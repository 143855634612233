import * as React from "react";
import {  defaultMargin, row, shellDarkGray } from '../../styles/styles'
import { DataProvider, RequestStatus } from "../../data/DataProvider";
import { Util } from "../../util/Util";
import { RoiPreview } from "../../screens/NewRoi";
import { Roi } from "../../../../lambda/interfaces/rois";
import { Modal, Spinner, Button } from "react-bootstrap";
import { Redirect} from 'react-router-dom'
import { TemporalConstraintFirstLastDateSelector } from "../selectors/TemporalConstraintFirstLastDateSelector";
import { DataCollectionDateType } from "../../../../lambda/interfaces/sources";

interface Props
{
    visible: boolean;
    dataProvider: DataProvider;
    roi: Roi;
    onModalClose: () => void;
}

interface State 
{
    status: RequestStatus;
    firstDate: Date;
    lastDate: Date;
    dataCollectionDateType: DataCollectionDateType;
}

export class EditROITemporalConstraintsModal extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state = {
            status: RequestStatus.NOT_STARTED,
            firstDate: this.props.roi.temporalConstraint.firstDate,
            lastDate: this.props.roi.temporalConstraint.lastDate,
            dataCollectionDateType: this.props.roi.temporalConstraint.dataCollectionDateType
        }
    }

    onTemporalDataChange(dataCollectionDateType: DataCollectionDateType, firstDate: Date, lastDate: Date, interval: number, disabled: boolean) {
        this.setState({
            dataCollectionDateType,
            firstDate,
            lastDate: dataCollectionDateType === DataCollectionDateType.ONGOING ? new Date() : lastDate
        });
    }

    editRoiTemporalConstraints = async () => {
        let canChange = this.state.firstDate && this.state.lastDate && this.state.dataCollectionDateType !== null;

        if (canChange){
            this.setState({
                status: RequestStatus.IN_PROGRESS
            });

            await this.props.dataProvider.editRoiTemporalConstraints(this.props.roi.id, this.state.dataCollectionDateType, this.state.firstDate, this.state.lastDate);

            this.setState({
                status: RequestStatus.NOT_STARTED
            });

            this.props.onModalClose();
        }
    }

    public render(): JSX.Element
    {
        if(this.state.status == RequestStatus.SUCCESS){
            this.setState({
                status: RequestStatus.NOT_STARTED
            });
            this.props.onModalClose();
        }

        return (
            <Modal show={this.props.visible}  backdrop="static">
                <Modal.Header >
                    <Modal.Title>Update temporal constraints</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {
                            this.state.status == RequestStatus.NOT_STARTED &&
                            <div style={{...row, flexWrap: 'wrap'}}>
                            <TemporalConstraintFirstLastDateSelector 
                                firstDate={this.state.firstDate}
                                lastDate={this.state.lastDate}
                                interval={this.props.roi.temporalConstraint.interval}
                                dataCollectionDateType={this.state.dataCollectionDateType}
                                disabled={this.props.roi.temporalConstraint.disabled}
                                onTemporalDataChange={this.onTemporalDataChange.bind(this)}
                            /></div>
                        }
                        {
                            this.state.status == RequestStatus.ERROR &&
                                <p>There was an error while editing the temporal constraints for ROI {this.props.roi.id}.</p>
                        }                    
                        {
                            this.state.status == RequestStatus.IN_PROGRESS &&
                            <div style={{...row, alignItems: 'center'}}>
                                 <Spinner animation="border" role="status" color={shellDarkGray}/>
                                <div style={{ marginLeft: defaultMargin}}>Updating temporal constraints...</div>
                            </div>
                        }
                </Modal.Body>
                   <Modal.Footer>
                       {
                           (this.state.status == RequestStatus.NOT_STARTED || 
                            this.state.status == RequestStatus.ERROR) &&
                            <Button className="shell-secondary-btn" onClick={() => {
                                this.props.onModalClose();
                            }}>Close</Button>
                       }
                        {
                            this.state.status == RequestStatus.NOT_STARTED &&
                            <Button className="shell-gray-btn" onClick={this.editRoiTemporalConstraints.bind(this)}>Update</Button> 
                        }
                    </Modal.Footer>                
            </Modal>     
        );
    }
}