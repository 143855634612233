import * as React from "react";
import * as ReactDOM from "react-dom";
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import Feature from 'ol/Feature';
import {applyTransform} from 'ol/extent';
import TileLayer from 'ol/layer/Tile';
import Draw, {createRegularPolygon, createBox, DrawEvent} from 'ol/interaction/Draw';
import {get as getProjection, getTransform} from 'ol/proj';
import {register} from 'ol/proj/proj4';
import OSM from 'ol/source/OSM';
import proj4 from 'proj4';
import { Vector as VectorSource, GeoJSON} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer';
import { OutputProjectionType, SpatialConstraint } from "../../../../lambda/interfaces/rois";
import {defaults as defaultControls, Control} from 'ol/control';
import { Point, LinearRing, Polygon} from 'ol/geom';
import { Style, Fill, Stroke } from 'ol/style';
import { Util } from "../../util/Util";
import { GeoUtil } from "../../data/GeoUtil";
import { DragPan } from 'ol/interaction';

var source = new VectorSource({wrapX: false});

var raster = new TileLayer({
    source: new OSM()
  });

const blueStyle = new Style({
    stroke: new Stroke({
      color: 'blue',
      width: 3
    }),
    fill: new Fill({
      color: 'rgba(0, 123, 255, 0.2)'
    })
});

const redStyle = new Style({
    stroke: new Stroke({
      color: 'red',
      width: 3
    }),
    fill: new Fill({
      color: 'rgba(236, 27, 35, 0.2)'
    })
});

var vector = new VectorLayer({
  source: source,
});

function formatNumber(num: number): number{
  return parseFloat(Util.formatNumber(num));
}

  interface Props {
      roiSpatialConst: SpatialConstraint;
      modalVisible: boolean;
  }

  interface State {
      map: Map | null ;
  }

const equirectangularProj = 'EPSG:4326';

export class SimpleRoiMap extends React.Component<Props,State> {
  constructor(props) {
    super(props);    
    this.state={
        map: null,
    } 
  }

  componentDidMount(){
    //create the map
    var map: Map = new Map({
        layers: [raster, vector],
        target: 'map',      
        view: new View({
        projection: equirectangularProj,
        center: [0, 0],
        zoom: 1,
        })
    }); 

    var dragPan;
    map.getInteractions().forEach(function(interaction) {
    if (interaction instanceof DragPan) {
        dragPan = interaction;
    }
    }, this);
    if (dragPan) {
    map.removeInteraction(dragPan);
    }

    source.clear();  

    let roiMinX;
    let roiMaxX;
    let roiMinY;
    let roiMaxY;

    if(this.props.roiSpatialConst.projectionType === OutputProjectionType.EQUIRECTANGULAR){
      roiMinX = this.props.roiSpatialConst.minX;
      roiMaxX = this.props.roiSpatialConst.maxX;
      roiMinY = this.props.roiSpatialConst.minY;
      roiMaxY = this.props.roiSpatialConst.maxY;
    }else{
      const minlatLong = GeoUtil.utmToLatLon(this.props.roiSpatialConst.minX, this.props.roiSpatialConst.minY, this.props.roiSpatialConst.zone, this.props.roiSpatialConst.facing);
      const maxlatLong = GeoUtil.utmToLatLon(this.props.roiSpatialConst.maxX, this.props.roiSpatialConst.maxY, this.props.roiSpatialConst.zone, this.props.roiSpatialConst.facing);

      roiMinX = minlatLong.longitude;
      roiMinY = minlatLong.latitude;
      roiMaxX = maxlatLong.longitude;
      roiMaxY = maxlatLong.latitude;
    }

    SimpleRoiMap.createRectPolygon(roiMinX, roiMaxX, roiMinY,roiMaxY, redStyle);

    this.setState({map: map});
    
    }

    static createRectPolygon(minX: number, maxX: number, minY: number, maxY: number, style: Style){
    var ring = [[minX,minY],[maxX,minY],[maxX,maxY],[minX, maxY],[minX,minY]];
    var polygon = new Polygon([ring]);  
    var feature = new Feature(polygon);
    feature.setStyle(style);
    source.addFeature(feature);
    }

    render(){

    if(this.state.map !== null){
    return (
        <div id="map" style={{width: '100%', height: '100%'}} className="map" ref={(el)=> this.state.map.setTarget(el)}></div>
    );
    }else{
        return <div/>;
    }
    
    }
}