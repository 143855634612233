import * as React from "react";
import { tabPage, fullHeight, row, defaultMargin, textInputStyle, shellDarkGray } from "../../styles/styles";
import { Form, Dropdown } from 'react-bootstrap';
import DatePicker  from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { DataCollectionDateType, TemporalInterval } from "../../../../lambda/interfaces/sources";
import { RoiPreview, TemporalPreview, SpatialPreview } from "../NewRoi";
import { InterpolationType, TemporalInterpolationType, OutputProjectionType } from "../../../../lambda/interfaces/rois";
import { Util } from "../../util/Util";
import { TemporalConstraintFirstLastDateSelector } from "../../components/selectors/TemporalConstraintFirstLastDateSelector";

export interface Props {
    roiPreview: RoiPreview;
    onTemporalDataChange: (dataCollectionDateType: DataCollectionDateType, firstDate: Date, lastDate: Date, interval: number, disabled: boolean) => void;
    onTemporalInterpolationChange: (interpolation: TemporalInterpolationType) => void;
    onSpatialDataChange: (sp: SpatialPreview) => void; 
    onSpatialInterpolationChange: (interpolation: InterpolationType) => void;
}

export interface State {
}

export default class TemporalConstraintsTab extends React.Component<Props, State>
{
    public render(): JSX.Element
    {     

        let intervals: TemporalInterval[] = [1 , 2 , 3 , 4 , 6 , 8 , 12 , 24];
        
        return (
            <div style={{...tabPage, ...fullHeight, color: shellDarkGray}}>       
                    <div style={{marginBottom: defaultMargin * 2}}>Select the date range of the data collection</div>
                    <div style={{...row, flexWrap: 'wrap'}}>
                        <TemporalConstraintFirstLastDateSelector 
                            firstDate={this.props.roiPreview.temporalConstraint.firstDate}
                            lastDate={this.props.roiPreview.temporalConstraint.lastDate}
                            dataCollectionDateType={this.props.roiPreview.temporalConstraint.dataCollectionDateType}
                            disabled={this.props.roiPreview.temporalConstraint.disabled}
                            interval={this.props.roiPreview.temporalConstraint.interval}
                            onTemporalDataChange={this.props.onTemporalDataChange.bind(this)}
                        />
                        {
                             !this.props.roiPreview.temporalConstraint.disabled &&
                            <div style={{marginLeft: defaultMargin * 2}}>
                            <div style={{fontWeight: 'bold', marginBottom: 30,}}>Temporal interpolation</div>                                                         
                                <Dropdown>
                                    <Dropdown.Toggle className="shell-dropdown" id="dropdown-spatial-interpolation" >
                                        {this.props.roiPreview.temporalInterpolation === undefined ? "Select here" : Util.getTemporalInterpolationName(this.props.roiPreview.temporalInterpolation)}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onSelect={() => this.props.onTemporalInterpolationChange(TemporalInterpolationType.LINEAR)}>Linear</Dropdown.Item>
                                        <Dropdown.Item onSelect={() => this.props.onTemporalInterpolationChange(TemporalInterpolationType.NONE)}>None</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>                                                         
                        </div>   
                        }                        
                        {
                            (this.props.roiPreview.temporalInterpolation === undefined || this.props.roiPreview.temporalInterpolation === TemporalInterpolationType.LINEAR) &&
                            !this.props.roiPreview.temporalConstraint.disabled &&
                                <div style={{marginLeft: defaultMargin * 2}}>
                                    <div style={{ fontWeight: 'bold', marginBottom: 30}}>Time interval (hours)</div>
                                    <Dropdown>
                                        <Dropdown.Toggle className="shell-dropdown" id="dropdown-spatial-interpolation" >
                                            { this.props.roiPreview.temporalConstraint.interval === undefined ? "Select here" : this.props.roiPreview.temporalConstraint.interval}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {
                                                intervals.map(interval => 
                                                <Dropdown.Item 
                                                    key={interval}
                                                    onSelect={() => 
                                                    this.props.onTemporalDataChange(this.props.roiPreview.temporalConstraint.dataCollectionDateType, this.props.roiPreview.temporalConstraint.firstDate, this.props.roiPreview.temporalConstraint.lastDate, interval, this.props.roiPreview.temporalConstraint.disabled)}>{interval}</Dropdown.Item>
                                                    )
                                            }                                    
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                        }      
                    </div>
                    <div className="separator" style={{marginTop: 20, marginBottom: 15}}></div>
                    <div style={{...row}}>
                        <div>
                        <div style={{marginRight: defaultMargin * 2}}>
                            <div style={{fontWeight: 'bold', marginBottom: defaultMargin}}>Spatial interpolation:</div>
                            <Dropdown>
                                <Dropdown.Toggle className="shell-dropdown" id="dropdown-spatial-interpolation" >
                                    {this.props.roiPreview.spatialInterpolation === undefined ? "Select here" : Util.getInterpolationName(this.props.roiPreview.spatialInterpolation)}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onSelect={() => this.props.onSpatialInterpolationChange(InterpolationType.LINEAR)}>Linear</Dropdown.Item>
                                    <Dropdown.Item onSelect={() =>  this.props.onSpatialInterpolationChange(InterpolationType.NEAREST_NEIGHBOR)}>Nearest neighbour</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        </div>
                        <div>
                                <div style={{ fontWeight: 'bold', marginBottom: defaultMargin}}>Spatial resolution:</div>
                                <div style={{...row, alignItems: this.props.roiPreview.spatialConstraint.projectionType === OutputProjectionType.UTM ? 'flex-end' : 'flex-start'}}>
                                    <input
                                        type="number"                                   
                                        style={{width: 100, marginRight: 5, ...textInputStyle}}
                                        className="shell-input"
                                        value={this.props.roiPreview.spatialConstraint.gridResolution !== undefined ? this.props.roiPreview.spatialConstraint.gridResolution : ''}                               
                                        onChange={event => 
                                            this.props.onSpatialDataChange(Object.assign(this.props.roiPreview.spatialConstraint, {gridResolution: event.target.value}))
                                        } 
                                    />
                                    {this.props.roiPreview.spatialConstraint.projectionType !== undefined ? <span>{" " + Util.getProjectionUnitsInMetersOrDeg(this.props.roiPreview.spatialConstraint.projectionType)}</span> : null}
                                </div>
                        </div>
                    </div>
            </div>
        );
    }
}