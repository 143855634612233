import * as React from "react";
import { DataIssues, DatasetRecord } from "../../../../lambda/interfaces/rois";
import DatePicker  from 'react-datepicker';
import { Util } from "../../util/Util";

export interface Props {
    dataIssues: DataIssues | null;
    month: number;
    year: number;
    onDatePickerNavigate: (uyear: number, month: number) => any;
    updateSelectedIssueTimestamp: (ts: number) => any;
    selectedIssueTimestamp: number;
    availableParams: string[];
    updateSelectedParam: (paramId: string) => any;
    selectedParamId: string;
    availableRecords: DatasetRecord[];
    onSelectRecord:(recordTs: number) => any;
    selectedRecordTimestamp: number;
    onDatePickerDateSelect:() => any;
}

export interface State {
    // This is a date where the local day/month/year values match the UTC day/month/year values that the user has selected.
    // The timestamp of this date itself is **not right**.
    // The local time is always 00:00.
    selectedIssueDateFakeUtc: Date | null;
}

export class DataSelector extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);

        let selectedIssueDateFakeUtc: Date | null = null;
        
        if (this.props.selectedIssueTimestamp){
            let d  = new Date(this.props.selectedIssueTimestamp);
            // Creates a date where the local day/month/year matches the UTC day/month/year for the most recent issue timestamp.
            selectedIssueDateFakeUtc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
        };

        this.state = {
            selectedIssueDateFakeUtc
        }
    }

    public render(): JSX.Element
    {
        //exclude all of the dates for the currently selected time period except for the ones that are 
        //in our dataIssues.issues array - note, using includeDates here won't let us navigate between the months
        //as our data only includes timestamps that sit in the current month

        let excludeDates = this.getDatesInMonthLocal(this.props.year, this.props.month);
        let issueTimes: {timestamp: number, val: string}[] = [];

        if (this.props.dataIssues) {
            this.props.dataIssues.issues.forEach((issue) => {
                let d1 = new Date(issue.timestamp);//convert ms to date then use this to get a date at 00:00

                excludeDates[d1.getUTCDate() - 1] = null;

                let d2 = new Date(d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate());

                if (this.state.selectedIssueDateFakeUtc !== null && this.state.selectedIssueDateFakeUtc.getTime() === d2.getTime())
                {
                    let val = d1.getUTCHours().toString().padStart(2, '0') + ":" + d1.getUTCMinutes().toString().padStart(2, '0') + " UTC";
                    issueTimes.push({
                        timestamp: issue.timestamp,
                        val
                    });
                }
            });
        }

        excludeDates.filter(x => x !== null);
        return <>
            <table className="data_selector_table">
                <tbody>
                    <tr>
                        <th>Issue date:</th>
                        <td>
                            <DatePicker                                         
                                onChange={(date) => {
                                    this.setState({selectedIssueDateFakeUtc: date}, () => {
                                        this.props.onDatePickerDateSelect();
                                    });     
                                }}              
                                onMonthChange={(date) => {
                                    this.props.onDatePickerNavigate(date.getFullYear(), date.getMonth());
                                }}
                                onYearChange={(date) => {
                                    this.props.onDatePickerNavigate(date.getFullYear(), date.getMonth());
                                }}
                                dateFormat={"yyyy-MM-dd"}
                                selected={this.state.selectedIssueDateFakeUtc ? this.state.selectedIssueDateFakeUtc : null}        
                                excludeDates={excludeDates}
                                showYearDropdown
                                showMonthDropdown
                                onChangeRaw={(e) => {e.preventDefault()}}
                                openToDate={new Date(this.props.year, this.props.month, 1)}
                                
                            />
                        </td>
                        <th>Issue time:</th>
                        <td>
                            <select disabled={!issueTimes.length} onChange={(e) => {
                                if (e.target.value !== "" && e.target.value !== "NONE")
                                {
                                    this.props.updateSelectedIssueTimestamp(parseInt(e.target.value));
                                }
                            }}>
                                {issueTimes.length === 0 && <option value="NONE">None available</option>}
                                {issueTimes.length > 0 && <option value="">Please select...</option>}
                                {issueTimes.map(x => <option selected={this.props.selectedIssueTimestamp === x.timestamp && this.props.availableParams.length > 0} key={x.timestamp} value={x.timestamp}>{x.val}</option>)}
                            </select>
                        </td>
                        <th>Parameter:</th>
                        <td>
                            <select disabled={!this.props.availableParams.length} onChange={(e) => {
                                if (e.target.value !== "" && e.target.value !== "NONE")
                                {
                                    this.props.updateSelectedParam(e.target.value);
                                }  
                            }}>
                                {this.props.availableParams.length=== 0 && <option value="NONE">None available</option>}
                                {this.props.availableParams.length > 0 && <option value="">Please select...</option>}
                                {this.props.availableParams.map(x => <option selected={this.props.selectedParamId === x && this.props.availableParams.length > 0 && this.props.availableRecords.length > 0} key={x} value={x}>{x}</option>)}
                            </select>
                        </td>
                        <th>Data timestamp:</th>
                        <td>
                            <select disabled={!this.props.availableRecords.length} onChange={(e) => {
                                if (e.target.value !== "" && e.target.value !== "NONE")
                                {
                                    this.props.onSelectRecord(parseInt(e.target.value));
                                }     
                            }}>
                                {this.props.availableRecords.length=== 0 && <option value="NONE" selected={this.props.selectedRecordTimestamp === null}>None available</option>}
                                {this.props.availableRecords.length > 0 && <option value="" selected={this.props.selectedRecordTimestamp === null}>Please select...</option>}
                                {this.props.availableRecords.map(x => <option key={x.timestamp} value={x.timestamp} selected={this.props.selectedRecordTimestamp === x.timestamp}>{Util.formatTimestamp(x.timestamp)}</option>)}
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    }

    // For a give year and month, returns all the midnights (local time zone) in that month
    getDatesInMonthLocal(year: number, month: number) {
        let dates: Date[] = [];
        for (let day = 1; day <= new Date(year, month + 1, 0).getDate(); day++) {
            dates.push(new Date(year, month, day));
        }
        return dates;
    }
}