import * as React from "react";
import { Redirect } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Form, Button, Modal, Spinner } from 'react-bootstrap';
import {  defaultMargin, row } from '../../styles/styles'
import { DataProvider, } from "../../data/DataProvider";
import { Util } from "../../util/Util";
import { RoiPreview } from "../../screens/NewRoi";
import { Roi } from "../../../../lambda/interfaces/rois";

const enum RequestStatus {
    NOT_STARTED,
    IN_PROGRESS,
    COMPLETE,
    ERROR,
}

interface Props
{
    visible: boolean;
    dataProvider: DataProvider;
    onModalClose: () => void;
    downloadLink: string;
}

interface State {
    initStatus: RequestStatus;
    tmpDownloadLink: string;
    s3DownloadLink: string;
}
const defaultState: State = {
    initStatus: RequestStatus.NOT_STARTED,
    tmpDownloadLink: '',
    s3DownloadLink: ''
}


export class DownloadGribModal extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state=defaultState;
    }
    
    // componentWillReceiveProps(nextProps: Props) {
    //     if (nextProps.downloadLink && this.state.initStatus === RequestStatus.NOT_STARTED) {
    //         this.initGribJsonDownload(this.props.downloadLink);
    //     }
    // }

    initGribJsonDownload(){
        var getRequest = new XMLHttpRequest();
        this.setState({
            initStatus: RequestStatus.IN_PROGRESS
        })
        getRequest.addEventListener('load', () => { 
            const tmpDownloadLink = JSON.parse(getRequest.responseText)['Location'];
            this.setState({
                tmpDownloadLink: JSON.parse(getRequest.responseText)['Location'],
            })
            this.fetchTmpGribJsonDownload(tmpDownloadLink);
        })
        getRequest.addEventListener('error', () => {     
            this.setState({
                initStatus: RequestStatus.ERROR
            })     
        });
        getRequest.addEventListener('abort', () => {     
            this.setState({
                initStatus: RequestStatus.ERROR
            })     
        });
        getRequest.open('GET', this.props.downloadLink + '?format=gribjson&noredirectwait=true');
        getRequest.withCredentials = true;
        getRequest.send(); 
    }

    fetchTmpGribJsonDownload = (downloadLink: string) => {
        if (this.props.visible === false || this.state.initStatus !== RequestStatus.IN_PROGRESS) {
            return;
        }
        var getRequest = new XMLHttpRequest();
        getRequest.addEventListener('load', () => { 
            const s3DownloadLink = JSON.parse(getRequest.responseText)['Location'];
            if (s3DownloadLink !== downloadLink) {
                this.setState({
                    tmpDownloadLink: '',
                    s3DownloadLink,
                    initStatus: RequestStatus.COMPLETE
                })
            }
            else {
                setTimeout(() => {
                    this.fetchTmpGribJsonDownload(downloadLink);
                }, 5000)
            }
        })
        getRequest.addEventListener('error', () => {     
            this.setState({
                initStatus: RequestStatus.ERROR
            })     
        });
        getRequest.addEventListener('abort', () => {     
            this.setState({
                initStatus: RequestStatus.ERROR
            })     
        });
        getRequest.open('GET', downloadLink + '&noredirectwait=true');
        getRequest.withCredentials = true;
        getRequest.send(); 
    }

    public render(): JSX.Element
    {   
        if (this.props.downloadLink && this.state.initStatus === RequestStatus.NOT_STARTED) {
            this.initGribJsonDownload();
        }
      
        return (
            <Modal show={this.props.visible} backdrop="static">
                <Modal.Header >
                    <Modal.Title>GRIB JSON Download</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        (this.state.initStatus === RequestStatus.IN_PROGRESS || this.state.initStatus === RequestStatus.NOT_STARTED) &&
                            <div style={{...row, alignItems: 'center'}}>
                                <Spinner animation="border" role="status" variant="primary"/>
                                <div style={{ marginLeft: defaultMargin}}>
                                    Please wait while the request GRIB JSON file is converted.
                                </div>
                            </div>
                    }
                    {
                        this.state.initStatus === RequestStatus.ERROR &&
                            <div style={{...row, alignItems: 'center'}}>                               
                                <div style={{ marginLeft: defaultMargin}}>There was an error converting to GRIB JSON.</div>
                            </div>
                    }
                    {
                        this.state.initStatus === RequestStatus.COMPLETE &&
                            <div style={{...row, alignItems: 'center'}}>        
                                <div style={{ marginLeft: defaultMargin}}>
                                    Your file is ready for download. Click the link below to retrieve your download.
                                    Please note that the requested file is in gzip format, and will require extraction.
                                    <a style={{ marginLeft: defaultMargin}} href={this.state.s3DownloadLink}>Download Here</a>
                                </div>                       
                            </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        className="shell-secondary-btn" 
                        onClick={() => {
                            this.setState(defaultState);
                            this.props.onModalClose();
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>     
        );
    }
}


