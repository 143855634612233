import * as React from "react";
import { Dropdown } from 'react-bootstrap';
import { Roi, RoiApprovalStatus, RoiDataCollectionStatus } from "../../../../lambda/interfaces/rois";
import { roundedBox, smallFontSize, row, shellDarkGray } from '../../styles/styles'
import { StatusBox } from '..';
import { BoxStatus } from '../StatusBox';
import { Util } from "../../util/Util";
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import { RoiApprovalModal } from "../modals/RoiApprovalModal";
import { DataProvider, RequestStatus } from "../../data/DataProvider";


interface Props
{
    roi: Roi;
    isAdmin: boolean;
    dataProvider: DataProvider;
}

interface State{
    modifyApprovalStatusModalVisible: boolean;
}

export  class InlineRoi extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state={
            modifyApprovalStatusModalVisible: false,
        }
    }

    public render(): JSX.Element
    {
        let dataSourceNames: string = '';
        this.props.roi.dataSources.map((ds, i) => {
            dataSourceNames += ds.dataSourceId;
            if(i !== this.props.roi.dataSources.length - 1){
                dataSourceNames += ', ';
            }
        });

        const CustomToggle = React.forwardRef((props: any, ref: React.Ref<HTMLAnchorElement>) => (
            <a
              ref={ref}
              style={{width: 20, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}
              onClick={e => {
                e.preventDefault();               
                props.onClick(e);
              }}
            >
                
                    {props.children}
                
            </a>
          ));   
          
          const startDataDownloadDate = this.props.dataProvider.getDataDownloadStartDate(this.props.roi.id);
          const endDataDownloadDate = this.props.dataProvider.getDataDownloadEndDate(this.props.roi.id);

          const dataDownloadInterval = (startDataDownloadDate && endDataDownloadDate) ?  Util.formatDate(new Date(startDataDownloadDate)) + ' to ' +
          Util.formatDate(new Date(endDataDownloadDate)) : this.props.roi.dataCollectionStatus === RoiDataCollectionStatus.COMPLETE ? "no data" : 'not available yet';
         
        return (
            <div style={roundedBox} className="inline-roi-link box-shadow" >
                <Link to={"/roi/" + this.props.roi.id} key={this.props.roi.id} style={{textDecoration: 'none', flex: 1,}}>
                    <div  style={{...row, flex: 1,  alignItems: 'center', color: shellDarkGray}}>
                        {
                            this.props.isAdmin &&
                            <div style={{flex: 2, fontSize: smallFontSize}}>{this.props.roi.owner}</div>
                        }            
                        <div style={{flex: 2, fontSize: smallFontSize, fontWeight: 'bold'}}>{this.props.roi.name}</div>
                        <div style={{flex: 1, fontSize: smallFontSize}}>{dataSourceNames}</div>
                        <div style={{flex: 1, display: 'flex', flexDirection: 'row',  fontSize: smallFontSize}}>
                            <div style={{ marginRight: 5}}>{this.props.roi.dataStorage}</div>
                            {this.props.roi.dataStorageUnit}
                        </div>
                        <div style={{flex: 2, fontSize: smallFontSize}}>{dataDownloadInterval}</div>
                        <div style={{flex: 2}}>
                            <StatusBox 
                                status={this.props.roi.isStopped ? BoxStatus.ERROR : this.props.roi.isSuspended ? BoxStatus.SUSPENDED : Util.getColorForCollectionStatus(this.props.roi.dataCollectionStatus)} 
                                label={this.props.roi.isStopped ? "Stopped" : this.props.roi.isSuspended ? "Suspended" : Util.getLabelForCollectionStatus(this.props.roi.dataCollectionStatus)} 
                            />
                        </div>
                        <div style={{flex: 2}}>
                            <StatusBox 
                                status={Util.getColorForApprovalStatus(this.props.roi.approvalStatus)} 
                                label={Util.getLabelForApprovalStatus(this.props.roi.approvalStatus)} 
                            />
                        </div>
                        <div style={{flex: 2}}>
                            <StatusBox 
                                status={Util.getColorForAccessStatus(this.props.roi.userAccess)} 
                                label={Util.getLabelForAccessStatus(this.props.roi.userAccess)} 
                            />
                        </div>
                    </div>
                </Link>              
                {
                    this.props.isAdmin && 
                    <div style={{width: 30, cursor: 'pointer'}}>
                        <Dropdown drop='left' style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                <FontAwesomeIcon icon={faEllipsisV} style={{fontSize: 15}} />
                            </Dropdown.Toggle>                  
                            <Dropdown.Menu>
                                <Dropdown.Item 
                                    disabled={this.props.roi.approvalStatus !== RoiApprovalStatus.APPROVAL_PENDING} 
                                    eventKey="1" 
                                    onClick={() => this.setState({modifyApprovalStatusModalVisible: true})}
                                >Set approval status</Dropdown.Item>
                                {/* <Dropdown.Item 
                                    disabled={this.props.roi.userAccess !== RoiAccessStatus.DENIED} 
                                    eventKey="2" 
                                    onClick={() => this.props.dataProvider.requestRoiAccess(this.props.roi.id)}
                                >Request Access</Dropdown.Item> */}
                            </Dropdown.Menu>
                        </Dropdown>               
                    </div>    
                }               
                <RoiApprovalModal 
                    dataProvider={this.props.dataProvider}
                    roi={this.props.roi}
                    visible={this.state.modifyApprovalStatusModalVisible && 
                        this.props.roi.approvalStatus === RoiApprovalStatus.APPROVAL_PENDING && 
                        this.props.dataProvider.getDataLoadedStatus() == RequestStatus.SUCCESS
                    }
                    onModalClose={() => {
                        this.props.dataProvider.completeRoiApprovalStatusChange();
                        this.setState({modifyApprovalStatusModalVisible: false});                   
                    }}
            />                
            </div>
        );
    }
}