import * as React from "react";
import {  defaultMargin, row, shellDarkGray } from '../../styles/styles'
import { DataProvider, RequestStatus } from "../../data/DataProvider";
import { Util } from "../../util/Util";
import { RoiPreview } from "../../screens/NewRoi";
import { Roi } from "../../../../lambda/interfaces/rois";
import { Modal, Spinner, Button } from "react-bootstrap";
import { Redirect} from 'react-router-dom'

interface Props
{
    visible: boolean;
    dataProvider: DataProvider;
    onModalClose: () => void;
}

export class CreateMapFileModal extends React.Component<Props, {}>
{
    constructor(props: Props)
    {
        super(props);
    }

    public render(): JSX.Element
    {
      
        return (
            <Modal show={this.props.visible}  backdrop="static">
                <Modal.Header >
                    <Modal.Title>Create .map file</Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                        {
                            this.props.dataProvider.isMapFileCreated() == RequestStatus.ERROR &&
                                <p>There was an error while creating the .map file.</p>
                        }    
                        {
                            this.props.dataProvider.isMapFileCreated() == RequestStatus.SUCCESS &&
                                <p>.map file was created successfully.</p>
                        }                    
                        {
                            this.props.dataProvider.isMapFileCreated() == RequestStatus.IN_PROGRESS &&
                            <div style={{...row, alignItems: 'center'}}>
                                 <Spinner animation="border" role="status" color={shellDarkGray}/>
                                <div style={{ marginLeft: defaultMargin}}>Creation in progress ...</div>
                            </div>
                        }
                </Modal.Body>
                   <Modal.Footer>
                       {
                           (this.props.dataProvider.isMapFileCreated() == RequestStatus.ERROR || this.props.dataProvider.isMapFileCreated() == RequestStatus.SUCCESS) &&
                            <Button className="shell-secondary-btn" onClick={() => {
                                this.props.onModalClose();
                                this.props.dataProvider.completeMapFileCreation();
                            }}>Close</Button>
                       }                  
                    </Modal.Footer>                
            </Modal>     
        );
    }
}


