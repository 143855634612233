import { CSSProperties } from 'react';

export const lightGray = '#f2f2f2';
export const gray = '#666666';
export const lightGreen = '#8cd98c';
export const lightOrange = '#ffb366';
export const lightRed = '#ff4d4d';
export const lightBlue = ' #66b3ff';
export const lightGray2 = '#cccccc';
export const purple = '#bb99ff';

export const darkBlueColor = '#000d1a';
export const mainBlue = '#007bff';
export const transparentDark = '#000000BF';

export const defaultMargin = 10;
export const defaultPadding = 10;

export const smallFontSize = 12;
export const mediumFontSize = 16;
export const largeFontSize = 20;

export const shellRed = '#dd1d21';
export const shellYellow = '#fbce07';
export const shellDarkGray = '#404040';

export const roundedBox: CSSProperties = {
    backgroundColor: lightGray,
    color: gray,
    margin: defaultMargin,
    padding: defaultPadding,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
};

export const roundedBoxContainer: CSSProperties = {
    backgroundColor: lightGray,
    color: gray,
    padding: defaultPadding,
    marginBottom: defaultMargin * 2,
    marginRight: defaultMargin,
    marginLeft: defaultMargin,  
};

export const highlightedLinkPart: CSSProperties = {
    color: shellRed, 
    fontWeight: 'bold',
}

export const disabledLinkPart: CSSProperties = {
    color: 'light-gray !important', 
}

export const textInputStyle: CSSProperties = {
    padding: 5,
    borderColor: lightGray2,
    borderRadius: 5,
    color: 'gray',
    border: '1px solid #ced4da',
}

export const tableCaption: CSSProperties = {
    color: gray,
    fontSize: smallFontSize,
    fontWeight: 'bold',
};

export const tabPage: CSSProperties = {
    paddingTop: defaultPadding * 2,
    paddingBottom: defaultPadding * 2,
    paddingLeft: defaultPadding,
    paddingRight: defaultPadding,
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
};

export const dropDown: CSSProperties = {
    display: 'flex', 
    alignItems: 'center',
};

export const description: CSSProperties = {
    marginTop: defaultMargin * 2,
    marginBottom: defaultMargin * 2,
};

export const fullHeight: CSSProperties = {
    height: '100%',
}

export const row: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
}

export const smallFont: CSSProperties = {
    marginLeft: defaultMargin, 
    fontSize: smallFontSize, 
};

export const smallError: CSSProperties = {
    marginLeft: defaultMargin, 
    fontSize: smallFontSize, 
    color: lightRed,
};

export const smallBold: CSSProperties = {
    marginLeft: defaultMargin, 
    fontSize: mediumFontSize, 
    fontWeight: 'bold',
};

export const errorColor: CSSProperties = {color: lightRed};
export const blackColor: CSSProperties = {color: shellDarkGray};

export const box: React.CSSProperties = {
    color: 'white', 
    fontWeight: 'bold',
    fontSize: smallFontSize,

    marginRight: defaultMargin,

    height: 35,
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    paddingBottom: defaultPadding,
    paddingTop: defaultPadding,
    paddingLeft: defaultPadding * 2,
    paddingRight: defaultPadding * 2,

};