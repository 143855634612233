import * as React from "react";
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { DataSource, DataCollectionDateType, Parameter } from "../../../../lambda/interfaces/sources";
import { mediumFontSize, defaultPadding, defaultMargin, darkBlueColor, smallFontSize, row, tableCaption, roundedBoxContainer, largeFontSize, mainBlue, lightOrange } from '../../styles/styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { Util } from "../../util/Util";
import { DataSourceReference } from "../../../../lambda/interfaces/rois";
import { RoiPreview } from "../../screens/NewRoi";
import { GeoUtil } from "../../data/GeoUtil";
import { MapIntersectionModal } from "../modals/MapIntersectionModal";
import { IntersectionMap } from "../maps/IntersectionMap";


interface Props
{
    dataSource: DataSource;
    updateDataSourceReference: (ds: DataSourceReference) => void;
    selectedDSRef: DataSourceReference | undefined;
    roiPreview: RoiPreview;
}

interface State {
    open: boolean;
    selectedParamIds: string[];
    paramFilter: string;
    intersectionMapVisible: boolean;
}

export  class InlineDataSource extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state = {
            open: false,
            selectedParamIds: [],
            paramFilter: '',
            intersectionMapVisible: false,
        }
    }

    renderProjectionWarning = (props) => {
        return <Tooltip {...props}>The projection of the ROI is not matching the projection of the data source.</Tooltip>;
    }

    renderResolutionWarning = (props) => {
    return <Tooltip {...props}>The Spatial resolution of the ROI is not matching the resolution of the data source.</Tooltip>;
    }

    getParamSubsetNames(params: Parameter[]): string[]{
        let names: string[] = [];
        params.map(p => {
                if(names.findIndex(n => n === p.subsetId) === -1){
                    names.push(p.subsetId);
                }      
        });

        return names;
    }

    public render(): JSX.Element
    {
        let timeInterval: string[] = [];
        if(this.props.dataSource.temporalConstraint.temporalResolutions.length == 0){
            timeInterval.push("-");
        }else{
            if(this.props.dataSource.temporalConstraint.temporalResolutions.length == 1){
                timeInterval.push(this.props.dataSource.temporalConstraint.temporalResolutions[0].interval +` h`);
            }else{
                this.props.dataSource.temporalConstraint.temporalResolutions.map(tr => timeInterval.push(tr.interval + ` h - ` +tr.subsetId ));
            }
        }

        let allParams: Parameter[] = this.props.dataSource.parameters.sort(function(a,b){
            return a.name > b.name ? 1 : -1
        });

        let filteredParamList: Parameter[] = [];
        if(this.state.paramFilter !== ''){
            allParams.map(p =>{
                if(p.name.toLocaleLowerCase().includes(this.state.paramFilter.toLocaleLowerCase()) || p.unit.toLocaleLowerCase().includes(this.state.paramFilter.toLocaleLowerCase())){
                    filteredParamList.push(p);
                }
            });
        }else{
            filteredParamList = allParams;
        }

        const subsetNames = this.getParamSubsetNames(filteredParamList);
        let filteredParamGroups: {groupId: string, params: Parameter[]}[] = [];
        subsetNames.map(ssN => {
            filteredParamGroups.push({
                groupId: ssN,
                params: filteredParamList.filter(x => x.subsetId === ssN),
            });
        });

        const roiIntersectionWithDs = Util.spatialCoordinatesDefined(this.props.roiPreview.spatialConstraint) ? 
        Util.getIntersectionTypeName(GeoUtil.getIntersectionForROIAndDs(this.props.roiPreview.spatialConstraint, this.props.dataSource.spatialConstraint)) : "-";

        let intersectionButton = roiIntersectionWithDs === "-" ?
        <div style={{fontSize: mediumFontSize, fontWeight: 'bold'}}>{roiIntersectionWithDs}</div> :    
        <OverlayTrigger placement="bottom" overlay={(props) =>
            <Tooltip id={this.props.dataSource.id + "-intersection-tooltip"} {...props}>Check {this.props.dataSource.productName} data source and the selected ROI visual intersection on a map.</Tooltip>}>
                <a style={{fontSize: mediumFontSize}} className="simple-link"
                onClick={(evt) => {
                    this.setState({intersectionMapVisible: true});
                    evt.stopPropagation();
                }} >
                    {roiIntersectionWithDs}
                </a>
        </OverlayTrigger>                                   
        
                                                

        return (
            <div style={{...roundedBoxContainer,flex:  this.state.open ?  1 : 0, display: 'flex', flexDirection: 'column'}} className="box-shadow" >                
                    <div style={{ display: 'flex', flexDirection: 'column' }} >
                        <div style={{  width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} onClick={() => this.setState({open: !this.state.open})}>
                                    <div style={{flex: 1}}>
                                        <div style={{...row, flex: 1, marginRight: 50}}>
                                            <div style={{flex: 2, ...tableCaption}}>Product name</div>
                                            <div style={{flex: 2, ...tableCaption}}>Type</div>
                                            <div style={{flex: 2, ...tableCaption}}>Dates</div>
                                            <div style={{flex: 1, ...tableCaption}}>Intersection</div>
                                        </div>
                                        <div style={{...row, flex: 1, marginRight: 50}}>
                                            <div style={{flex: 2, fontSize: mediumFontSize}}>{this.props.dataSource.productName}</div>
                                            <div style={{flex: 2, fontSize: mediumFontSize }}>{Util.getDataSourceTypeName(this.props.dataSource.type)}</div>
                                            <div style={{flex: 2, fontSize: mediumFontSize }}>{Util.getTemporalConstraintLabel(this.props.dataSource.temporalConstraint)}</div>
                                            <div style={{flex: 1}}> 
                                                {intersectionButton}
                                            </div>
                                        </div>
                                    </div>
                                    <FontAwesomeIcon icon={this.state.open ? faChevronUp : faChevronDown} style={{fontSize: 20, marginRight: defaultMargin}} />
                            </div>
                            {
                                this.state.open &&
                                <div style={{  width: '100%', display: 'flex', flexDirection: 'column', flexShrink: 1, flexGrow: 1, flexBasis: 'auto',overflowY: 'auto', minHeight: 0, height: '100%'}}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column'}}>                                                        
                                    <div style={{flex: 1, marginTop: defaultMargin}}>
                                        <div style={{...row, flex: 1, marginRight: 50 + 20}}>
                                            <div style={{flex: 2, ...tableCaption, }}>Organization</div>
                                            <div style={{flex: 2, ...row}}>
                                                <div style={{...tableCaption}}>Projection</div>                                          
                                                </div>
                                            <div style={{flex: 2, ...row}}>                                  
                                                <div style={{...tableCaption}}>Spatial resolution</div>                                              
                                            </div>
                                            <div style={{flex: 1, ...tableCaption}}>Time interval</div>
                                        </div>
                                        <div style={{...row, flex: 1, marginRight: 50 + 20}}>
                                            <div style={{flex: 2,  fontSize: mediumFontSize,}}>{this.props.dataSource.organization}</div>
                                            <div style={{flex: 2, }}>{Util.getProjectionName(this.props.dataSource.spatialConstraint.projectionType)}</div>
                                            <div style={{flex: 2, }}>{this.props.dataSource.spatialConstraint.gridResolution}</div>
                                                <div style={{flex: 1,}}>{timeInterval.map(tr => <div key={tr}>{tr}</div>)}</div>
                                        </div>
                                    </div>
                                    <div style={{flex: 1, marginTop: defaultMargin}}>                                      
                                        <div style={{flex: 4, ...tableCaption}}>Reference URL</div>                                                                                                            
                                        <a style={{fontSize: smallFontSize}} href={this.props.dataSource.referenceURL} target="_blank">{this.props.dataSource.referenceURL}</a>                                         
                                    </div>
                                    <div style={{...tableCaption, marginTop: defaultMargin}}>License</div>
                                    <div style={{whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{__html: this.props.dataSource.license}}></div>
                                    <div style={{...tableCaption, marginTop: defaultMargin}}>Parameters</div>
                                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', }}>                                  
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Filter parameters" 
                                        style={{width: 200, alignSelf: 'flex-end', marginRight: defaultMargin}}
                                        onChange={(evt) => this.setState({paramFilter: evt.target.value})}
                                    />
                                    {
                                        filteredParamGroups.map(fpg =>
                                            <div className="param-selection">
                                                {
                                                    fpg.groupId !== undefined && filteredParamGroups.length > 1 &&
                                                        <div style={{fontSize: mediumFontSize,  fontWeight: 'bold', marginBottom: defaultMargin, marginTop: defaultMargin}}>{`${this.props.dataSource.productName} (${fpg.groupId})`} </div>
                                                }     
                                                <table style={{borderSpacing: 10}}>
                                                    <tr>
                                                        <th style={{width: 30}}></th>
                                                        <th>ID</th>
                                                        <th>Name</th>
                                                        <th>Unit</th>
                                                    </tr>
                                                                                    
                                                {
                                                    fpg.params.map((p, i) => 
                                                        <tr key={p.name + "_ " + i}>
                                                            <td>
                                                                <input 
                                                                    type="checkbox" 
                                                                    onChange={() => {
                                                                        let newList: string[] ;
                                                                        if(this.props.selectedDSRef !== undefined && this.props.selectedDSRef.dataSourceId == this.props.dataSource.id && this.props.selectedDSRef.subsetId === fpg.groupId){
                                                                            newList= Object.assign(this.state.selectedParamIds);
                                                                            const index = newList.findIndex(sp => sp == p.id);
                                                                            if(index > -1){
                                                                                newList.splice(index, 1);
                                                                            }else{
                                                                                newList.push(p.id);
                                                                            }
                                                                        }else{
                                                                            newList = [p.id];
                                                                        }                                                       
                                                                        this.setState({selectedParamIds: newList});
                                                                        this.props.updateDataSourceReference({
                                                                            dataSourceId: this.props.dataSource.id,
                                                                            subsetId: fpg.groupId,
                                                                            parameterIds: newList,
                                                                        });
                                                                    }}
                                                                    checked={this.props.selectedDSRef !== undefined && this.props.selectedDSRef.dataSourceId == this.props.dataSource.id && 
                                                                        this.props.selectedDSRef.parameterIds.indexOf(p.id) > -1 && this.props.selectedDSRef.subsetId === fpg.groupId}
                                                                />
                                                            </td>
                                                            <td>{p.id}</td>
                                                            <td>{p.name}</td>
                                                            <td>{p.unit}</td>                                                           
                                                        </tr>
                                                        )
                                                }
                                                </table>      
                                            </div>
                                            )
                                    }
                                    </div>                           
                                </div>
                            </div>
                            }
                        </div>                       
                    </div>  
                   
                    <MapIntersectionModal 
                        onModalClose={() => this.setState({intersectionMapVisible: false})} 
                        visible={this.state.intersectionMapVisible} 
                        roiSpatialConst={this.props.roiPreview.spatialConstraint}
                        dataSource={this.props.dataSource}
                        intersectionName={roiIntersectionWithDs}
                        />                           
            </div>
        );
    }
}