import { RoiTemporalConstraint } from "./sources";

export interface PostRoi {
    name: string;
    dataSources: DataSourceReference[];
    spatialConstraint: SpatialConstraint;
    temporalConstraint: RoiTemporalConstraint;
    spatialInterpolation: InterpolationType;
    temporalInterpolation: TemporalInterpolationType;
}
export enum PostRoiApprovalStatus {
    APPROVED,
    DECLINED,
}

export enum RoiUserAccessStatus {
    FULL,
    PENDING,
    READ,
    NONE
}
export enum RoiAccessListStatus {
    APPROVED,
    PENDING
}
export interface RoiAccess {
    user: string;
    accessStatus: RoiAccessListStatus;
}

export interface Roi {
    id: string;
    name: string;
    dataCollectionStatus: RoiDataCollectionStatus;
    approvalStatus: RoiApprovalStatus;
    owner: string; //probably username or email address
    dataStorage: number;
    dataStorageUnit: string;
    dataSources: DataSourceReference[];
    spatialConstraint: SpatialConstraint;
    temporalConstraint: RoiTemporalConstraint;
    spatialInterpolation: InterpolationType;
    temporalInterpolation: TemporalInterpolationType;
    wmsConfig: { [paramId: string]: ParameterWMSConfig}
    parameterBounds: { [paramId: string]: ParameterBound }
    availableLogDates?: DateRange // if undefined, no logs available
    availableForecasts?: DateRange // if undefined, no forecasts available
    hasBeenUpdated?: RoiUpdate;
    isSuspended: boolean;
    hasMissingDataWhenComplete?: boolean;
    isStopped: boolean;
    userAccess: RoiUserAccessStatus;
    accessList?: RoiAccess[] // Only userAccess === FULL will see this
    isOwner: boolean;
}

export interface RoiUpdate {
    user: string;
    action: UserInteractionType;
    timestamp: number;
}

export interface DateRange {
	firstTimestamp: number;
	lastTimestamp: number;
}

export interface ParameterBound {
    minValue: number,
    maxValue: number
}

export interface ParameterWMSConfig {
    minValue?: number,
    maxValue?: number
}

export interface DatasetVersion {
    forecastTimestamp: number,
    parameters: DatasetParameter[]
}

export interface DatasetParameter {
    parameterId: string,
    records: DatasetRecord[]
}

export interface DatasetRecord {
    timestamp: number,

    // netcdfUrl is only set in the legacy API
    // (GET /rois/{id}/data/netcdf) and is not available in the UI
    netcdfUrl?: string,
    mapFilePath?: string
}

export interface DataSourceReference {
    dataSourceId: string;
    subsetId?: string;
    parameterIds: string[];
}

export interface DataCollectionLogResp {
    logs: DataCollectionLog[];
    nextToken?: string;//for paging
}

export interface DataCollectionLog {
    timestamp: Date;
    type: DataCollectionLogType;
    category: string;
    message: string;
}

export interface RGBColor{
    r: number,
    g: number,
    b: number,
}

export enum DataCollectionLogType {
    ERROR,
    WARNING,
    INFO
}

export interface SpatialConstraint {
    projectionType: OutputProjectionType;
    minX: number;
    maxX: number;
    minY: number;
    maxY: number;
    facing?: Facing;
    zone?: number;
    gridResolution: number;
}

export enum RoiApprovalStatus {
    APPROVED,
    DECLINED,
    APPROVAL_PENDING
}

export enum Facing {
    N,
    S,
}

export enum RoiDataCollectionStatus {
    COMPLETE,
    ERROR,
    IN_PROGRESS,
    WAITING_TO_START,
    ONGOING
}

export enum OutputProjectionType {
    UTM,
    EQUIRECTANGULAR,
    CUSTOM_METERS
}

export enum InterpolationType {
   LINEAR,
   NEAREST_NEIGHBOR,
//    BICUBIC,
//    FIRST_ORDER_CONSERVATIVE,
//    SECOND_ORDER_CONSERVATIVE,
//    LARGEST_AREA_FRACTION
}

export enum TemporalInterpolationType {
    LINEAR,
    NONE
}

export interface DataIssue {
    timestamp: number;
}

export interface DataIssues {
    issues: DataIssue[];
}

export interface DataParams {
    issueTimestamp: number;
    params: DataParam[];
}

export interface DataParam {
    paramId: string;
}

export enum UserInteractionType {
    STOP,
    PAUSE,
    RESUME,
    TEMPORAL_CONSTRAINT_UPDATE,
    WMSCONFIG_UPDATE
}