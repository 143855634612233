import * as React from "react";
import { defaultMargin, tabPage, description, fullHeight, lightGray, row, textInputStyle, mediumFontSize, gray, smallFontSize } from "../../styles/styles";
import { OutputProjectionType, Facing, InterpolationType } from "../../../../lambda/interfaces/rois";
import { Dropdown, Button } from "react-bootstrap";
import { RoiOverview, ProjectionMap } from "../../components";
import { RoiPreview, SpatialPreview } from "../NewRoi";
import { Util } from "../../util/Util";
import { DataProvider, DEGREE_TO_KM } from "../../data/DataProvider";

export interface Props {
    roiPreview: RoiPreview;
    dataProvider: DataProvider;
    onSpatialDataChange: (sp: SpatialPreview) => void;   
    onSpatialInterpolationChange: (interpolation: InterpolationType) => void;
}

export interface State {
}

export default class SpatialConstraints extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
       
        this.state = {
        }
    }

    public render(): JSX.Element
    {

        let mapProjectionId = '';
        if(this.props.roiPreview.spatialConstraint.projectionType == OutputProjectionType.UTM && this.props.roiPreview.spatialConstraint.zone !== undefined && this.props.roiPreview.spatialConstraint.facing !== undefined){
            mapProjectionId = Util.getUTMProjectionIdentifierForFetch(this.props.roiPreview);          
        }  

        const inputsDisabled = this.props.roiPreview.spatialConstraint.projectionType === undefined || 
        (this.props.roiPreview.spatialConstraint.projectionType == OutputProjectionType.UTM && (this.props.roiPreview.spatialConstraint.zone === undefined || this.props.roiPreview.spatialConstraint.facing === undefined));

        return (
                 <div style={{ ...fullHeight, ...tabPage}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginBottom: defaultMargin}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div>Output projection:</div>
                            <Dropdown>
                                <Dropdown.Toggle className="shell-dropdown" id="dropdown-spatial-output-projection" style={{width: 200}}>
                                    { this.props.roiPreview.spatialConstraint.projectionType === undefined ? 'Select projection' : Util.getProjectionName(this.props.roiPreview.spatialConstraint.projectionType)}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item  onSelect={() => {
                                            if(this.props.roiPreview.spatialConstraint.projectionType !== OutputProjectionType.EQUIRECTANGULAR){
                                                let defaultGridResolution = 1;
                                                if(this.props.roiPreview.dataSources !== undefined &&  this.props.roiPreview.dataSources.length === 1){
                                                    defaultGridResolution = this.props.dataProvider.getDataSourceById(this.props.roiPreview.dataSources[0].dataSourceId).spatialConstraint.gridResolution;
                                                }                                              
                                                this.props.onSpatialDataChange(Object.assign(this.props.roiPreview.spatialConstraint, 
                                                    {projectionType: OutputProjectionType.EQUIRECTANGULAR, zone: undefined, facing: undefined, minX: undefined, maxX: undefined, minY: undefined, maxY: undefined, gridResolution: defaultGridResolution}));
                                            }                                
                                            }}>Equirectangular</Dropdown.Item>
                                    <Dropdown.Item onSelect={() => {          
                                        if(this.props.roiPreview.spatialConstraint.projectionType !== OutputProjectionType.UTM){ 
                                            let defaultGridResolution = 5000;
                                                if(this.props.roiPreview.dataSources !== undefined &&  this.props.roiPreview.dataSources.length === 1){
                                                    defaultGridResolution = this.props.dataProvider.getDataSourceById(this.props.roiPreview.dataSources[0].dataSourceId).spatialConstraint.gridResolution * DEGREE_TO_KM;
                                                    defaultGridResolution = Math.round(defaultGridResolution * 10) / 10;
                                                }   
                                            this.props.onSpatialDataChange(Object.assign(this.props.roiPreview.spatialConstraint, 
                                                {projectionType: OutputProjectionType.UTM, minX: undefined, maxX: undefined, minY: undefined, maxY: undefined, gridResolution: defaultGridResolution}));
                                        }                                 
                                        }} >Universal Transverse Mercator</Dropdown.Item>                              
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                      
                        {
                            this.props.roiPreview.spatialConstraint.projectionType == OutputProjectionType.UTM &&
                            <input
                                type="number"                               
                                style={{width: 100, marginLeft: defaultMargin, marginRight: defaultMargin, ...textInputStyle}}
                                placeholder="Zone" 
                                className="shell-input"
                                min={1}
                                max={60}
                                value={this.props.roiPreview.spatialConstraint.zone !== undefined ? this.props.roiPreview.spatialConstraint.zone : ''}                               
                                onChange={event => 
                                    this.props.onSpatialDataChange(Object.assign(this.props.roiPreview.spatialConstraint, 
                                    {zone: event.target.value, minX: undefined, maxX: undefined, minY: undefined, maxY: undefined}))
                                }
                            /> 
                        }
                        {
                            this.props.roiPreview.spatialConstraint.projectionType == OutputProjectionType.UTM &&
                            <Dropdown>
                                <Dropdown.Toggle className="shell-dropdown" id="dropdown-spatial-facing" >
                                    {this.props.roiPreview.spatialConstraint.facing === undefined ? "Facing" : Util.getFacingString(this.props.roiPreview.spatialConstraint.facing)}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onSelect={() => {
                                        if(this.props.roiPreview.spatialConstraint.facing !== Facing.N){
                                            this.props.onSpatialDataChange(Object.assign(this.props.roiPreview.spatialConstraint, 
                                                {facing: Facing.N, minX: undefined, maxX: undefined,  minY: undefined, maxY: undefined}));
                                        }                                       
                                        }}
                                            >N</Dropdown.Item>
                                    <Dropdown.Item onSelect={() => {
                                        if(this.props.roiPreview.spatialConstraint.facing !== Facing.S){
                                            this.props.onSpatialDataChange(Object.assign(this.props.roiPreview.spatialConstraint, 
                                                {facing: Facing.S,  minX: undefined, maxX: undefined,  minY: undefined, maxY: undefined}));
                                        }                                      
                                    }}
                                    >S</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                    
                    </div>
                   
                    <div style={{display: 'flex', flexDirection: 'row', flex: 1,  justifyContent: 'center', alignItems: 'center'}} >
                        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginRight: defaultMargin}}>
                            <input
                                type="number"
                                className="shell-input"
                                style={{width: 100, ...textInputStyle}}
                                placeholder="Min X"
                                disabled={inputsDisabled}
                                value={this.props.roiPreview.spatialConstraint.minX || ''}
                                onChange={event => this.props.onSpatialDataChange(Object.assign(this.props.roiPreview.spatialConstraint, {minX: event.target.value}))}
                            /> 
                            <div style={{fontSize: smallFontSize, color: gray, fontWeight: 'bold'}}>(&gt;= -180)</div>
                        </div>                       
                        <div style={{...fullHeight, width: '100%', marginTop: defaultMargin , justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                            <input
                                type="number"
                                className="shell-input"
                                style={{width: 200, marginBottom: defaultMargin, ...textInputStyle}}
                                placeholder="Max Y"
                                disabled={inputsDisabled}
                                value={this.props.roiPreview.spatialConstraint.maxY || ''}
                                onChange={event => this.props.onSpatialDataChange(Object.assign(this.props.roiPreview.spatialConstraint, {maxY: event.target.value}))}
                            /> 
                            <div style={{height: '100%', backgroundColor: lightGray, width: '100%'}}>
                                <ProjectionMap 
                                    utmProjectionStringIdentifier={mapProjectionId} 
                                    projectionType={this.props.roiPreview.spatialConstraint.projectionType}
                                    onRectDrawn={(minX, minY, maxX, maxY) => {                                    
                                        this.props.onSpatialDataChange(Object.assign(this.props.roiPreview.spatialConstraint, {minX: minX, maxX: maxX, minY: minY, maxY: maxY}));
                                    }}
                                    minX={this.props.roiPreview.spatialConstraint.minX}
                                    maxX={this.props.roiPreview.spatialConstraint.maxX}
                                    minY={this.props.roiPreview.spatialConstraint.minY}
                                    maxY={this.props.roiPreview.spatialConstraint.maxY}
                                />
                            </div>
                            <input
                                type="number"
                                style={{width: 200, marginTop: defaultMargin, ...textInputStyle}}
                                placeholder="Min Y"
                                className="shell-input"
                                disabled={inputsDisabled}
                                value={this.props.roiPreview.spatialConstraint.minY || ''}
                                onChange={event => this.props.onSpatialDataChange(Object.assign(this.props.roiPreview.spatialConstraint, {minY: event.target.value}))}
                            /> 
                        </div>                
                        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginLeft: defaultMargin}}>
                            <input
                                type="number"
                                className="shell-input"
                                style={{width: 100,  ...textInputStyle}}
                                placeholder="Max X"        
                                disabled={inputsDisabled}
                                value={this.props.roiPreview.spatialConstraint.maxX || ''}                   
                                onChange={event => this.props.onSpatialDataChange(Object.assign(this.props.roiPreview.spatialConstraint, {maxX: event.target.value}))}
                            /> 
                            <div style={{fontSize: smallFontSize, color: gray, fontWeight: 'bold'}}>{"(<= 180)"}</div>
                        </div>                             
                    </div>
                </div>       
        );
    }
}