import * as React from "react";
import { Button, Tab, Tabs, Navbar, TabContent, TabPane, Modal, Image } from 'react-bootstrap';
import SpatialConstraints from "./roi-creation/SpatialConstraints";
import ParamSelection from "./roi-creation/ParamSelection";
import TemporalConstraintsTab from "./roi-creation/TemporalConstraints";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faQuestion, faTimes } from '@fortawesome/free-solid-svg-icons';

import "../styles/styles.css";
import { defaultPadding, fullHeight, row, defaultMargin, tabPage } from "../styles/styles";
import { InterpolationType, OutputProjectionType, DataSourceReference, Facing, TemporalInterpolationType } from "../../../lambda/interfaces/rois";
import { DataCollectionDateType } from "../../../lambda/interfaces/sources";
import { RoiOverview } from "../components";
import { DataProvider, DEGREE_TO_KM } from "../data/DataProvider";

export interface Props {
    dataProvider: DataProvider;
}

export interface State {
    roiPreview: RoiPreview;
    cancelModalVisible: boolean;
    showValidation: boolean;
    selectedTabKey: string;
}

export interface SpatialPreview {
    projectionType: OutputProjectionType | undefined;
    minX: number | undefined;
    maxX: number | undefined;
    minY: number | undefined;
    maxY: number | undefined;
    zone: number | undefined;
    facing: Facing | undefined;
    gridResolution: number | undefined;
}

export interface TemporalPreview {
    dataCollectionDateType: DataCollectionDateType;
    firstDate: Date | undefined; 
    lastDate: Date | undefined; 
    interval: number | undefined;
    disabled: boolean;
}

export interface RoiPreview {
    name: string;
    dataSources: DataSourceReference[];
    spatialConstraint: SpatialPreview | undefined;
    temporalConstraint: TemporalPreview | undefined;
    spatialInterpolation: InterpolationType | undefined;
    temporalInterpolation: TemporalInterpolationType | undefined;  
}

export enum TabKeys {
    SPATIAL = 'spatial',
    TEMPORAL = 'temporal',
    PARAM_SELECTION = 'param',
    INTERPOLATION = 'interpolation'
}

export default class NewROI extends React.Component<Props, State>
{
    _input: any;
    
    constructor(props: Props)
    {
        super(props);     
        this.state = {
            selectedTabKey: TabKeys.SPATIAL,
            cancelModalVisible: false,
            showValidation: false,
            roiPreview: {
                name: '',
                dataSources: [],
                spatialConstraint: {
                    projectionType: undefined,
                    minX: undefined,
                    maxX: undefined,
                    minY: undefined,
                    maxY: undefined,
                    zone: undefined,
                    facing: undefined,
                    gridResolution: undefined
                },
                temporalConstraint: {
                    dataCollectionDateType: DataCollectionDateType.FIXED,
                    firstDate: undefined,
                    lastDate: undefined,
                    interval: undefined,
                    disabled: false,
                },
                spatialInterpolation: InterpolationType.LINEAR,
                temporalInterpolation: TemporalInterpolationType.LINEAR,
            }
        } 
    }

    componentDidMount() {
        this._input.focus();
      }

    spatialDataChage = (spatialData: SpatialPreview) => {
        this.setState({roiPreview: Object.assign({}, this.state.roiPreview, {spatialConstraint: spatialData})});
    }

    dataSourceRefUpdate = (dsrefs: DataSourceReference[]) => {
        const grOrig = this.props.dataProvider.getDataSourceById(dsrefs[0].dataSourceId).spatialConstraint.gridResolution;     //currently only a single ds can be selected anyway
        const grAsDegrees = (this.props.dataProvider.getDataSourceById(dsrefs[0].dataSourceId).spatialConstraint.projectionType === OutputProjectionType.CUSTOM_METERS ||
                             this.props.dataProvider.getDataSourceById(dsrefs[0].dataSourceId).spatialConstraint.projectionType === OutputProjectionType.UTM)
                             ? grOrig / DEGREE_TO_KM : grOrig; 
        // grAsDegrees has been added to account for meters
        // All orig units in meters are now coverted first to degrees
        // NOTE: degrees_to_km is actually degrees_to_m
        // There is a bug on New ROi -> Temporal Constaint tab : units are m? not km?
        const grByProj = this.state.roiPreview.spatialConstraint.projectionType === OutputProjectionType.UTM ? Math.round(grAsDegrees * DEGREE_TO_KM * 10) / 10 : grAsDegrees;

        const tempResolutions = this.props.dataProvider.getDataSourceById(dsrefs[0].dataSourceId).temporalConstraint.temporalResolutions;
        let interval: number;
        if(tempResolutions.length > 0){
            if(tempResolutions.length > 1){
                const val = tempResolutions.find(x => x.subsetId === dsrefs[0].subsetId);
                interval = parseInt(val.interval.toString());
            }else{           
                interval = parseInt(tempResolutions[0].interval.toString());
            }  
        }
            
        this.setState({roiPreview: Object.assign({}, this.state.roiPreview, 
            {dataSources: dsrefs, 
            spatialConstraint: {...this.state.roiPreview.spatialConstraint, gridResolution: grByProj}, 
            temporalConstraint: {...this.state.roiPreview.temporalConstraint, interval: interval, disabled: tempResolutions.length == 0}})});
    }

    temporalDataChange = (dataCollectionDateType: DataCollectionDateType, firstDate: Date, lastDate: Date, interval: number, disabled: boolean) => {
        this.setState({roiPreview: Object.assign({}, this.state.roiPreview, {temporalConstraint: {
            dataCollectionDateType,
            firstDate, 
            lastDate, 
            interval,
            disabled
        }})});
    }

    spatialInterpolationChange = (intp: InterpolationType) => {
        this.setState({roiPreview: Object.assign({}, this.state.roiPreview, {spatialInterpolation: intp})});
    }

    temporalInterpolationChange = (intp: TemporalInterpolationType) => {
        this.setState({roiPreview: Object.assign({}, this.state.roiPreview, {temporalInterpolation: intp})});
    }

    updateValidationVisibility = (v: boolean) => {
        this.setState({showValidation: v});
    }

    paramRemoved = (paramId: string, dsId: string) => {
        let dsref: DataSourceReference = this.state.roiPreview.dataSources.find(x => x.dataSourceId == dsId);              
        const groupIndex = this.state.roiPreview.dataSources.indexOf(dsref);
        const paramIndex = dsref.parameterIds.indexOf(paramId);
        let newParams = Object.assign(dsref.parameterIds);
        newParams.splice(paramIndex, 1);
        let newGroups: DataSourceReference[] = Object.assign(this.state.roiPreview.dataSources);
        if(newParams.length == 0){
            newGroups.splice(groupIndex, 1);
        }else{         
            newGroups[groupIndex].parameterIds = newParams;
        }
        this.dataSourceRefUpdate(newGroups);   
    }

    public render(): JSX.Element
    {
        return (
            <div style={{...fullHeight, display: 'flex', flexDirection: 'column', }}>
                <Navbar className="shell-navbar justify-content-between" expand="lg" style={{paddingTop: defaultPadding , paddingBottom: defaultPadding }} > 
                <div style={{...row, alignItems: 'center'}}>
                    <Image src={require("../assets/shell-icon.png")} className="shell-logo" fluid />
                    <input
                        type="text"
                        ref={c => (this._input = c)}
                        className="shell-input"
                        style={{width: 500,}}
                        placeholder="Name of the new ROI"
                        value={this.state.roiPreview.name}
                        onChange={(event) => {
                            this.setState({roiPreview: Object.assign({}, this.state.roiPreview, {name: event.target.value})});
                        }}
                    />       
                </div>
                <div style={{...row}}>  
                    <Button 
                        className="shell-yellow-btn"
                        style={{ ...row, alignItems: 'center', marginRight: defaultMargin}}
                        onClick={() => { window.open('/support/index.html'); }}
                    >
                        <FontAwesomeIcon icon={faQuestion} style={{fontSize: 15}} className="shell-yellow-btn-icon"/>
                        <div style={{marginLeft: 5}}>Help</div>
                    </Button>              
                    <Button 
                        className="shell-yellow-btn"
                        style={{ ...row, alignItems: 'center', marginRight: defaultMargin}}
                        onClick={() => this.setState({cancelModalVisible: true})}
                    >
                        <FontAwesomeIcon icon={faTimes} style={{fontSize: 15}} className="shell-yellow-btn-icon"/>
                        <div style={{marginLeft: 5}}>Cancel</div>
                    </Button>                                        
                </div>                         
                </Navbar>  
                <div style={{display: 'flex', flexDirection: 'column', width: '100%', top: 56, bottom: 0, position: 'absolute'}}>
                    <Tabs 
                        activeKey={this.state.selectedTabKey} 
                        id="newROI_tabs" style={{display: 'flex', backgroundColor: 'gray' }} 
                        onSelect={(key) => this.setState({selectedTabKey: key})}
                        >       
                        <Tab eventKey={TabKeys.SPATIAL}  title="SPATIAL CONSTRAINTS" >  
                                <div style={{...tabPage, ...fullHeight, ...row }} >
                                    <div style={{flex: 2, flexShrink: 0}}>
                                        <SpatialConstraints 
                                            dataProvider={this.props.dataProvider}
                                            roiPreview={this.state.roiPreview} 
                                            onSpatialDataChange={(sp) => this.spatialDataChage(sp)} 
                                            onSpatialInterpolationChange={(intp) => this.spatialInterpolationChange(intp)}
                                            />
                                    </div>
                                    <div style={{flex: 1, maxWidth: 520}}>
                                        <RoiOverview 
                                            dataProvider={this.props.dataProvider}
                                            roiPreview={this.state.roiPreview} 
                                            showValidation={this.state.showValidation} 
                                            updateValidationVisibility={this.updateValidationVisibility}
                                            readonly={false}
                                            onParameterRemove={(pId, dsId) => this.paramRemoved(pId, dsId)}
                                            activeTabKey={TabKeys.SPATIAL}
                                            onNextButtonPressed={() => this.setState({selectedTabKey: TabKeys.PARAM_SELECTION})}
                                            />
                                    </div>  
                                </div>                                                                                        
                            </Tab>                                                   
                            <Tab eventKey={TabKeys.PARAM_SELECTION} title="PARAMETER SELECTION" >
                                <div style={{...tabPage, ...fullHeight, ...row }} >
                                    <div style={{flex: 2}}>
                                        <ParamSelection dataProvider={this.props.dataProvider} roiPreview={this.state.roiPreview} updateDataSourceReferences={(dsrefs) => this.dataSourceRefUpdate(dsrefs)} />
                                    </div>
                                    <div style={{flex: 1, maxWidth: 520}}>
                                        <RoiOverview 
                                            dataProvider={this.props.dataProvider}
                                            roiPreview={this.state.roiPreview} 
                                            showValidation={this.state.showValidation} 
                                            updateValidationVisibility={this.updateValidationVisibility}
                                            readonly={false}
                                            onParameterRemove={(pId, dsId) => this.paramRemoved(pId, dsId)}
                                            activeTabKey={TabKeys.PARAM_SELECTION}
                                            onPreviousButtonPressed={() => this.setState({selectedTabKey: TabKeys.SPATIAL})} 
                                            onNextButtonPressed={() => this.setState({selectedTabKey: TabKeys.TEMPORAL})}                                          
                                        />
                                    </div>  
                                </div> 
                            </Tab>
                            <Tab eventKey={TabKeys.TEMPORAL} title="TEMPORAL CONSTRAINTS" >
                                <div style={{...tabPage, ...fullHeight, ...row }} >
                                    <div style={{flex: 2}}>
                                        <TemporalConstraintsTab 
                                            roiPreview={this.state.roiPreview} 
                                            onTemporalDataChange={this.temporalDataChange.bind(this)}
                                            onSpatialDataChange={(sp) => this.spatialDataChage(sp)}
                                            onTemporalInterpolationChange={(intp) => this.temporalInterpolationChange(intp)}
                                            onSpatialInterpolationChange={(intp) => this.spatialInterpolationChange(intp)}
                                            />
                                    </div>
                                    <div style={{flex: 1, maxWidth: 520}}>
                                        <RoiOverview 
                                            dataProvider={this.props.dataProvider}
                                            roiPreview={this.state.roiPreview} 
                                            showValidation={this.state.showValidation} 
                                            updateValidationVisibility={this.updateValidationVisibility}
                                            readonly={false}
                                            onParameterRemove={(pId, dsId) => this.paramRemoved(pId, dsId)}
                                            activeTabKey={TabKeys.TEMPORAL}                                           
                                            onPreviousButtonPressed={() => this.setState({selectedTabKey: TabKeys.PARAM_SELECTION})}
                                            />
                                    </div>  
                                </div>
                            </Tab>   
                    </Tabs>
                </div>              
                <Modal show={this.state.cancelModalVisible} onHide={() => this.setState({cancelModalVisible: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Cancel creation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>By canceling the creation process, all the information you set, will be lost. Are you sure about this action?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="shell-secondary-btn" onClick={() => this.setState({cancelModalVisible: false})}>No</Button>
                        <Link to="/"><Button className="shell-gray-btn">Yes</Button></Link>
                    </Modal.Footer>
                </Modal>                                  
            </div>            
        );
    }
}