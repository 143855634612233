import * as React from "react";
import { faEnvelope, faKey, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spinner, Form, InputGroup, Button, Image, Alert } from "react-bootstrap";
import { Redirect } from 'react-router-dom';
import { fullHeight, row, lightGray2, defaultMargin, largeFontSize, shellRed, defaultPadding, shellYellow, gray } from "../../styles/styles";
const splash = require('../../assets/splash.jpg');

if (window.location.host.startsWith('www.')) {
    window.location.replace(`https://${window.location.host.substring(4)}`);
}

let domainUrl: string;
if (window.location.host === "development.shellmetnetspatial.com" || window.location.host.startsWith("localhost")) {
    domainUrl = 'development.shellmetnetspatial.com';
} else {
    domainUrl = 'shellmetnetspatial.com';
}

interface Props
{
    setUserLoggedIn: (isAdmin: boolean) => Promise<void>;
}

interface State {
    password: string;
    email: string;
    loginError: string;
    loginState: LoginState;
}

enum LoginState {
    credentialsEntry,
    loggingIn,
    loggedIn
}

interface DelegatedLoginResponse {
    Token?: string;
    Message?: string;
    IsAdmin?: boolean;
}

export default class Login extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state = {
           password: '',
           email: '',
           loginError: null,
           loginState: LoginState.credentialsEntry
        };
    }

    async validateLoginCredentials() {
        this.setState({loginState: LoginState.loggingIn}); 


        const loginFormData = new FormData();
        loginFormData.set('Email', this.state.email);
        loginFormData.set('Password', this.state.password);
        loginFormData.set('AppId', 'mns');

        try {
            let resp = await fetch(
                'https://www.shellmetnetglobal.com/api/Session/DelegatedLogin', {
                    method: 'POST',
                    body: loginFormData,
                    
                });
            var respBody = (await resp.json()) as DelegatedLoginResponse;
            if (respBody.Message) {
                this.setState({loginState: LoginState.credentialsEntry, loginError: respBody.Message});
            } else if (!respBody.Token) {
                this.setState({loginState: LoginState.credentialsEntry, loginError: "Token missing from METNET Global serer response."});
            } else {
                // set cookie before state so API calls have cookie
                document.cookie = `token=${respBody.Token}; domain=${domainUrl}; secure`;
                await this.props.setUserLoggedIn(respBody.IsAdmin);
                this.setState({loginState: LoginState.loggedIn, loginError: null});
            }
        } catch (err) {
            this.setState({loginState: LoginState.credentialsEntry, loginError: err});
        }
    }

    public render(): JSX.Element
    {
        if(this.state.loginState == LoginState.loggedIn) {
            return <Redirect to='/' />;
        }

        return (
            <div style={{...fullHeight, ...row}}>                         
               <div style={{...fullHeight, backgroundImage: `url(${splash})`, flex: 3, backgroundRepeat: 'no-repeat', backgroundSize:'cover'}}>
                   <div style={{backgroundColor: shellYellow, padding: defaultPadding * 2, marginTop: 50, marginLeft: 50, width: '50%'}}>
                       <div style={{fontWeight: 'bold', color: shellRed, fontSize: largeFontSize}}>METNET Spatial provides a consistent interface to access spatial (gridded) metocean data.</div>
                       <div style={{color: gray, marginTop: defaultMargin}}>Offering powerful, user-friendly tools to locate, request, retrieve, standardise, visualise and download from a variety of internal and third-party data sources.</div>
                   </div>
               </div>
               <div style={{...fullHeight, flex: 1, padding: defaultMargin * 2}}>
               <div style={{...row, marginBottom: defaultMargin * 3}}>
               <Image src={require("../../assets/shell-icon.png")} style={{height: 46.5, marginRight: defaultMargin}} fluid />
                   <div style={{color: shellRed, fontSize: 32, fontWeight: 'bold'}}>METNET SPATIAL</div>
               </div>
                   <div style={{...row, justifyContent: 'space-between', marginBottom: defaultMargin}}>
                        <div style={{fontSize: largeFontSize, fontWeight: 'bold', color: 'gray'}}>Log in</div>
                   </div>

                   <div style={{...row, justifyContent: 'space-between', marginBottom: defaultMargin}}>
                        <div><span>Log in to METNET Spatial using the same email address and password that you
                            use for METNET Global. If you do not have a METNET Global account, or have
                            forgotten your password, then please resolve this first at the </span><a href="https://www.shellmetnetglobal.com" target="_blank">METNET Global login page</a><span>.</span></div>
                   </div>

                    <div style={{...row, justifyContent: 'space-between', marginBottom: defaultMargin}}>
                        <div>Your METNET Global account will need to be approved for METNET Spatial access.
                        To request this, please contact your METNET Global regional administrator.</div>
                    </div>
                   
                   <Form onSubmit={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                       this.validateLoginCredentials();
                        
                   }}>
                   <Form.Group controlId="validationEmail">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faEnvelope} style={{fontSize: 15}} />
                            </InputGroup.Text>                       
                        </InputGroup.Prepend>
                        <Form.Control
                        type="text"
                        placeholder="Email address"
                        required
                        className="shell-input"
                        value={this.state.email}
                        onChange={(evt) => this.setState({email: evt.target.value})}
                        disabled={this.state.loginState != LoginState.credentialsEntry}
                        />
                    </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="validationPassword">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faKey} style={{fontSize: 15}} />
                            </InputGroup.Text>                       
                        </InputGroup.Prepend>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            required
                            className="shell-input"
                            value={this.state.password}   
                            onChange={(evt) => this.setState({password: evt.target.value})}
                            disabled={this.state.loginState != LoginState.credentialsEntry}
                        />
                    </InputGroup>
                    </Form.Group>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: defaultMargin}}>
                        <Button type="submit" className="shell-gray-btn"
                            style={{ ...row, alignItems: 'center', marginRight: defaultMargin}}
                            disabled={this.state.loginState != LoginState.credentialsEntry}
                        >
                            <FontAwesomeIcon icon={faSignInAlt} style={{fontSize: 15}} className="shell-gray-btn-icon"/> 
                            <div style={{marginLeft: 5}}>{this.state.loginState == LoginState.loggingIn ? "Logging in..." : "Log in"}</div>
                        </Button>
                    </div> 
                    {this.state.loginError && <Alert variant="danger">{this.state.loginError}</Alert>}         
                   </Form>
               </div>
            </div>        
        );
    }
}

