import * as React from "react";
import { Roi, DatasetRecord, DatasetParameter, DataIssues, DataParams, DataParam } from "../../../../lambda/interfaces/rois";
import { VisualizationMap, MapFileConfigurator } from "../../components";
import { row, fullHeight, defaultMargin, shellDarkGray } from "../../styles/styles";
import { Card, Button } from "react-bootstrap";
import { DataProvider, RequestStatus } from "../../data/DataProvider";
import { WMSSettingsModal } from "../../components/modals/WMSSettingsModal";
import { ColorScaleModal } from "../../components/modals/ColorScaleModal";
import { DataSelector } from "../../components/selectors/DataSelector";
import { LoadingIndicator } from "../../components/LoadingIndicator";

let baseUrl: string;
if (window.location.host.startsWith("localhost")) {
  baseUrl = 'http://127.0.0.1:8085/cgi-bin/mapserv?map=';
}
else if (window.location.host === "development.shellmetnetspatial.com") {
    baseUrl = 'https://mapserver.development.shellmetnetspatial.com/cgi-bin/mapserv?map=';
} else {
    baseUrl = 'https://mapserver.shellmetnetspatial.com/cgi-bin/mapserv?map=';
}

export interface Props {
    roi: Roi;
    dataProvider: DataProvider;
    currentlySelectedTab: boolean;
    canUpdate: boolean;

    dataIssues: DataIssues;
    selectedIssueTimestamp: number;
    dataSetFetchStatus: RequestStatus;
    selectedYearUtc: number;
    selectedMonthUtc: number;
    dataParams: DataParams;
    selectedParam: string | null;
    datasetParameter: DatasetParameter;
    selectedRecordTimestamp: number;

    updateDataIssues: () => any;
    updateSelectedIssueTimestamp: (selectedTs: number) => any;
    onDatePickerNavigate: (month: number, year: number) => any;
    onDatePickerDateSelect: () => any;
    updateSelectedParam: (param: string) => any;
    onSelectRecord: (recordTs: number) => any;
}

export interface State {
    wmsSettingsModalVisible: boolean;
    colorScaleModalVisible: boolean;
    mapKey: string; //this will be used to refresh the map on color changes
}

export default class Visualization extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state = {
          wmsSettingsModalVisible: false,
          colorScaleModalVisible: false,
          mapKey: new Date().getTime().toString(),
        }
    }

    updateMapKey(newKey: string){
        this.setState({mapKey: newKey});
    }

    public async componentDidMount() {
        //default to currently selected month
        await this.props.updateDataIssues();
    }

    public render(): JSX.Element
    {
        let dsr: DatasetRecord = null;
        if(this.props.datasetParameter && this.props.selectedRecordTimestamp && this.props.datasetParameter.records.findIndex(x => x.timestamp === this.props.selectedRecordTimestamp) !== -1){
            dsr = this.props.datasetParameter.records.find(x => x.timestamp === this.props.selectedRecordTimestamp);
        }  

        let wmsServerUrl = dsr && dsr.mapFilePath ? baseUrl + dsr.mapFilePath : "";  
        let showLoader = this.props.dataSetFetchStatus === RequestStatus.NOT_STARTED || this.props.dataSetFetchStatus === RequestStatus.IN_PROGRESS;

        let params: DataParam[] = [];
        if(this.props.dataParams)
        {
            params = this.props.dataParams.params;
        }

        return (
            <div style={{ ...fullHeight, display: 'flex', flexDirection: 'column', color: shellDarkGray }}> 
                    {
                        showLoader &&
                        <LoadingIndicator message="Loading ROI data..."/>
                    }
                    <div style={{...row, width: '100%', justifyContent: 'space-between', alignItems: 'flex-end', flexWrap: 'wrap'}}>
                        <DataSelector 
                            selectedIssueTimestamp={this.props.selectedIssueTimestamp}
                            updateSelectedIssueTimestamp={this.props.updateSelectedIssueTimestamp.bind(this)}
                            dataIssues={this.props.dataIssues}
                            onDatePickerNavigate={this.props.onDatePickerNavigate.bind(this)}
                            onDatePickerDateSelect={this.props.onDatePickerDateSelect.bind(this)}
                            month={this.props.selectedMonthUtc}
                            year={this.props.selectedYearUtc}
                            updateSelectedParam={this.props.updateSelectedParam.bind(this)}
                            selectedParamId={this.props.selectedParam}
                            availableRecords={this.props.datasetParameter && this.props.datasetParameter.records || []}
                            availableParams={this.props.dataParams && this.props.dataParams.params.map(x => x.paramId) || []}
                            onSelectRecord={(selectedRecordTimestamp) => {
                                this.props.onSelectRecord(selectedRecordTimestamp);
                                this.setState({
                                    mapKey: new Date().getTime().toString()
                                });
                            }}
                            selectedRecordTimestamp={this.props.selectedRecordTimestamp}
                        />
                        {
                            this.props.canUpdate && (
                                <div style={{...row, alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: defaultMargin}}>                    
                                    {
                                        (dsr && dsr.mapFilePath) && 
                                        <Button className="shell-gray-btn" style={{ marginLeft: defaultMargin}} onClick={() => this.setState({wmsSettingsModalVisible: true})}>WMS Settings</Button>
                                    }
                                    {
                                        this.props.dataParams && <Button className="shell-gray-btn" style={{width: 200,  marginLeft: defaultMargin}} onClick={() => this.setState({colorScaleModalVisible: true})}>Parameter Colour Scale</Button> 
                                    }
                                </div> 
                            )
                        }              
                    </div>                                                                  
                    <div style={{ height: '90%', margin: defaultMargin, marginTop: defaultMargin * 2,}}>
                        {
                             !dsr &&
                             <Card className="shell-block" style={{ margin: defaultMargin, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                 <div>The visualization interface requires the selection of a parameter at an available timestamp.</div>                         
                            </Card>
                        }
                        {
                            this.props.currentlySelectedTab &&
                            dsr &&
                            dsr.mapFilePath &&
                            <VisualizationMap mapFilePath={dsr.mapFilePath} key={this.state.mapKey} roi={this.props.roi}/>
                        }
                        {
                            dsr && 
                            !dsr.mapFilePath &&
                            <MapFileConfigurator dataProvider={this.props.dataProvider} dsr={dsr} issueTimestamp={this.props.selectedIssueTimestamp} dataTimestamp={dsr.timestamp} param={this.props.selectedParam} roiId={this.props.roi.id} />
                        }
                        
                    </div>  
                    <WMSSettingsModal 
                        visible={this.state.wmsSettingsModalVisible} 
                        onModalClose={() => this.setState({wmsSettingsModalVisible: false})} 
                        wmsServerUrl={ wmsServerUrl} 
                    />    
                    <ColorScaleModal 
                        visible={this.state.colorScaleModalVisible}
                        params={params}
                        onModalClose={() => {
                            //attempt to force map redraw on modal close by updating map key
                            this.setState({colorScaleModalVisible: false, mapKey: new Date().getTime().toString()})
                        }}
                        dataSetRecord={dsr}
                        dataProvider={this.props.dataProvider}
                        roi={this.props.roi}
                    />
            </div>
        );
    }
}