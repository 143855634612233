import * as React from "react";
import {
    HashRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";

import Home from './Home';
import NewROI from "./NewRoi";
import Login from './auth/Login';
import PrivateRoute from './auth/PrivateRoute';
import { DataProvider, RequestStatus } from "../data/DataProvider";
import { fullHeight } from "../styles/styles";
import { LoadingIndicator } from "../components/LoadingIndicator";
import RoisMap from "./RoisMap";

export interface Props{
}

export interface State {
    dataProvider?: DataProvider;
    userLoggedIn: boolean;
    isAdmin?: boolean;
}

export class Main extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state = {
            userLoggedIn: false,
            isAdmin: false
        };
    }

    public render(): JSX.Element
    {
        return (
            <div style={{...fullHeight}}>               
                <Router>
                {
                    (this.state.userLoggedIn && this.state.dataProvider &&
                        (this.state.dataProvider.getDataLoadedStatus() == RequestStatus.NOT_STARTED || 
                        this.state.dataProvider.getDataLoadedStatus() == RequestStatus.IN_PROGRESS)) && 
                        !window.location.hash.includes("/roi/") && //until single roi fetch is available => this line prevents showing 2 loading screens on each other
                    <LoadingIndicator message="Loading data..." />
                }
                    <Switch>                    
                        <Route path="/login">
                            <Login setUserLoggedIn={(isAdmin: boolean) => 
                                new Promise<void>((resolve, reject) => {
                                    this.setState({
                                        userLoggedIn: true,
                                        isAdmin: isAdmin,
                                        dataProvider: new DataProvider(this)
                                    }, resolve);
                                })} />
                        </Route>
                        <PrivateRoute path="/newROI" userLoggedIn={this.state.userLoggedIn} isAdmin={this.state.isAdmin}>                  
                                <NewROI dataProvider={this.state.dataProvider} />
                        </PrivateRoute>
                        <PrivateRoute path="/roisMap" userLoggedIn={this.state.userLoggedIn} isAdmin={this.state.isAdmin}>                  
                                <RoisMap dataProvider={this.state.dataProvider} isAdmin={this.state.isAdmin} rois={this.state.dataProvider ? this.state.dataProvider.getRois(): []} />
                        </PrivateRoute>
                        <PrivateRoute 
                            path="/roi/:id" userLoggedIn={this.state.userLoggedIn}
                            dataProvider={this.state.dataProvider}    
                            isAdmin={this.state.isAdmin}                        
                        />  
                        <PrivateRoute path="/" userLoggedIn={this.state.userLoggedIn} isAdmin={this.state.isAdmin}>
                                <Home isAdmin={this.state.isAdmin} dataProvider={this.state.dataProvider} onLogoutUser={() => this.setState({userLoggedIn: false})}/>
                        </PrivateRoute>                   
                    </Switch>                 
                </Router>     
            </div>        
        );
    }
}