import {  OutputProjectionType, InterpolationType, RoiApprovalStatus, RoiDataCollectionStatus, Facing, DataCollectionLogType, Roi, TemporalInterpolationType, PostRoi, SpatialConstraint, RoiUserAccessStatus } from "../../../lambda/interfaces/rois";
import { IntersectionType, DataSourceType, DataCollectionDateType, TemporalInterval, TemporalConstraint } from "../../../lambda/interfaces/sources";
import { BoxStatus } from "../components";
import { lightOrange, lightRed, lightBlue } from "../styles/styles";
import { RoiPreview } from "../screens/NewRoi";

export class Util {

    public static formatNumber = (num: number) => {
        return (Math.round(num * 10000) / 10000).toFixed(4);
    }

    public static getIntersectionTypeName(intersection: IntersectionType) {
        switch(intersection){
            case IntersectionType.FULL:
                return "Full";
            case IntersectionType.NONE:
                return "None";
            case IntersectionType.PARTIAL:
                return "Partial";
            default:
        }
    }

    public static spatialCoordinatesDefined(spatialConstraint: SpatialConstraint): boolean{
        return spatialConstraint.minX !== undefined && spatialConstraint.maxX !== undefined && spatialConstraint.minY !== undefined && spatialConstraint.maxY !== undefined;
    }

    public static getUTMProjectionIdentifierForFetch(roi: Roi | RoiPreview){
        return 'WGS+84+%2F+UTM+zone+' + roi.spatialConstraint.zone + this.getFacingString(roi.spatialConstraint.facing);
    }

    public static getProjectionName(projectionType: OutputProjectionType) {
        switch(projectionType){
            case OutputProjectionType.UTM:
                return "UTM";
            case OutputProjectionType.EQUIRECTANGULAR:
                return "Equirectangular";
            case OutputProjectionType.CUSTOM_METERS:
                return "Custom Meters";
            default:
        }
    }

    public static getProjectionUnits(projectionType: OutputProjectionType) {
        switch(projectionType){
            case OutputProjectionType.UTM:
                return "km";
            case OutputProjectionType.EQUIRECTANGULAR:
                return "°";
            default:
                return "";
        }
    }

    public static getProjectionUnitsInMetersOrDeg(projectionType: OutputProjectionType) {
        switch(projectionType){
            case OutputProjectionType.UTM:
                return "m";
            case OutputProjectionType.EQUIRECTANGULAR:
                return "°";
            default:
                return "";
        }
    }

    public static getInterpolationName(interpolation: InterpolationType) {
        switch(interpolation){
            case InterpolationType.LINEAR:
                return "Linear";
            case InterpolationType.NEAREST_NEIGHBOR:
                return "Nearest neighbour";
            // case InterpolationType.BICUBIC:
            //     return "Bicubic";
            // case InterpolationType.FIRST_ORDER_CONSERVATIVE:
            //     return "First order conservative";
            // case InterpolationType.SECOND_ORDER_CONSERVATIVE:
            //     return "Second order conservative";
            // case InterpolationType.LARGEST_AREA_FRACTION:
            //     return "Largest area fraction";
            default:
        }
    }

    public static getTemporalInterpolationName(interpolation: TemporalInterpolationType) {
        switch(interpolation){
            case TemporalInterpolationType.LINEAR:
                return "Linear";
            case TemporalInterpolationType.NONE:
                return "None";
            
            default:
        }
    }

    public static getDataSourceTypeName(type: DataSourceType) {
        switch(type){
            case DataSourceType.FORECAST:
                return "Forecast";
            case DataSourceType.HINDCAST:
                return "Hindcast";
            case DataSourceType.REANALYSIS:
                return "Reanalysis";
                case DataSourceType.SATELLITE:
                return "Satellite";
            default:
        }
    }

    public static formatTimestamp(timestamp: number) {
        const date = new Date(timestamp);
        const month = (date.getUTCMonth() + 1) < 10 ? "0" +(date.getUTCMonth() + 1) : (date.getUTCMonth() + 1);
        const day = date.getUTCDate() < 10 ? "0" + date.getUTCDate() : date.getUTCDate();
        const hour = date.getUTCHours() < 10 ? "0" + date.getUTCHours() : date.getUTCHours();
        const minutes = date.getUTCMinutes() < 10 ? "0" + date.getUTCMinutes() : date.getUTCMinutes();
        return date.getUTCFullYear() + "-" + month + "-" + day + " " + hour + ":" + minutes + " UTC";
    }

    public static formatDate(date: Date) {
        date = new Date(date);//this is nasty because sometimes (from the datasource) we are assuming that we get a Date but actually get a string!
        return date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1).toString().padStart(2,'0') + "-" + date.getUTCDate().toString().padStart(2,'0');
    }

    public static getTemporalConstraintLabel(tempConst: TemporalConstraint){
        let firstDate: Date;
        if (tempConst.dynamicFirstDateOffsetInDays) {
            const tempDate = new Date();
            firstDate = new Date(tempDate.getUTCFullYear(), tempDate.getUTCMonth(), tempDate.getUTCDate() + tempConst.dynamicFirstDateOffsetInDays);
        }
        else {
            firstDate = tempConst.firstDate;
        }
        const lastDate = tempConst.dataCollectionDateType == DataCollectionDateType.ONGOING ? ' (ongoing)' :  " to " + Util.formatDate(tempConst.lastDate);
        return Util.formatDate(firstDate) + lastDate;
    }

    public static getColorForApprovalStatus = (status: RoiApprovalStatus) => {
        switch(status){
            case RoiApprovalStatus.APPROVAL_PENDING:
                return BoxStatus.WARNING;
            case RoiApprovalStatus.APPROVED:
                return BoxStatus.SUCCESS;
            case RoiApprovalStatus.DECLINED:
                return BoxStatus.ERROR;
            default:
        }
    }

    public static getColorForAccessStatus = (status: RoiUserAccessStatus) => {
        switch(status){
            case RoiUserAccessStatus.FULL:
                return BoxStatus.SUCCESS;
            case RoiUserAccessStatus.NONE:
                return BoxStatus.ERROR;
            case RoiUserAccessStatus.PENDING:
                return BoxStatus.WARNING;
            case RoiUserAccessStatus.READ:
                return BoxStatus.ONGOING;
            default:
        }
    }

    public static getFacingString = (f: Facing) => {
        switch(f){
            case Facing.N:
                return "N";
            case Facing.S:
                return "S";
            default:
        }
    }

    public static getLogTypeLabel = (type: DataCollectionLogType) => {
        switch(type){
            case DataCollectionLogType.ERROR:
                return "ERROR";
            case DataCollectionLogType.WARNING:
                return "WARNING";
            case DataCollectionLogType.INFO:
                return "INFO";
            default:
        }
    }

    public static getLogTypeColor = (type: DataCollectionLogType) => {
        switch(type){
            case DataCollectionLogType.ERROR:
                return lightRed;
            case DataCollectionLogType.WARNING:
                return lightOrange;
            case DataCollectionLogType.INFO:
                return lightBlue;
            default:
        }
    }

    public static getColorForCollectionStatus = (status: RoiDataCollectionStatus) => {
        switch(status){
            case RoiDataCollectionStatus.WAITING_TO_START:
                return BoxStatus.INFO;
            case RoiDataCollectionStatus.IN_PROGRESS:
                return BoxStatus.WARNING;
            case RoiDataCollectionStatus.COMPLETE:
                return BoxStatus.SUCCESS;
            case RoiDataCollectionStatus.ONGOING:
                return BoxStatus.ONGOING;
            case RoiDataCollectionStatus.ERROR:
                return BoxStatus.ERROR;
            default:
        }
    }

    public static getLabelForApprovalStatus = (status: RoiApprovalStatus) => {
        switch(status){
            case RoiApprovalStatus.APPROVAL_PENDING:
                return "Approval pending";
            case RoiApprovalStatus.APPROVED:
                return "Approved";
            case RoiApprovalStatus.DECLINED:
                return "Declined";
            default:
        }
    }

    public static getLabelForAccessStatus = (status: RoiUserAccessStatus) => {
        switch(status){
            case RoiUserAccessStatus.FULL:
                return "Full";
            case RoiUserAccessStatus.NONE:
                return "None";
            case RoiUserAccessStatus.PENDING:
                return "Pending";
            case RoiUserAccessStatus.READ:
                return "Read";
            default:
        }
    }

    public static prepareRoiForUpload(roiPreview: RoiPreview | Roi): PostRoi {
        return {
            dataSources: roiPreview.dataSources,
            name: roiPreview.name,
            spatialConstraint: roiPreview.spatialConstraint,
            temporalConstraint: {
                dataCollectionDateType: roiPreview.temporalConstraint.dataCollectionDateType,
                firstDate: roiPreview.temporalConstraint.firstDate,
                lastDate: roiPreview.temporalConstraint.lastDate !== undefined ? roiPreview.temporalConstraint.lastDate : new Date(),
                interval: roiPreview.temporalConstraint.interval as TemporalInterval,    
                disabled: roiPreview.temporalConstraint.disabled,    
            },
            spatialInterpolation: roiPreview.spatialInterpolation,
            temporalInterpolation: roiPreview.temporalInterpolation,
        };
    }

    public static getLabelForCollectionStatus = (status: RoiDataCollectionStatus) => {
        switch(status){
            case RoiDataCollectionStatus.WAITING_TO_START:
                return "Waiting to start";
            case RoiDataCollectionStatus.IN_PROGRESS:
                return "In progress";
            case RoiDataCollectionStatus.COMPLETE:
                return "Complete";
            case RoiDataCollectionStatus.ONGOING:
                return "Ongoing";
            case RoiDataCollectionStatus.ERROR:
                return "Error";
            default:
        }
    }

    public static canReadAccess = (userAccess: RoiUserAccessStatus) => userAccess === RoiUserAccessStatus.FULL || userAccess === RoiUserAccessStatus.READ;
    public static canUpdateAccess = (userAccess: RoiUserAccessStatus) => userAccess === RoiUserAccessStatus.FULL;
}