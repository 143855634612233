import * as React from "react";
import {  defaultMargin, row, dropDown } from '../../styles/styles'
import { DataProvider, RequestStatus } from "../../data/DataProvider";
import { Roi, DatasetRecord, DataParam } from "../../../../lambda/interfaces/rois";
import { Modal, Spinner, Button, Dropdown } from "react-bootstrap";

interface Props
{
    visible: boolean;
    onModalClose: () => void;
    params: DataParam[];
    dataSetRecord: DatasetRecord;
    dataProvider: DataProvider;
    roi: Roi;
}

interface State {
    selectedParam: string;
    minValue: number | undefined;
    maxValue: number | undefined;
    status: RequestStatus;

    minValueError?: string;
    maxValueError?: string;
}

// helpers:
const floatOrUndefined = (s: string) => {
    let val = parseFloat(s);
    return isNaN(val) ? undefined : val;
}

export class ColorScaleModal extends React.Component<Props, State>
{
    constructor(props: Props){
        super(props);
        this.state = {
           selectedParam: '',
           status: RequestStatus.NOT_STARTED,
           minValue: undefined,
           maxValue: undefined,
        }
    }

    onSave = async () => {
        let canSave = true;
        let minValueError: string | undefined = undefined;
        let maxValueError: string | undefined = undefined;

        if (this.state.minValue === undefined) {
            canSave = false;
            minValueError = 'Invalid value. Please enter a value.'
        }

        if (this.state.maxValue === undefined) {
            canSave = false;
            maxValueError = 'Invalid value. Please enter a value.'
        }

        if (canSave) {
            this.setState({
                status: RequestStatus.IN_PROGRESS
            });
            
            await this.props.dataProvider.saveColorsToRoi(
                    this.props.roi.id, 
                    this.state.selectedParam, 
                    this.state.minValue, 
                    this.state.maxValue);
                    
            this.setState({
                selectedParam: '',
                status: RequestStatus.NOT_STARTED,
                minValue: undefined,
                maxValue: undefined,
             });
             this.props.onModalClose();
        }
        else {
            this.setState({
                minValueError,
                maxValueError
            })
        }
    }

    public render(): JSX.Element
    {      
        if(this.state.status == RequestStatus.SUCCESS){
            this.props.onModalClose();
            this.props.dataProvider.completeRoiUpdate();   
        }

        let paramBounds = this.state.selectedParam ? this.props.roi.parameterBounds[this.state.selectedParam] : undefined;

        return (
            <Modal show={this.props.visible}  backdrop="static">
                <Modal.Header >
                    <Modal.Title>Parameter Colour Scale</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.status == RequestStatus.IN_PROGRESS &&
                        <div style={{...row, alignItems: 'center'}}>
                            <Spinner animation="border" role="status" variant="primary"/>
                            <div style={{ marginLeft: defaultMargin}}>Update in progress ...</div>
                        </div>
                    }
                    {
                        this.state.status == RequestStatus.ERROR &&
                        <div style={{...row, alignItems: 'center'}}>
                            An error occurred while updating the ROI.
                        </div>
                    }
                    {
                        (this.state.status == RequestStatus.NOT_STARTED) &&
                        <div>
                            <div style={{marginTop: defaultMargin, marginBottom: defaultMargin * 2}}>Use this dialog to set the data range (min, max values) between which the selected parameter will be visible on the map. An automatic color scale will be used with blue representing the lowest values and red representing the highest.</div>
                            <div>Select a parameter: </div>
                                <Dropdown>
                                        <Dropdown.Toggle 
                                            className="shell-dropdown"
                                            id="dropdown-param-selection" 
                                            style={{ ...dropDown,  justifyContent: this.state.selectedParam ? 'space-between' : 'flex-end'}}
                                        >
                                            {this.state.selectedParam ? this.props.dataProvider.getParameterById(this.state.selectedParam).name : ''}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {
                                                this.props.params.map((param, i) => 
                                                <Dropdown.Item key={i} onSelect={() => {
                                                   
                                                    let wmsP = this.props.roi.wmsConfig !== undefined && this.props.roi.wmsConfig[param.paramId];
                                                    if(this.props.roi.wmsConfig !== undefined && this.props.roi.wmsConfig[param.paramId] !== undefined){
                                                        this.setState({selectedParam: param.paramId, minValue: wmsP.minValue, maxValue: wmsP.maxValue})
                                                    }else{
                                                        this.setState({selectedParam: param.paramId});
                                                    }                                                   
                                                }}>{this.props.dataProvider.getParameterById(param.paramId).name}</Dropdown.Item>
                                                    )
                                            }                                                           
                                        </Dropdown.Menu>
                                </Dropdown>
                                {
                                    this.state.selectedParam !== '' &&
                                    <div>
                                        <div style={{...row, alignItems: 'center', marginTop: defaultMargin, marginBottom: defaultMargin}} >
                                            <div style={{ width: 150}}>Min value:</div>
                                            <input 
                                                value={this.state.minValue} 
                                                type='number'  
                                                className="shell-input"
                                                style={{marginRight: defaultMargin * 2, width: '50%', color:'gray', fontWeight: 'bold'}}
                                                onChange={(evt) =>  this.setState({ minValue: floatOrUndefined(evt.target.value) }) }
                                            /> 
                                            <div>{this.props.dataProvider.getParameterById(this.state.selectedParam).unit}</div>                                                                  
                                        </div>                               
                                        <div style={{ color: 'red' }}>{this.state.minValueError}</div>   
                                        <div>Recommended min value:  {paramBounds ? paramBounds.minValue  : '-'}</div>        
                                        
                                        <div style={{...row, alignItems: 'center', marginTop: defaultMargin, marginBottom: defaultMargin}} >
                                            <div style={{ width: 150}}>Max value:</div>
                                            <input 
                                                value={this.state.maxValue} 
                                                type='number'  
                                                className="shell-input"
                                                style={{marginRight: defaultMargin * 2, width: '50%', color:'gray', fontWeight: 'bold'}}
                                                onChange={(evt) =>  this.setState({ maxValue: floatOrUndefined(evt.target.value) }) }
                                            /> 
                                            <div>{this.props.dataProvider.getParameterById(this.state.selectedParam).unit}</div>                                                                    
                                        </div>
                                        <div style={{ color: 'red' }}>{this.state.maxValueError}</div>   
                                        <div>Recommended max value:  {paramBounds ? paramBounds.maxValue  : '-'}</div>
                                        <div>
                                        </div>
                                    </div>
                                    
                                }
                        </div>
                    }   
                </Modal.Body>
                   <Modal.Footer>  
                        {
                            this.state.status !== RequestStatus.IN_PROGRESS &&
                            <Button className="shell-secondary-btn" onClick={() => {this.props.onModalClose();}}>Close</Button>
                        }                        
                        {
                            this.state.status !== RequestStatus.IN_PROGRESS && <Button 
                                className="shell-gray-btn" 
                                onClick={this.onSave.bind(this)}
                                disabled={this.state.selectedParam === '' || this.state.status !== RequestStatus.NOT_STARTED }
                            >
                                Save
                            </Button>
                        }
                    </Modal.Footer>                
            </Modal>     
        );
    }
}