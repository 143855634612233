
export const prettyPrintBytes = (bytes: number): { size: number, unit: string } => {
    let unitInt = 0;
    do {
        bytes = bytes / 1024;
        unitInt++;
    } while (bytes >= 1024 && unitInt < 4);

    let unit;
    switch (unitInt) {
        case 0: // we shouldnt get this
            unit = "bytes";
            break;
        case 1:
            unit = "kB";
            break;
        case 2:
            unit = "MB";
            break;
        case 3:
            unit = "GB";
            break;
        default:
            unit = "TB";
            break;

    }

    const roundedSizeString = bytes.toFixed(1);
    const roundedSizeNumber = parseFloat(roundedSizeString)

    return {
        size: roundedSizeNumber,
        unit
    };
}