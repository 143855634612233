import * as React from "react";
import { Roi, RoiAccessListStatus, RoiAccess } from "../../../../lambda/interfaces/rois";
import { row, defaultMargin, smallFontSize, mediumFontSize, defaultPadding } from "../../styles/styles";
import { Util } from "../../util/Util";
import { DataProvider } from "../../data/DataProvider";
import { Button, Card } from "react-bootstrap";
import { RevokAccessModal } from "../../components/modals/RevokeAccessModal";
import { ChangeAccessModal } from "../../components/modals/ChangeAccessModal";


export interface Props {
    roi: Roi;
    dataProvider: DataProvider;
    isAdmin: boolean;
}

export interface State {
   revokeAccessModalVisableAndUser?: string;
   changeAccessModalVisableAndUser?: string;
}

export default class PermissionsTab extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state = {
            revokeAccessModalVisableAndUser: undefined,
            changeAccessModalVisableAndUser: undefined
        }
    }

    canUpdate = Util.canUpdateAccess(this.props.roi.userAccess); // can remove / reject
    // admin can remove / reject / accept

    approvedUserList = this.props.roi.accessList.filter(x => x.accessStatus === RoiAccessListStatus.APPROVED);
    // approvedUserList: RoiAccess[] = [
    //     { user: '1', accessStatus: RoiAccessListStatus.APPROVED},
    //     { user: '2', accessStatus: RoiAccessListStatus.APPROVED},
    //     { user: '3', accessStatus: RoiAccessListStatus.APPROVED},
    //     { user: '4', accessStatus: RoiAccessListStatus.APPROVED},
    // ]
    pendingUserList = this.props.roi.accessList.filter(x => x.accessStatus === RoiAccessListStatus.PENDING);
    // pendingUserList: RoiAccess[] = [
    //     { user: '1', accessStatus: RoiAccessListStatus.PENDING},
    //     { user: '2', accessStatus: RoiAccessListStatus.PENDING},
    //     { user: '3', accessStatus: RoiAccessListStatus.PENDING},
    //     { user: '4', accessStatus: RoiAccessListStatus.PENDING},
    // ]

    public render(): JSX.Element
    {
        return (
            <div style={{...row, }}>   
                <div style={{flex: 2, maxWidth: 520}}>

                        {this.canUpdate && 
                            <Card style={{padding: defaultPadding}}>
                                <div style={{fontSize: mediumFontSize, fontWeight: 'bold',}}>Users with read access</div>
                                {
                                    (this.approvedUserList.length === 0) &&
                                        <div>No users with access permissions.</div>
                                }
                                {
                                    (this.props.roi.accessList.length !== 0) &&
                                        <table
                                            style={{
                                                border: '1px solid #a0a0a0'
                                            }}
                                        >
                                            {
                                                this.approvedUserList.map((x, i, arr) => {
                                                    return (
                                                        <tr 
                                                            key={x.user} 
                                                            style={{
                                                                display:'flex',
                                                                flexDirection:'row',
                                                                alignItems: 'stretch',
                                                                borderBottom: i !== arr.length - 1 && '1px solid #a0a0a0'
                                                            }}
                                                        >
                                                            <td 
                                                                style={{
                                                                    flexGrow: 1,
                                                                    alignItems: 'center',
                                                                    display: 'flex',
                                                                    flexDirection: 'row'
                                                                }}
                                                            >
                                                                <div>{x.user}</div>
                                                            </td>
                                                            <td>
                                                                <Button  className="shell-red-white-btn" style={{...row, width: 150, justifyContent: 'center', alignItems: 'center'}}
                                                                    onClick={() => this.setState({revokeAccessModalVisableAndUser: x.user})}
                                                                >  
                                                                    <div style={{fontSize: smallFontSize, marginLeft: defaultMargin}}>Revoke</div>
                                                                </Button> 
                                                            </td>
                                                        </tr> 
                                                    )
                                                })
                                            }
                                        </table>
                                }

                            </Card>
                        }
                        {   
                            this.canUpdate && 
                            <Card style={{padding: defaultPadding, marginTop: defaultMargin}}>
                                <div style={{fontSize: mediumFontSize, fontWeight: 'bold',}}>Users requesting access</div>
                                {
                                    (this.pendingUserList.length === 0) &&
                                        <div>No users requesting access permissions.</div>
                                }
                                {
                                    (this.pendingUserList.length !== 0) &&
                                        <table
                                            style={{
                                                border: '1px solid #a0a0a0'
                                            }}
                                        >
                                            {
                                                this.pendingUserList.map((x, i, arr) => {
                                                    return (
                                                        <tr 
                                                            key={x.user} 
                                                            style={{
                                                                display:'flex',
                                                                flexDirection:'row',
                                                                alignItems: 'stretch',
                                                                borderBottom: i !== arr.length - 1 && '1px solid #a0a0a0'
                                                            }}
                                                        >
                                                            <td 
                                                                style={{
                                                                    flexGrow: 1,
                                                                    alignItems: 'center',
                                                                    display: 'flex',
                                                                    flexDirection: 'row'
                                                                }}
                                                            >
                                                                <div>{x.user}</div>
                                                            </td>
                                                            <td>
                                                                <Button  className="shell-gray-btn" style={{...row, width: 150, justifyContent: 'center', alignItems: 'center'}}
                                                                    onClick={() => this.setState({changeAccessModalVisableAndUser: x.user})}
                                                                >  
                                                                    <div style={{fontSize: smallFontSize, marginLeft: defaultMargin}}>Change</div>
                                                                </Button> 
                                                            </td>
                                                        </tr> 
                                                    )
                                                })
                                            }
                                        </table>
                                }

                            </Card>
                        }
                </div>
                <div style={{flex: 1}}/>

                <RevokAccessModal
                    visible={this.state.revokeAccessModalVisableAndUser !== undefined} 
                    onModalClose={() => this.setState({revokeAccessModalVisableAndUser: undefined})} 
                    roi={this.props.roi} 
                    dataProvider={this.props.dataProvider}
                    user={this.state.revokeAccessModalVisableAndUser}
                />
                {(this.state.changeAccessModalVisableAndUser !== undefined) && <ChangeAccessModal
                    visible={true} 
                    onModalClose={() => this.setState({changeAccessModalVisableAndUser: undefined})} 
                    roi={this.props.roi} 
                    dataProvider={this.props.dataProvider}
                    user={this.state.changeAccessModalVisableAndUser}
                    canAccept={this.props.isAdmin}
                />}
            </div>
        );
    }
}