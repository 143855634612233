import * as React from "react";
import { Card } from 'react-bootstrap';
import { defaultPadding, tableCaption, row, defaultMargin, mediumFontSize, lightGray } from '../../styles/styles'
import { DataSource, Parameter } from "../../../../lambda/interfaces/sources";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DataSourceReference } from "../../../../lambda/interfaces/rois";
import { Util } from "../../util/Util";
import { DataProvider } from "../../data/DataProvider";


interface Props
{
    dataSourceReference: DataSourceReference;
    onParameterRemove: (paramId: string) => void;
    readonly: boolean;
    dataProvider: DataProvider;
    
}

interface State {
    ds: DataSource;
    
}

export  class SelectedDataSource extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);

        this.state = {
            ds: this.props.dataProvider.getDataSourceById(this.props.dataSourceReference.dataSourceId),
        }
    }

    public render(): JSX.Element
    {

        let params = [];
        this.props.dataSourceReference.parameterIds.map(id =>
            {
                const p: Parameter =   this.props.dataProvider.getParameterByIdAndSubsetId(id, this.props.dataSourceReference.subsetId);
                if(p !== undefined){
                    params.push(this.props.dataProvider.getParameterByIdAndSubsetId(id, this.props.dataSourceReference.subsetId));
                }              
            }  
            );
            if(this.state.ds === undefined){
                return (<Card>
                    The selected Data Source no longer exists
                </Card>);
            }else{
                let name;
                if(this.props.dataSourceReference.subsetId !== undefined){
                    name = `${this.state.ds.productName} (${this.props.dataSourceReference.subsetId})`;
                }else{
                    name = this.state.ds.productName
                }   
                let interval = "-";
                if(this.state.ds.temporalConstraint.temporalResolutions.length > 0){
                    if(this.state.ds.temporalConstraint.temporalResolutions.length === 1){
                        interval = this.state.ds.temporalConstraint.temporalResolutions[0].interval.toString();
                    }else{
                        interval = this.state.ds.temporalConstraint.temporalResolutions.find(x => x.subsetId === this.props.dataSourceReference.subsetId).interval.toString();
                    }
                }
               
                
                return (
                    <Card style={{padding: defaultPadding, marginBottom: defaultMargin}} >
                        <div style={{fontSize: mediumFontSize, fontWeight: 'bold',}}>{name}</div>
                        <div style={{...tableCaption}}>{"Data available: " +  Util.getTemporalConstraintLabel(this.state.ds.temporalConstraint)}</div>
                        <div style={{...tableCaption}}> {"Interval: " + interval}</div>
                        <div style={{...row, flexWrap: 'wrap'}}>
                        {
                            params.map((param, i) => {                      
                                return <Card key={param.id} style={{...row, alignItems: 'center', margin: defaultMargin / 2, padding: defaultPadding / 2, backgroundColor: lightGray}}>
                                        <div>{param.name}</div>
                                        {
                                            this.props.readonly == false &&
                                            <FontAwesomeIcon icon={faTimes} style={{fontSize: 15, marginLeft: defaultMargin}} onClick={() => this.props.onParameterRemove(param.id)} />
                                        }                             
                                    </Card> 
                            }                          
                                )
                        }
                        </div>               
                    </Card>
                );
            }

       
    }
}