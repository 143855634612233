import * as React from "react";
import { Redirect, Route } from 'react-router-dom'
import { DataProvider } from "../../data/DataProvider";
import RoiDetails from "../RoiDetails";

export interface Props {
    userLoggedIn: boolean;
    path: string;
    dataProvider?: DataProvider;
    isAdmin: boolean;
}

export default class PrivateRoute extends React.Component<Props, {}>
{

    public render(): JSX.Element
    {
        if(this.props.userLoggedIn == false){
            return <Redirect to="/login" />
        }else{
            if(this.props.path.startsWith('/roi/') && this.props.dataProvider !== undefined){
                return <Route path={this.props.path} render={({match}) => (<RoiDetails isAdmin={this.props.isAdmin}  roi={this.props.dataProvider.getRoiById(match.params.id)} dataProvider={this.props.dataProvider}/>)} />
            }else{
                return <Route path={this.props.path} >{this.props.children}</Route>
            }           
        }
    }
}