import * as React from "react";
import {  defaultMargin, row, shellDarkGray } from '../../styles/styles'
import { DataProvider, RequestStatus } from "../../data/DataProvider";
import { Roi } from "../../../../lambda/interfaces/rois";
import { Modal, Spinner, Button } from "react-bootstrap";

interface Props
{
    visible: boolean;
    dataProvider: DataProvider;
    roi: Roi;
    onModalClose: () => void;
}

export class RequestRoiAccessModal extends React.Component<Props, {}>
{
    constructor(props: Props)
    {
        super(props);
    }

    public render(): JSX.Element
    {
        if(this.props.dataProvider.getRequestRoiAccessStatus() == RequestStatus.SUCCESS){
            this.props.dataProvider.completeRequestRoiAccessAction();
            this.props.onModalClose();
        }

        const title = "Request access to ROI";
        const actionDescription = `This action will request access to ROI ${this.props.roi.id} (${this.props.roi.name}). An administrator will approve or decline your request.`;
      

        return (
            <Modal show={this.props.visible}  backdrop="static">
                <Modal.Header >
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {
                            this.props.dataProvider.getRequestRoiAccessStatus() == RequestStatus.NOT_STARTED &&
                            <div>
                                <p>{actionDescription}</p>
                                <p>Are you sure you want to request access to this ROI?</p>
                            </div>                               
                        }   
                        {
                            this.props.dataProvider.getRequestRoiAccessStatus() == RequestStatus.ERROR &&
                                <p>There was an error requesting access.</p>
                        }                    
                        {
                            this.props.dataProvider.getRequestRoiAccessStatus() == RequestStatus.IN_PROGRESS &&
                            <div style={{...row, alignItems: 'center'}}>
                                 <Spinner animation="border" role="status" color={shellDarkGray}/>
                                <div style={{ marginLeft: defaultMargin}}>ROI access request is in progress ...</div>
                            </div>
                        }
                </Modal.Body>
                   <Modal.Footer>
                       {
                           (this.props.dataProvider.getRequestRoiAccessStatus() == RequestStatus.NOT_STARTED || 
                           this.props.dataProvider.getRequestRoiAccessStatus() == RequestStatus.ERROR) &&
                            <Button className="shell-secondary-btn" onClick={() => {
                                this.props.onModalClose();
                                this.props.dataProvider.completeRequestRoiAccessAction();
                            }}>Close</Button>
                       }
                        {
                            this.props.dataProvider.getRequestRoiAccessStatus() == RequestStatus.NOT_STARTED &&
                            <Button className="shell-gray-btn" onClick={() => {                                            
                                this.props.dataProvider.requestRoiAccess(this.props.roi.id);                    
                             }}>Request</Button> 
                        }
                    </Modal.Footer>                
            </Modal>     
        );
    }
}


