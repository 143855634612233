import * as React from "react";
import { Navbar, Nav, NavDropdown, Form, Button } from 'react-bootstrap';
import { lightBlue, lightGreen, lightOrange, lightRed, gray, defaultPadding, mediumFontSize, defaultMargin, mainBlue, box, purple, lightGray } from '../styles/styles'

interface Props
{
    status: BoxStatus;
    label: string;
}

export enum BoxStatus {
    INFO,
    WARNING,
    ERROR,
    SUCCESS,
    ONGOING,
    SUSPENDED
}

export class StatusBox extends React.Component<Props, {}>
{
    constructor(props: Props)
    {
        super(props);
    }

    getColor = () => {
        switch(this.props.status){
            case BoxStatus.ERROR:
                return lightRed;
            case BoxStatus.SUSPENDED:
                return gray;
            case BoxStatus.INFO:
                return lightBlue;
            case BoxStatus.SUCCESS:
                return lightGreen;
            case BoxStatus.WARNING:
                return lightOrange;
            case BoxStatus.ONGOING:
                return purple;
            default:
        }
    }

    public render(): JSX.Element
    {
        return (
            <div style={{backgroundColor: this.getColor(), ...box, maxWidth: 300} }>
                <div>{this.props.label}</div>
            </div>
        );
    }
}

