import { Facing, SpatialConstraint } from "./rois";

export interface DataSource {
    id: string;
    productName: string;
    type: DataSourceType;
    organization: string;
    intersection: IntersectionType;
    referenceURL: string;
    temporalConstraint: TemporalConstraint;
    license: string;
    parameters: Parameter[];
    spatialConstraint: SpatialConstraint;
    
    maximumExpectedLagTimeMinutes: number;
}

export interface TemporalConstraint {
    dataCollectionDateType: DataCollectionDateType;
    firstDate: Date; 
    lastDate: Date; // the Date of creation if Ongoing
    temporalResolutions: TemporalResolution[];
    dynamicFirstDateOffsetInDays?: number; 
}

export interface RoiTemporalConstraint {
    dataCollectionDateType: DataCollectionDateType;
    firstDate: Date; 
    lastDate: Date; // the Date of creation if Ongoing
    interval: TemporalInterval;
    disabled: boolean;
}

export interface TemporalResolution{
    subsetId?: string;
    interval: TemporalInterval;
}

export type TemporalInterval = 1 | 2 | 3 | 4 | 6 | 8 | 12 | 24; // divisors of 24(hours)

export interface Parameter {
    id: string;
    subsetId?: string;
    name: string;
    unit: string;
}

export enum DataSourceType {
    FORECAST,
    HINDCAST,
    REANALYSIS,
    SATELLITE
}

export enum IntersectionType {
    FULL,
    PARTIAL,
    NONE
}

export enum DataCollectionDateType {
    ONGOING,
    FIXED 
}
export const validateRoiTemporalConstraint = (ob: RoiTemporalConstraint): void => {
    const {
        dataCollectionDateType, //: DataCollectionDateType;
        firstDate, //: Date; 
        lastDate, //: Date; // the Date of creation if Ongoing
        interval, //: number;
        disabled, //:boolean
    } = ob;

    // enums:
    validateDataCollectionDateType(dataCollectionDateType);
    if(!disabled){
        // numbers:
        if (isNaN(interval)) throw new Error(`Invalid interval in TemporalConstraint ${interval}`);
        switch (interval) {
            case 1:
            case 12:
            case 2:
            case 24:
            case 3:
            case 4:
            case 6:
            case 8:
                break;
            default:
                throw new Error(`Invalid interval in TemporalConstraint ${interval}`);
        }
    }

    // dates:
    if (isNaN(firstDate.valueOf())) throw new Error("Invalid firstDate in TemporalConstraint");
    if (dataCollectionDateType !== DataCollectionDateType.ONGOING) {
        if (isNaN(lastDate.valueOf())) throw new Error("Invalid lastDate in TemporalConstraint");
    }
}

// enums:
export const validateDataCollectionDateType = (dataCollectionDateType: DataCollectionDateType): void => {
    if (!(dataCollectionDateType in DataCollectionDateType)) throw new Error("Invalid DataCollectionDateType");
}