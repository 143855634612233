import * as React from "react";
import { Navbar, Nav, NavDropdown, Form, Button, Card } from 'react-bootstrap';
import { lightBlue, lightGreen, lightOrange, lightRed, gray, defaultPadding, mediumFontSize, defaultMargin, mainBlue, row, lightGray, smallBold, smallFontSize } from '../styles/styles'
import { Util } from "../util/Util";

interface Props
{
    minX: number;
    maxX: number;
    minY: number;
    maxY: number;
}


export class RegionBox extends React.Component<Props, {}>
{
    constructor(props: Props)
    {
        super(props);
    }

    public render(): JSX.Element
    {
        return (
            <Card style={{ paddingLeft: defaultPadding, paddingRight: defaultPadding, backgroundColor: lightGray, marginLeft: defaultMargin}}>
                <div style={{...row, alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{fontSize: smallFontSize}}>SW Corner:</div>
                    <div style={{...row}}>
                        <div style={{...smallBold}}>{Util.formatNumber(this.props.minX)},</div>
                        <div style={{ ...smallBold}}>{Util.formatNumber(this.props.minY)}</div>
                    </div>                 
                </div>              
                <div style={{...row, alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{fontSize: smallFontSize}}>NE Corner:</div>
                    <div style={{...row}}>
                        <div style={{...smallBold}}>{Util.formatNumber(this.props.maxX)},</div>
                        <div style={{ ...smallBold}}>{Util.formatNumber(this.props.maxY)}</div>
                    </div>                   
                </div>
            </Card>
        );
    }
}