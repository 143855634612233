import * as React from "react";
import {  defaultMargin, row } from '../../styles/styles'
import { DataProvider, RequestStatus } from "../../data/DataProvider";
import { Util } from "../../util/Util";
import { RoiPreview } from "../../screens/NewRoi";
import { Roi } from "../../../../lambda/interfaces/rois";
import { Modal, Spinner, Button } from "react-bootstrap";
import { Redirect} from 'react-router-dom'

interface Props
{
    visible: boolean;
    dataProvider: DataProvider;
    roiToDelete: Roi;
    onModalClose: () => void;
}

export class DeleteRoiModal extends React.Component<Props, {}>
{
    constructor(props: Props)
    {
        super(props);
    }

    public render(): JSX.Element
    {
        if(this.props.dataProvider.isRoiDeleted() == RequestStatus.SUCCESS){
            this.props.dataProvider.completeRoiDeletion();
            return <Redirect to='/' />;
        }
      
        return (
            <Modal show={this.props.visible}  backdrop="static">
                <Modal.Header >
                    <Modal.Title>Delete ROI</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {
                            this.props.dataProvider.isRoiDeleted() == RequestStatus.NOT_STARTED &&
                                <p>{'Are you sure you want to delete the ROI named: ' + this.props.roiToDelete.name }</p>
                        }   
                        {
                            this.props.dataProvider.isRoiDeleted() == RequestStatus.ERROR &&
                                <p>There was an error while deleting your ROI.</p>
                        }                    
                        {
                            this.props.dataProvider.isRoiDeleted() == RequestStatus.IN_PROGRESS &&
                            <div style={{...row, alignItems: 'center'}}>
                                 <Spinner animation="border" role="status" variant="primary"/>
                                <div style={{ marginLeft: defaultMargin}}>Deletion in progress ...</div>
                            </div>
                        }
                </Modal.Body>
                   <Modal.Footer>
                       {
                           (this.props.dataProvider.isRoiDeleted() == RequestStatus.NOT_STARTED || 
                           this.props.dataProvider.isRoiDeleted() == RequestStatus.ERROR) &&
                            <Button className="shell-secondary-btn" onClick={() => {
                                this.props.onModalClose();
                                this.props.dataProvider.completeRoiDeletion();
                            }}>Close</Button>
                       }
                        {
                            this.props.dataProvider.isRoiDeleted() == RequestStatus.NOT_STARTED &&
                            <Button className="shell-gray-btn" onClick={() => {                                            
                                this.props.dataProvider.deleteRoi(this.props.roiToDelete.id);                    
                             }}>Delete</Button> 
                        }
                    </Modal.Footer>                
            </Modal>     
        );
    }
}


