import * as React from "react";
import DatePicker  from 'react-datepicker';
import { DataCollectionDateType } from "../../../../lambda/interfaces/sources";
import { Form } from "react-bootstrap";
import { defaultMargin, row } from "../../styles/styles";

export interface Props {
    firstDate: Date;
    lastDate: Date;
    dataCollectionDateType: DataCollectionDateType;
    interval: number;
    disabled: boolean;
    onTemporalDataChange: (dataCollectionDateType: DataCollectionDateType, firstDate: Date, lastDate: Date, interval: number, disabled: boolean) => any;
}

export interface State {
}

export class TemporalConstraintFirstLastDateSelector extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state = {
        }
    }

    public render(): JSX.Element
    {
        return <>
            <div style={{marginRight: defaultMargin * 2}}>
                <div style={{marginBottom: 30, fontWeight: 'bold'}}>First date</div>
                <DatePicker  
                    maxDate={this.props.dataCollectionDateType === DataCollectionDateType.ONGOING ? null : this.props.lastDate}      
                    selected={this.props.firstDate}                                     
                    onChange={(date)=> {
                        let realDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
                        this.props.onTemporalDataChange(this.props.dataCollectionDateType, realDate, this.props.lastDate, this.props.interval, this.props.disabled);
                    }}   
                    dateFormat={"yyyy-MM-dd"}          
                />
            </div>
            <div style={{marginRight: defaultMargin * 2}}>
                <div style={{fontWeight: 'bold'}}>Last date</div>
                <div style={{...row, height: 30}}>
                    <Form.Check 
                        type="radio" 
                        checked={this.props.dataCollectionDateType == DataCollectionDateType.FIXED}
                        onChange={() => this.props.onTemporalDataChange(DataCollectionDateType.FIXED, this.props.firstDate, this.props.lastDate, this.props.interval, this.props.disabled)}
                    />
                    <Form.Check.Label style={{marginRight: defaultMargin * 2}}>Date</Form.Check.Label>
                    <Form.Check 
                        type="radio" 
                        checked={this.props.dataCollectionDateType == DataCollectionDateType.ONGOING} 
                        onChange={() => this.props.onTemporalDataChange(DataCollectionDateType.ONGOING, this.props.firstDate, this.props.lastDate, this.props.interval, this.props.disabled)}
                    />
                    <Form.Check.Label>Ongoing</Form.Check.Label>
                </div>
                <DatePicker
                    dateFormat={"yyyy-MM-dd"}        
                    minDate={this.props.firstDate} 
                    selected={this.props.lastDate} 
                    disabled={this.props.dataCollectionDateType == DataCollectionDateType.ONGOING}
                    onChange={(date)=> {
                        let realDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
                        this.props.onTemporalDataChange(this.props.dataCollectionDateType, this.props.firstDate, realDate, this.props.interval, this.props.disabled);
                    }}
                />
            </div>
        </>
    }
}