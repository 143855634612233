import * as React from "react";
import { Navbar, Nav, NavDropdown, Form, Button } from 'react-bootstrap';
import { row, defaultMargin, lightGray, mediumFontSize, shellRed, shellDarkGray } from "../../styles/styles";
import { ServerStatus } from "../../data/DataProvider";

interface Props
{
   serverStatus: ServerStatus; 
}


export class ServerStatusIndicator extends React.Component<Props, {}>
{
    constructor(props: Props)
    {
        super(props);
    }


    public render(): JSX.Element
    {
        return (
            <div style={{...row, height: '95%', alignItems: 'center', marginLeft: defaultMargin, marginRight: defaultMargin * 2, padding: defaultMargin, marginTop: defaultMargin}} className="box-border">
                <div style={{marginRight: defaultMargin, fontWeight: 'bold', fontSize: mediumFontSize, color: shellRed}}>Server status</div>
                <table className="status-table">
                    <tbody>
                        <tr>
                            <th></th>
                            <th style={{color: shellDarkGray}}>All</th>
                            <th style={{color: shellDarkGray}}>Mine</th>
                        </tr>
                        <tr >
                            <td style={{color: shellDarkGray, fontWeight: 'bold', paddingRight: defaultMargin}}>Jobs Queued</td>
                            <td style={{width: 100}}>{this.props.serverStatus.all.queued}</td>
                            <td style={{width: 100}}>{this.props.serverStatus.user.queued} (waiting for {this.props.serverStatus.user.jobsInfront})</td>
                        </tr>
                        <tr>
                            <td style={{color: shellDarkGray, fontWeight: 'bold', paddingRight: defaultMargin}}>Jobs running</td>
                            <td >{this.props.serverStatus.all.running}</td>
                            <td >{this.props.serverStatus.user.running}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

