import * as React from "react";
import {  defaultMargin, row } from '../../styles/styles'
import { DataProvider, RequestStatus } from "../../data/DataProvider";
import { Util } from "../../util/Util";
import { RoiPreview } from "../../screens/NewRoi";
import { Roi, SpatialConstraint } from "../../../../lambda/interfaces/rois";
import { Modal, Spinner, Button } from "react-bootstrap";
import { Redirect} from 'react-router-dom'
import { IntersectionMap } from "../maps/IntersectionMap";
import { DataSource, IntersectionType } from "../../../../lambda/interfaces/sources";

import {mainBlue, shellRed} from "../../styles/styles";
import { SimpleRoiMap } from "../maps/SimpleRoiMap";

interface Props
{
    visible: boolean;
    onModalClose: () => void;
    roiSpatialConst: SpatialConstraint;
}

export class RoiMapModal extends React.Component<Props, {}>
{
    constructor(props: Props)
    {
        super(props);
    }

    public render(): JSX.Element
    {

      
        return (
            <Modal show={this.props.visible}  backdrop="static" dialogClassName="roi-map-modal-dialog">
                <Modal.Header >
                <Modal.Title>ROI Boundary</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{width: "100%", height: 400, marginTop: defaultMargin * 2 }}>
                        <SimpleRoiMap modalVisible={this.props.visible} roiSpatialConst={this.props.roiSpatialConst}/>
                    </div>                        
                </Modal.Body>
                   <Modal.Footer>
                    <Button className="shell-secondary-btn" onClick={() => {
                        this.props.onModalClose(); 
                    }}>Close</Button>                       
                    </Modal.Footer>                
            </Modal>     
        );
    }
}


