import { DataCollectionDateType, DataSource, Parameter } from "../../../lambda/interfaces/sources";
import { Roi, PostRoi, DatasetRecord, RoiApprovalStatus, DatasetVersion, PostRoiApprovalStatus, DataCollectionLogResp } from "../../../lambda/interfaces/rois";
import { ServerJobQueueStatus, ServerJobQueueStatusUser} from "../../../lambda/interfaces/serverStatus";
import PatchRoiResponse from "../../../lambda/api/rois/id/verb_patch/response";

export const enum RequestStatus {
    NOT_STARTED,
    IN_PROGRESS,
    SUCCESS,
    ERROR,
}

export interface ServerStatus {
    all: ServerJobQueueStatus, 
    user: ServerJobQueueStatusUser
}

export const DEGREE_TO_KM = 111320;

let API_BASE_URL: string;
if (window.location.host.indexOf("shellmetnetspatial") !== -1) {
    API_BASE_URL = 'https://api.shellmetnetspatial.com/';
} else {
    API_BASE_URL = 'https://api.shellmetnetspatial.com/'; 
}

export class DataProvider {
    private  componentToReRender: React.Component | undefined;
    private dataSources: DataSource[] = [];
    private parameters: Parameter[] = [];
    private rois: Roi[] = [];
    private serverStatus: ServerStatus = null;

    //this is currently just used for mapping
    private dataSetMap: {roiId: string, dataSets: DatasetVersion[]}[] = [];

    private roisFetchStatus: RequestStatus = RequestStatus.NOT_STARTED;
    private dataSourcesFetchStatus: RequestStatus = RequestStatus.NOT_STARTED;
    private roiUploadStatus: RequestStatus = RequestStatus.NOT_STARTED;
    private roiDeleteStatus: RequestStatus = RequestStatus.NOT_STARTED;
    private createMapFileStatus: RequestStatus = RequestStatus.NOT_STARTED;
    private updateRoiStatus: RequestStatus = RequestStatus.NOT_STARTED;
    private dataSetFetchStatus: RequestStatus = RequestStatus.NOT_STARTED;
    private serverStatusFetch: RequestStatus = RequestStatus.NOT_STARTED;
    private requestRoiAccessStatus: RequestStatus = RequestStatus.NOT_STARTED;
    private modifyRoiAccessStatus: RequestStatus = RequestStatus.NOT_STARTED;

    //https://api.shellmetnetspatial.com/
    //https://api.shellmetnetspatial.com/

     constructor(componentToRerender: React.Component){
        this.componentToReRender = componentToRerender;
        this.fetchRois();
        this.fetchDatSources();
        this.fetchServerStatus();
        this.fetchRoisPeriodically();
        this.fetchServerStatusPeriodically();
    }

    fetchServerStatusPeriodically() {     
        let dataProvider = this;
        setInterval(function (){
            dataProvider.fetchServerStatus();
        }, 1000 * 60 * 5);
         //get ROIs every 5 minutes without showing refresh status on UI
    }

    fetchServerStatus(){
        var getRequest = new XMLHttpRequest();
        this.serverStatusFetch = RequestStatus.IN_PROGRESS; 
        this.updateMainComponent();      
        getRequest.addEventListener('load', () => {  
            console.log(JSON.parse(getRequest.responseText));
            this.serverStatus = JSON.parse(getRequest.responseText);    
            this.serverStatusFetch =  RequestStatus.SUCCESS;  
            this.updateMainComponent();
        })
        getRequest.addEventListener('error', () => {     
            this.serverStatusFetch = RequestStatus.ERROR;  
            this.updateMainComponent();
        });
        getRequest.addEventListener('abort', () => {     
            this.serverStatusFetch = RequestStatus.ERROR;  
            this.updateMainComponent();
        });
        getRequest.open('GET',  API_BASE_URL + 'server');
        getRequest.withCredentials = true;
        getRequest.send(); 
    }

    //it would be nice to replace all of these XMLHttpRequests with fetch as it is much easier to use!
    async fetchDataIssuesForRoi(roiId: string, firstDate: number, lastDate: number){
        const resp = await fetch(API_BASE_URL + 'rois/' + roiId + '/data/issues?firstDate=' + firstDate + "&lastDate=" + lastDate, {
            method: 'GET',
            credentials: 'include'
        });

        if (!resp.ok) {
            this.dataSetFetchStatus = RequestStatus.ERROR;  
            throw new Error("Non OK response");
        }
    
        return await resp.json();
    }

    async fetchDataParamsForRoi(roiId: string, timestamp: number){
        const resp = await fetch(API_BASE_URL + 'rois/' + roiId + '/data/issues/' + timestamp + '/parameters', {
            method: 'GET',
            credentials: 'include'
        });

        if (!resp.ok) {
            this.dataSetFetchStatus = RequestStatus.ERROR;  
            throw new Error("Non OK response");
        }
    
        return await resp.json();
    }

    async fetchDataSetsForRoi(roiId: string, timestamp: number, paramId: string){
        const resp = await fetch(API_BASE_URL + 'rois/' + roiId + '/data/issues/' + timestamp + '/parameters/' + paramId + "/records", {
            method: 'GET',
            credentials: 'include'
        });

        if (!resp.ok) {
            this.dataSetFetchStatus = RequestStatus.ERROR;  
            throw new Error("Non OK response");
        }
    
        return await resp.json();
    }

    fetchDatSources() {
        var getRequest = new XMLHttpRequest();
        this.dataSourcesFetchStatus = RequestStatus.IN_PROGRESS;
        getRequest.addEventListener('load', () => {  
            this.dataSources =  JSON.parse(getRequest.responseText)['dataSources'];
            this.dataSources.map(ds => {
                ds.parameters.map(param => {
                    this.parameters.push(param);
                });
            });     
            this.dataSourcesFetchStatus =  RequestStatus.SUCCESS;  
            if(this.roisFetchStatus == RequestStatus.SUCCESS){
                this.updateMainComponent();
            }                
        })
        getRequest.addEventListener('error', () => {     
            this.dataSourcesFetchStatus = RequestStatus.ERROR;  
            this.updateMainComponent();
        });
        getRequest.addEventListener('abort', () => {     
            this.dataSourcesFetchStatus = RequestStatus.ERROR;  
            this.updateMainComponent();
        });
        getRequest.open('GET',  API_BASE_URL + 'sources');
        getRequest.withCredentials = true;
        getRequest.send(); 
    }

    fetchRois() {
        var getRequest = new XMLHttpRequest();
        this.roisFetchStatus = RequestStatus.IN_PROGRESS;  
        this.updateMainComponent();
        getRequest.addEventListener('load', () => {    
            let rois = JSON.parse(getRequest.responseText)['rois'] as Roi[]; 
            rois.forEach((roi) => {
                //this is nasty but we have assumed everywhere that these actually do come back as dates when in 
                //fact they come back as strings! have to convert here.
                roi.temporalConstraint.firstDate = new Date(roi.temporalConstraint.firstDate);
                roi.temporalConstraint.lastDate = new Date(roi.temporalConstraint.lastDate);
            });

            this.rois = rois;

            this.roisFetchStatus = RequestStatus.SUCCESS;  
            if(this.dataSourcesFetchStatus == RequestStatus.SUCCESS){
                this.updateMainComponent();
            }
        });
        getRequest.addEventListener('error', () => {    
            this.roisFetchStatus = RequestStatus.ERROR; 
            this.updateMainComponent();
        });
        getRequest.addEventListener('abort', () => {    
            this.roisFetchStatus = RequestStatus.ERROR;  
            this.updateMainComponent();
        });
        getRequest.open('GET', API_BASE_URL + 'rois');
        getRequest.withCredentials = true;
        getRequest.send();
    }

    fetchRoisPeriodically() {     
        let dataProvider = this;
        setInterval(function (){
            var getRequest = new XMLHttpRequest(); 
            getRequest.addEventListener('load', () => {       
                let rois = JSON.parse(getRequest.responseText)['rois'] as Roi[]; 
                rois.forEach((roi) => {
                    //this is nasty but we have assumed everywhere that these actually do come back as dates when in 
                    //fact they come back as strings! have to convert here.
                    roi.temporalConstraint.firstDate = new Date(roi.temporalConstraint.firstDate);
                    roi.temporalConstraint.lastDate = new Date(roi.temporalConstraint.lastDate);
                });

                dataProvider.rois = rois;   
                dataProvider.updateMainComponent();       
            });
            getRequest.open('GET', API_BASE_URL + 'rois');
            getRequest.withCredentials = true;
            getRequest.send();
        }, 1000 * 60 * 5);
         //get ROIs every 5 minutes without showing refresh status on UI
    }

    async fetchLogs(roiId: string, timestamp: number, limit: number, nextToken?: string) {
        let url = API_BASE_URL + 'rois/' + roiId + '/logs?timestamp=' + timestamp + '&limit=' + limit;

        if (nextToken)
        {
            url += "&nextToken=" + nextToken;
        }

        const resp = await fetch(url, {
            method: 'GET',
            credentials: 'include'
        });

        if (!resp.ok) {
            this.dataSetFetchStatus = RequestStatus.ERROR;  
            throw new Error("Non OK response");
        }
    
        this.dataSetFetchStatus = RequestStatus.SUCCESS;
        return await resp.json() as DataCollectionLogResp;
    }

    doesRoiHaveUnrecognizedDataSource(roiId: string): boolean{
        let unrecognizedDs = false;
        this.getRoiById(roiId).dataSources.map(ds => {
            if(this.dataSources.findIndex(x => x.id === ds.dataSourceId) === -1){
                unrecognizedDs = true;
            }
        });
        return unrecognizedDs;
    }

    submitRoi(roi: PostRoi){
       var postRequest = new XMLHttpRequest();
        this.roiUploadStatus = RequestStatus.IN_PROGRESS;
        this.updateMainComponent();
        postRequest.addEventListener('load', () => {      
                this.roiUploadStatus = RequestStatus.SUCCESS;
                this.roisFetchStatus = RequestStatus.NOT_STARTED;  
                this.updateMainComponent();
                this.fetchRois();                     
        });
        postRequest.addEventListener('error', () => {     
            this.roiUploadStatus = RequestStatus.ERROR;  
            this.updateMainComponent();
        });
        postRequest.addEventListener('abort', () => {     
            this.roiUploadStatus = RequestStatus.ERROR;  
            this.updateMainComponent();
        });
        postRequest.open('POST', API_BASE_URL + 'rois');
        postRequest.withCredentials = true;
        let objToSend = {roi : roi};
        postRequest.send(JSON.stringify(objToSend));
    }

    async saveColorsToRoi(roiId: string, paramId: string, minValue: number, maxValue: number){
        let roiToUpdate: Roi = this.rois.find(r => r.id == roiId);
        //local update
        if(roiToUpdate.wmsConfig === undefined){
            roiToUpdate.wmsConfig = {[paramId] : {
                    minValue : minValue,
                    maxValue : maxValue
            }}
        }else{
            roiToUpdate.wmsConfig[paramId] = {
                minValue : minValue,
                maxValue : maxValue
            }
        }    

        const resp = await fetch(API_BASE_URL + 'rois/' + roiId, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({paramId: paramId, minValue: minValue, maxValue: maxValue })
        })

        if (!resp.ok) {
            throw new Error("Non OK response");
        }
    
        return await resp.json();
    }

    deleteRoi(roiId: string){
        var deleteRequest = new XMLHttpRequest();
        this.roiDeleteStatus = RequestStatus.IN_PROGRESS;
        this.updateMainComponent();
        deleteRequest.addEventListener('load', () => { 
                this.roiDeleteStatus = RequestStatus.SUCCESS;
                this.updateMainComponent();
                this.fetchRois();               
        });
        deleteRequest.addEventListener('error', () => {     
            this.roiDeleteStatus = RequestStatus.ERROR;  
            this.updateMainComponent();
        });
        deleteRequest.addEventListener('abort', () => {     
            this.roiDeleteStatus = RequestStatus.ERROR;  
            this.updateMainComponent();
        });
        deleteRequest.open('DELETE', API_BASE_URL + 'rois/' + roiId);
        deleteRequest.withCredentials = true;
        deleteRequest.send();
    }

    async modifyRoiApprovalStatus(roiId: string, approvalStatus: PostRoiApprovalStatus){
        const resp = await fetch(API_BASE_URL + 'rois/' + roiId + "/approve", {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({approvalStatus : approvalStatus})
        });

        if (!resp.ok) {
            throw new Error("Non OK response");
        }

        //update our copy of the roi so that we don't have to download it all again just to display this flag
        let roi = this.rois.find(r => r.id == roiId);
        roi.approvalStatus = approvalStatus == PostRoiApprovalStatus.APPROVED ? RoiApprovalStatus.APPROVED : RoiApprovalStatus.DECLINED;    
        return await resp.text();
    }

    requestRoiAccess(roid: string){
        var postRequest = new XMLHttpRequest();
        this.requestRoiAccessStatus = RequestStatus.IN_PROGRESS;
        this.updateMainComponent();
        postRequest.addEventListener('load', () => {      
                this.requestRoiAccessStatus = RequestStatus.SUCCESS;
                this.roisFetchStatus = RequestStatus.NOT_STARTED;  
                this.fetchRois();                     
        });
        postRequest.addEventListener('error', () => {     
            this.requestRoiAccessStatus = RequestStatus.ERROR;  
            this.updateMainComponent();
        });
        postRequest.addEventListener('abort', () => {     
            this.requestRoiAccessStatus = RequestStatus.ERROR;  
            this.updateMainComponent();
        });
        postRequest.open('POST', API_BASE_URL + 'rois/' + roid + '/access');
        postRequest.withCredentials = true;
        let objToSend = {requestAccess : true};
        postRequest.send(JSON.stringify(objToSend));
    }

    modifyRoiAccess(roid: string, user: string, allowRead: boolean) {
        var postRequest = new XMLHttpRequest();
        this.modifyRoiAccessStatus = RequestStatus.IN_PROGRESS;
        this.updateMainComponent();
        postRequest.addEventListener('load', () => {      
                this.modifyRoiAccessStatus = RequestStatus.SUCCESS;
                this.roisFetchStatus = RequestStatus.NOT_STARTED;  
                this.fetchRois();                     
        });
        postRequest.addEventListener('error', () => {     
            this.modifyRoiAccessStatus = RequestStatus.ERROR;  
            this.updateMainComponent();
        });
        postRequest.addEventListener('abort', () => {     
            this.modifyRoiAccessStatus = RequestStatus.ERROR;  
            this.updateMainComponent();
        });
        postRequest.open('POST', API_BASE_URL + 'rois/' + roid + '/access');
        postRequest.withCredentials = true;
        let objToSend = {
            modifyAccess : {
                user,
                allowRead
            }
        };
        postRequest.send(JSON.stringify(objToSend));
    }

    async stopRoiDataCollection(roiId: string){
        const resp = await fetch(API_BASE_URL + 'rois/' + roiId, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({stopRoi : true})
        })

        if (!resp.ok) {
            throw new Error("Non OK response");
        }

        let json = await resp.json() as PatchRoiResponse;

        //update locally so that we don't need to re-download the ROI
        let roiToUpdate: Roi = this.rois.find(r => r.id == roiId);
        roiToUpdate.isStopped = true;
        roiToUpdate.hasBeenUpdated = json.update;
    
        return json;
    }

    async pauseResumeDataCollection(roiId: string, pause: boolean){
        const resp = await fetch(API_BASE_URL + 'rois/' + roiId, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(pause? {suspendRoi : true}: {resumeRoi : true})
        })

        if (!resp.ok) {
            throw new Error("Non OK response");
        }

        let json = await resp.json() as PatchRoiResponse;

        //update locally so that we don't need to re-download the ROI
        let roiToUpdate: Roi = this.rois.find(r => r.id == roiId);
        roiToUpdate.isSuspended = pause;
        roiToUpdate.hasBeenUpdated = json.update;

        return json;
    }

    async editRoiTemporalConstraints(
        roiId: string,
        dataCollectionDateType: DataCollectionDateType,
        firstDate: Date, 
        lastDate: Date)
    {
        const resp = await fetch(API_BASE_URL + 'rois/' + roiId, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({temporalConstraints:{dataCollectionDateType: dataCollectionDateType, firstDate: firstDate, lastDate: lastDate}})
        })

        if (!resp.ok) {
            throw new Error("Non OK response");
        }

        //update locally so that we don't need to re-download the ROI
        let roiToUpdate: Roi = this.rois.find(r => r.id == roiId);
        roiToUpdate.temporalConstraint.firstDate = firstDate;
        roiToUpdate.temporalConstraint.lastDate = lastDate;
        roiToUpdate.temporalConstraint.dataCollectionDateType = dataCollectionDateType;
    
        return await resp.json();
    }

    createOrUpdateMapFile(dsr: DatasetRecord, roid: string, issueTimestamp: number, param: string, dataTimestamp: number ){
        var createMapRequest = new XMLHttpRequest();
        this.createMapFileStatus = RequestStatus.IN_PROGRESS;
        this.updateMainComponent();
        createMapRequest.addEventListener('load', () => {  
                const path =  JSON.parse(createMapRequest.responseText)['mapFilePath'];
                dsr.mapFilePath = path;
                this.createMapFileStatus = RequestStatus.SUCCESS;
                this.updateMainComponent();              
        });
        createMapRequest.addEventListener('error', () => {   
            this.createMapFileStatus = RequestStatus.ERROR;  
            this.updateMainComponent();
        });
        createMapRequest.addEventListener('abort', () => {  
            this.createMapFileStatus = RequestStatus.ERROR;  
            this.updateMainComponent();
        });          
        createMapRequest.open('POST', API_BASE_URL + 'rois/' + roid + '/data/map/' + issueTimestamp + '/' + param + '/' + dataTimestamp);
        createMapRequest.withCredentials = true;
        createMapRequest.send();  
    }

    downloadLogs(roiid: string, timestamp: number){
        window.location.href = API_BASE_URL + 'rois/' + roiid + '/logs-download?timestamp=' + timestamp;
    }

    updateMainComponent(){
        if(this.componentToReRender){
            this.componentToReRender.setState({dataProvider: this});
        }
    }

    isRoiUploaded(): RequestStatus{
        return this.roiUploadStatus;
    }

    isRoiDeleted(): RequestStatus{
        return this.roiDeleteStatus;
    }

    isRoiUpdated(): RequestStatus{
        return this.updateRoiStatus;
    }

    isMapFileCreated(): RequestStatus {
        return this.createMapFileStatus;
    }

    getDataSources(): DataSource[]{
        return this.dataSources;
    }

    getDataSourceById(id: string): DataSource {
        return this.dataSources.find(ds => ds.id == id);
    }

    getServerStatus(): ServerStatus | null {
        return this.serverStatus;
    }

    getRequestRoiAccessStatus(): RequestStatus {
        return this.requestRoiAccessStatus;
    }

    getModifyRoiAccessStatus(): RequestStatus {
        return this.modifyRoiAccessStatus;
    }
    
    completeRequestRoiAccessAction(){
        this.requestRoiAccessStatus = RequestStatus.NOT_STARTED;
    }
    
    completeModifyRoiAccessAction(){
        this.modifyRoiAccessStatus = RequestStatus.NOT_STARTED;
    }

    getAllParameters(): Parameter[] {
        return this.parameters;
    }

    getParameterById(id: string): Parameter{
        return this.parameters.find(p => p.id == id);
    }

    getParameterByIdAndSubsetId(id: string, subsetId?: string): Parameter{
        if(subsetId){
            return this.parameters.find(p => p.id === id && p.subsetId === subsetId);
        }else{
            return this.parameters.find(p => p.id === id);
        }     
    }

    getRois(): Roi[] {
        return this.rois;
    }

    async fetchDataSetIssuesByRoi(roiId: string, firstDate: number, lastDate: number){
        let resp = await this.fetchDataIssuesForRoi(roiId, firstDate, lastDate);
        this.dataSetFetchStatus = RequestStatus.SUCCESS;

        return resp.dataIssues;
    }

    async fetchDataSetParamsByRoi(roiId: string, timestamp: number){
        let resp = await this.fetchDataParamsForRoi(roiId, timestamp);
        this.dataSetFetchStatus = RequestStatus.SUCCESS;

        return resp.dataParams;
    }

    async fetchDataSetsByRoi(roiId: string, timestamp: number, paramId: string){
        let resp = await this.fetchDataSetsForRoi(roiId, timestamp, paramId );
        this.dataSetFetchStatus = RequestStatus.SUCCESS;

        return resp.datasetParameter;
    }

    getRoiById(id: string): Roi {
        return this.rois.find(r => r.id == id);
    }

    getDataDownloadStartDate(roiId: string){      
        let roi = this.rois.find(x => x.id == roiId);       
        return roi.availableForecasts? roi.availableForecasts.firstTimestamp : undefined;
    }

    getDataDownloadEndDate(roiId: string){       
        let roi = this.rois.find(x => x.id == roiId);      
        return roi.availableForecasts? roi.availableForecasts.lastTimestamp : undefined;
    }

    saveMapFilePath(roiId: string, version: string, param: string, timestamp: string, path: string){       
        let dataSets = this.dataSetMap.find(x => x.roiId === roiId).dataSets;
        let dsVersionIndex = dataSets.findIndex(x => x.forecastTimestamp.toString() == version);
        let dsParamIndex = dataSets[dsVersionIndex].parameters.findIndex(x => x.parameterId == param);
        let dsRecordIndex = dataSets[dsVersionIndex].parameters[dsParamIndex].records.findIndex(x => x.timestamp.toString() == timestamp.split('.')[0]);
       
        dataSets[dsVersionIndex].parameters[dsParamIndex].records[dsRecordIndex].mapFilePath = path;       
    }

    getDataLoadedStatus(): RequestStatus {
        if(this.roisFetchStatus == RequestStatus.SUCCESS && this.dataSourcesFetchStatus == RequestStatus.SUCCESS){
            return RequestStatus.SUCCESS;
        }else {
            if(this.roisFetchStatus == RequestStatus.ERROR || this.dataSourcesFetchStatus == RequestStatus.ERROR){
                return RequestStatus.ERROR;
            }else{
                if(this.roisFetchStatus == RequestStatus.IN_PROGRESS || this.dataSourcesFetchStatus == RequestStatus.IN_PROGRESS){
                    return RequestStatus.IN_PROGRESS;
            }else{
                return RequestStatus.NOT_STARTED;
            }
        }
    }
}

    getDataSetFetchStatus(): RequestStatus {
        return this.dataSetFetchStatus;
    }

    completeRoiUpload(){
        this.roiUploadStatus = RequestStatus.NOT_STARTED;
    }

    refresh(){
        this.fetchRois();
        this.fetchServerStatus();
        this.dataSetMap = [];
    }

    completeRoiDeletion(){
        this.roiDeleteStatus = RequestStatus.NOT_STARTED;
    }

    completeMapFileCreation(){
        this.createMapFileStatus = RequestStatus.NOT_STARTED;
    }

    completeRoiUpdate(){
        this.updateRoiStatus = RequestStatus.NOT_STARTED;
    }
}