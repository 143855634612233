import * as React from "react";
import * as ReactDOM from "react-dom";
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import Feature from 'ol/Feature';
import {applyTransform} from 'ol/extent';
import TileLayer from 'ol/layer/Tile';
import Draw, {createRegularPolygon, createBox, DrawEvent} from 'ol/interaction/Draw';
import {get as getProjection, getTransform} from 'ol/proj';
import {register} from 'ol/proj/proj4';
import OSM from 'ol/source/OSM';
import proj4 from 'proj4';
import { Vector as VectorSource, GeoJSON} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer';
import { OutputProjectionType, SpatialConstraint, Roi } from "../../../../lambda/interfaces/rois";
import {defaults as defaultControls, Control} from 'ol/control';
import { Point, LinearRing, Polygon, LineString} from 'ol/geom';
import { Style, Fill, Stroke } from 'ol/style';
import { Util } from "../../util/Util";
import { GeoUtil } from "../../data/GeoUtil";
import { DragPan, Select } from 'ol/interaction';
import { pointerMove } from 'ol/events/condition';
import { mainBlue, defaultMargin, lightRed } from "../../styles/styles";

var hoveredStyle = new Style({
    stroke: new Stroke({
    width: 3,
    color: lightRed,
    }),
    fill: new Fill({
      color: 'transparent',
    }),
});

var normalStyle = new Style({
  stroke: new Stroke({
  width: 3,
  color: mainBlue,
  }),
  fill: new Fill({
      color: 'transparent',
    }),
});

  interface Props {
      rois: Roi[];
      onRoiHover: (id) => void;
      onRoiClick: (id) => void;
  }

  interface State {
      map: Map | null ;
      rois: Roi[];
  }

const equirectangularProj = 'EPSG:4326';
var lastHovered = null;
const HIT_DETECTION_WIDTH = 1.5;

export class AllRoiMap extends React.Component<Props,State> {
  constructor(props) {
    super(props);    
    this.state={
        map: null,
        rois: this.props.rois,
    } 
  }

  static addFeaturesToMap(rois: Roi[], source: VectorSource){
    //removing previous ones
    source.clear();
    for(let i=0; i< rois.length; i++){
      let roiMinX;
      let roiMaxX;
      let roiMinY;
      let roiMaxY;
  
      if(rois[i].spatialConstraint.projectionType === OutputProjectionType.EQUIRECTANGULAR){
        roiMinX = rois[i].spatialConstraint.minX;
        roiMaxX = rois[i].spatialConstraint.maxX;
        roiMinY = rois[i].spatialConstraint.minY;
        roiMaxY = rois[i].spatialConstraint.maxY;
      }else{
        const minlatLong = GeoUtil.utmToLatLon(rois[i].spatialConstraint.minX, rois[i].spatialConstraint.minY, rois[i].spatialConstraint.zone, rois[i].spatialConstraint.facing);
        const maxlatLong = GeoUtil.utmToLatLon(rois[i].spatialConstraint.maxX, rois[i].spatialConstraint.maxY, rois[i].spatialConstraint.zone, rois[i].spatialConstraint.facing);
  
        roiMinX = minlatLong.longitude;
        roiMinY = minlatLong.latitude;
        roiMaxX = maxlatLong.longitude;
        roiMaxY = maxlatLong.latitude;
      }
  
      AllRoiMap.createRectPolygon(roiMinX, roiMaxX, roiMinY,roiMaxY, rois[i].id, source);
  }
  }

  static setUpMap(rois: Roi[], onRoiHover: (id) => void, onRoiClick: (id) => void): Map{

    var source = new VectorSource({wrapX: false});

    var raster = new TileLayer({
      source: new OSM()
    });

    var vector = new VectorLayer({
      source: source,
    });
  
        //create the map
        var map: Map = new Map({
          layers: [raster, vector],
          target: 'map',      
          view: new View({
          projection: equirectangularProj,
          center: [0, 0],
          zoom: 1,
          })
      }); 

      AllRoiMap.addFeaturesToMap(rois, source);

      map.on('click', function(e) {
        const features = map.getFeaturesAtPixel(e.pixel);
        for(let i=0; i< features.length; i++){     
          var coords =    features[i].getGeometry().getCoordinates()[0];
          let minX = coords[0][0];
          let maxX = coords[1][0];
          let minY = coords[1][1];
          let maxY = coords[2][1];
          const clickedCoordinates = map.getCoordinateFromPixel(e.pixel);
          const x = clickedCoordinates[0];
          const y = clickedCoordinates[1];
          if((x >= minX - HIT_DETECTION_WIDTH && x <= maxX + HIT_DETECTION_WIDTH && y >= maxY - HIT_DETECTION_WIDTH && y <= maxY + HIT_DETECTION_WIDTH)|| 
            (x >= minX - HIT_DETECTION_WIDTH && x <= maxX + HIT_DETECTION_WIDTH && y >= minY - HIT_DETECTION_WIDTH && y <= minY + HIT_DETECTION_WIDTH) || 
            (y >= minY - HIT_DETECTION_WIDTH && y <= maxY + HIT_DETECTION_WIDTH && x >= minX - HIT_DETECTION_WIDTH && x <= minX + HIT_DETECTION_WIDTH) || 
            (y >= minY - HIT_DETECTION_WIDTH && y <= maxY + HIT_DETECTION_WIDTH && x >= maxX - HIT_DETECTION_WIDTH && x <= maxX + HIT_DETECTION_WIDTH))
            {
            onRoiClick(features[i].getId());           
          }
        }   
      });

        map.on('pointermove', function(e) {
            if(lastHovered !== null){
                lastHovered.setStyle(normalStyle);
            }

            const features = map.getFeaturesAtPixel(e.pixel);
            if(features.length == 0){             
              onRoiHover(null);
            }else{
              let featureOnBoundary = null;
              for(let i=0; i< features.length; i++){
                var coords =    features[i].getGeometry().getCoordinates()[0];
                let minX = coords[0][0];
                let maxX = coords[1][0];
                let minY = coords[1][1];
                let maxY = coords[2][1];
                const clickedCoordinates = map.getCoordinateFromPixel(e.pixel);
                  const x = clickedCoordinates[0];
                  const y = clickedCoordinates[1];
                  if((x >= minX - HIT_DETECTION_WIDTH && x <= maxX + HIT_DETECTION_WIDTH && y >= maxY - HIT_DETECTION_WIDTH && y <= maxY + HIT_DETECTION_WIDTH)|| 
                    (x >= minX - HIT_DETECTION_WIDTH && x <= maxX + HIT_DETECTION_WIDTH && y >= minY - HIT_DETECTION_WIDTH && y <= minY + HIT_DETECTION_WIDTH) || 
                    (y >= minY - HIT_DETECTION_WIDTH && y <= maxY + HIT_DETECTION_WIDTH && x >= minX - HIT_DETECTION_WIDTH && x <= minX + HIT_DETECTION_WIDTH) || 
                    (y >= minY - HIT_DETECTION_WIDTH && y <= maxY + HIT_DETECTION_WIDTH && x >= maxX - HIT_DETECTION_WIDTH && x <= maxX + HIT_DETECTION_WIDTH))
                    {
                  if(featureOnBoundary === null){
                    featureOnBoundary = features[i];    
                    lastHovered = features[i];    
                  }                        
                }
              }   
              if(featureOnBoundary !== null){
                featureOnBoundary.setStyle(hoveredStyle);
                onRoiHover(featureOnBoundary.getId());              
              }else{
                if(lastHovered !== null){
                  lastHovered.setStyle(normalStyle);               
                  //lastHovered = null;
                  onRoiHover(null);
                }            
              }
            }           
          });

            return map;
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if(nextProps.rois !== prevState.rois){
        return {
            rois: nextProps.rois,
        }
    }

    return null;
}

  componentDidUpdate(prevProps: Props, prevState: State, snapshot) { 
    const layers = this.state.map.getLayers().getArray();
    const source = layers[1].getSource();
    AllRoiMap.addFeaturesToMap(this.props.rois, source);
  }

  componentDidMount(){
    this.setState({map: AllRoiMap.setUpMap(this.props.rois, this.props.onRoiHover, this.props.onRoiClick)});   
    }

    static createRectPolygon(minX: number, maxX: number, minY: number, maxY: number/*, style: Style*/, roiId: string, source: VectorSource){
        var ring = [[minX,minY],[maxX,minY],[maxX,maxY],[minX, maxY],[minX,minY]];
        var polygon = new Polygon([ring]);  
        var feature = new Feature(polygon);
        feature.setId(roiId);
        feature.setStyle(normalStyle);
        source.addFeature(feature);       
    }

    render(){


    if(this.state.map !== null){
    return (
        <div id={'map-all-rois'} style={{display: 'flex', flexDirection: 'column', flexGrow: 1, width: '100%',}} className="map" ref={(el)=> {
          this.state.map.setTarget(el);
        }}></div>
    );
    }else{
        return <div/>;
    }
    
    }
}