import * as React from "react";
import { InlineDataSource, RoiOverview } from '../../components'
import { DataSource, Parameter } from "../../../../lambda/interfaces/sources";
import { defaultMargin, defaultPadding, tableCaption, tabPage, fullHeight, row, shellDarkGray } from "../../styles/styles";
import { RoiPreview } from "../NewRoi";
import { DataSourceReference } from "../../../../lambda/interfaces/rois";
import { DataProvider } from "../../data/DataProvider";
import { string } from "prop-types";

export interface Props {
    roiPreview: RoiPreview;
    updateDataSourceReferences: (dsRefs: DataSourceReference[]) => void;
    dataProvider: DataProvider;
}

export interface State {
    availableDataSources: DataSource[];
}

export default class ParamSelection extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
       
        this.state = {
            availableDataSources: this.props.dataProvider.getDataSources(),
        }
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        if(nextProps.dataProvider.getDataSources() !== prevState.availableDataSources){
            return {
                availableDataSources: nextProps.dataProvider.getDataSources(),
              };
        }
        return null;
      }

    updateParamGroups = (dsref: DataSourceReference) => {
         this.props.updateDataSourceReferences([dsref]);
    }

    public render(): JSX.Element
    {

        return (
            <div style={{ height: '100%',display: 'flex',flexDirection: 'column', ...fullHeight, color: shellDarkGray}} >     
                {
                    this.state.availableDataSources.length == 0 &&
                    <div >No data source is currently available or isn't loaded yet</div>
                }   
                {
                    this.state.availableDataSources.map((dataSource,) => 
                    {
                        const dsRef = this.props.roiPreview.dataSources.find(x => x.dataSourceId ==dataSource.id)
                    return <InlineDataSource roiPreview={this.props.roiPreview} selectedDSRef={dsRef} key={dataSource.id} dataSource={dataSource} updateDataSourceReference={(pg) => this.updateParamGroups(pg)} />
                    }
                    )
                }                
            </div>
        );
    }
}