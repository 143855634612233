import * as React from "react";
import {  defaultMargin, row, box, lightRed, lightGreen, lightGray2 } from '../../styles/styles'
import { DataProvider, RequestStatus } from "../../data/DataProvider";
import { Roi, RoiApprovalStatus, PostRoiApprovalStatus } from "../../../../lambda/interfaces/rois";
import { Modal, Spinner, Button } from "react-bootstrap";

interface Props
{
    visible: boolean;
    dataProvider: DataProvider;
    roi: Roi;
    onModalClose: () => void;
}

interface State {
    selectedStatus: PostRoiApprovalStatus;
    status: RequestStatus;
    error: string;
}

export class RoiApprovalModal extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state = {
            selectedStatus: this.props.roi.approvalStatus == RoiApprovalStatus.APPROVED ? PostRoiApprovalStatus.APPROVED : 
            this.props.roi.approvalStatus == RoiApprovalStatus.DECLINED ? PostRoiApprovalStatus.DECLINED : null,
            status: RequestStatus.NOT_STARTED,
            error: null
        }
    }

    getDataSourceById = (id: string) => {
        return this.props.dataProvider.getDataSources().find(x => x.id === id);
    }

    async updateApprovalStatus() {
        let canUpdate = this.state.selectedStatus !== null;
        if (canUpdate)
        {
            this.setState({
                status: RequestStatus.IN_PROGRESS,
                error: null
            });
            
            await this.props.dataProvider.modifyRoiApprovalStatus(this.props.roi.id, this.state.selectedStatus);  

            this.setState({
                status: RequestStatus.NOT_STARTED
            });

            this.props.onModalClose();
        }
        else {
            this.setState({
                error: "Please select an approval status for this ROI."
            });
        }
    }

    public render(): JSX.Element
    {      
        if(this.state.status == RequestStatus.SUCCESS){
            this.props.onModalClose();
        }
      
        return (
            <Modal show={this.props.visible}  backdrop="static">
                <Modal.Header >
                    <Modal.Title>Set ROI approval status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {
                            this.state.status == RequestStatus.NOT_STARTED &&
                            <div>     
                                {
                                    this.props.roi.dataSources.map(ds =>
                                        <div style={{marginBottom: defaultMargin, maxHeight: 200, overflowY: 'auto'}} key={ds.dataSourceId}>
                                            <div style={{fontWeight: 'bold'}}>{ds.dataSourceId}:</div>
                                            <div style={{whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{__html: this.getDataSourceById(ds.dataSourceId).license}}></div>
                                        </div>)
                                }
                                <div style={{...row, width: '100%'}}>
                                    <div 
                                        style={{...box,cursor: 'pointer', backgroundColor: this.state.selectedStatus == PostRoiApprovalStatus.APPROVED ? lightGreen : lightGray2, flex: 1}} 
                                        onClick={() => this.setState({selectedStatus: PostRoiApprovalStatus.APPROVED})}
                                    > APPROVED</div>
                                    <div 
                                    style={{...box, cursor: 'pointer', backgroundColor: this.state.selectedStatus == PostRoiApprovalStatus.DECLINED ? lightRed : lightGray2, flex: 1}} 
                                    onClick={() => this.setState({selectedStatus: PostRoiApprovalStatus.DECLINED})}
                                    > DECLINED</div>
                                </div>
                                {
                                    this.state.error && <div style={{ color: 'red' }}>{this.state.error}</div> 
                                }
                            </div>
                        }   
                        {
                            this.state.status == RequestStatus.ERROR &&
                                <p>There was an error while updating the approval status.</p>
                        }                    
                        {
                            this.state.status == RequestStatus.IN_PROGRESS &&
                            <div style={{...row, alignItems: 'center'}}>
                                 <Spinner animation="border" role="status" variant="primary"/>
                                <div style={{ marginLeft: defaultMargin}}>Update in progress ...</div>
                            </div>
                        }
                </Modal.Body>
                   <Modal.Footer>
                       {
                           (this.state.status == RequestStatus.NOT_STARTED || 
                           this.state.status == RequestStatus.ERROR) &&
                            <Button className="shell-secondary-btn" onClick={() => {
                                this.setState({
                                    status: RequestStatus.NOT_STARTED,
                                    error: null
                                });
                                this.props.onModalClose();
                            }}>Cancel</Button>
                       }
                        {
                            this.state.status == RequestStatus.NOT_STARTED &&
                            <Button className="shell-gray-btn" onClick={this.updateApprovalStatus.bind(this)}>Update</Button> 
                        }
                    </Modal.Footer>                
            </Modal>     
        );
    }
}