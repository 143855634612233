import * as React from "react";
import {  defaultMargin, row, defaultPadding, mediumFontSize, box, lightRed, lightGreen, lightGray, gray, lightGray2 } from '../../styles/styles'
import { DataProvider, RequestStatus } from "../../data/DataProvider";
import { Roi, RoiApprovalStatus, PostRoiApprovalStatus } from "../../../../lambda/interfaces/rois";
import { Modal, Spinner, Button } from "react-bootstrap";

interface Props
{
    visible: boolean;
    dataProvider: DataProvider;
    roi: Roi;
    onModalClose: () => void;
    user: string;
    canAccept: boolean;
}

interface State {
    selectedAllowReadStatus: boolean;
}

export class ChangeAccessModal extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state = {
            selectedAllowReadStatus: false
        }
    }

    public render(): JSX.Element
    {      
        if(this.props.dataProvider.getModifyRoiAccessStatus() == RequestStatus.SUCCESS){
            this.props.dataProvider.completeModifyRoiAccessAction();
            this.props.onModalClose();
        }

        const title = "Modify user access to ROI";
        const actionDescription = `This action will modify access for user ${this.props.user} to ROI ${this.props.roi.id} (${this.props.roi.name}).`;
      
        return (
            <Modal show={this.props.visible}  backdrop="static">
                <Modal.Header >
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {
                            this.props.dataProvider.getModifyRoiAccessStatus() == RequestStatus.NOT_STARTED &&
                            <div>     
                                <div>
                                    <p>{actionDescription}</p>
                                </div>                   
                                <div style={{...row, width: '100%'}}>
                                    <div 
                                        style={{
                                            ...box,
                                            cursor: 'pointer', 
                                            backgroundColor: this.state.selectedAllowReadStatus ? lightGreen : lightGray2, 
                                            flex: 1
                                        }} 
                                        onClick={() => {
                                            if (this.props.canAccept) {
                                                this.setState({selectedAllowReadStatus: true})
                                            }  
                                        }}
                                    > APPROVED (Admin only)</div>
                                    <div 
                                    style={{...box, cursor: 'pointer', backgroundColor: !this.state.selectedAllowReadStatus ? lightRed : lightGray2, flex: 1}} 
                                    onClick={() => this.setState({selectedAllowReadStatus: false})}
                                    > DECLINED</div>
                                </div>
                            </div>
                                
                        }   
                        {
                            this.props.dataProvider.getModifyRoiAccessStatus() == RequestStatus.ERROR &&
                                <p>There was an error while updating the access status.</p>
                        }                    
                        {
                            this.props.dataProvider.getModifyRoiAccessStatus() == RequestStatus.IN_PROGRESS &&
                            <div style={{...row, alignItems: 'center'}}>
                                 <Spinner animation="border" role="status" variant="primary"/>
                                <div style={{ marginLeft: defaultMargin}}>Update in progress ...</div>
                            </div>
                        }
                </Modal.Body>
                   <Modal.Footer>
                       {
                           (this.props.dataProvider.getModifyRoiAccessStatus() == RequestStatus.NOT_STARTED || 
                           this.props.dataProvider.getModifyRoiAccessStatus() == RequestStatus.ERROR) &&
                            <Button className="shell-secondary-btn" onClick={() => {
                                this.props.onModalClose();
                                this.props.dataProvider.completeModifyRoiAccessAction();
                            }}>Cancel</Button>
                       }
                        {
                            this.props.dataProvider.getModifyRoiAccessStatus() == RequestStatus.NOT_STARTED &&
                            <Button className="shell-gray-btn" onClick={() => {                                            
                                this.props.dataProvider.modifyRoiAccess(this.props.roi.id, this.props.user, this.state.selectedAllowReadStatus);                    
                             }}>Update</Button> 
                        }
                    </Modal.Footer>                
            </Modal>     
        );
    }
}



