import * as React from "react";
import { Dropdown } from 'react-bootstrap';
import { Roi, RoiApprovalStatus, RoiUserAccessStatus } from "../../../../lambda/interfaces/rois";;
import { DataProvider } from "../../data/DataProvider";
import { defaultPadding, defaultMargin } from "../../styles/styles";
import { Util } from "../../util/Util";

export interface Props {
    isAdmin: boolean;
    dataProvider: DataProvider;
    onFilter: (list: Roi[]) => void;
}

export interface State {
    approvalStatusFilter: RoiApprovalStatus | undefined;
    textFilter: string | undefined;
    allUserRoisFilter: boolean;
}


export default class RoiFilter extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state = {
            approvalStatusFilter: undefined,
            textFilter: undefined,
            allUserRoisFilter: false
        }     
    }

    getFilteredlist = (textFilter: string | undefined, approvalStatusFilter: RoiApprovalStatus | undefined, allUserRoisFilter: boolean): Roi[] => {
        let statusFilteredList: Roi[]  = [];

        if(approvalStatusFilter !== undefined ){         
            this.props.dataProvider.getRois().filter(x => allUserRoisFilter || x.isOwner).map(roi => {
                if(roi.approvalStatus === approvalStatusFilter){
                    statusFilteredList.push(roi);
                }
            });
        }else{
            statusFilteredList = this.props.dataProvider.getRois().filter(x => allUserRoisFilter || x.isOwner);
        }

        let textFilteredList = [];
        if(textFilter !== undefined && textFilter.length > 0){
            statusFilteredList.map(roi => {
                roi.dataSources.map(ds => {
                    if(ds.dataSourceId.toLowerCase().includes(textFilter) && textFilteredList.indexOf(roi) == -1){
                        textFilteredList.push(roi);
                    }
                });

                if((roi.name.toLowerCase().includes(textFilter) ||
                (this.props.isAdmin && roi.owner.toLowerCase().includes(textFilter))) && textFilteredList.indexOf(roi) == -1){
                    textFilteredList.push(roi);
                }
            });
        }else{
            textFilteredList = statusFilteredList;
        }
        return textFilteredList;
    }

    public render(): JSX.Element
    {      
        const textFilterPlaceholder = this.props.isAdmin ? 'Filter by name, owner or data source' : 'Filter by name or data source';
        return ( 
                <div style={{display: 'flex', flexDirection: 'row',  paddingTop: defaultPadding}}>
                    <input 
                        type="text" 
                        className="shell-input" 
                        style={{width: 250, marginRight: defaultMargin * 2}} 
                        placeholder={textFilterPlaceholder}
                        onChange={(evt) => {
                            let text = evt.target.value.toLowerCase();
                            this.setState({textFilter: text});
                            this.props.onFilter(this.getFilteredlist(text, this.state.approvalStatusFilter, this.state.allUserRoisFilter));
                        }}
                    />      
                    <Dropdown style={{marginBottom: defaultMargin}}>
                            <Dropdown.Toggle className="shell-dropdown" id="dropdown-spatial-output-projection">
                                {this.state.approvalStatusFilter === undefined ? 'Filter by approval status' : Util.getLabelForApprovalStatus(this.state.approvalStatusFilter)}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onSelect={() => {
                                    this.setState({approvalStatusFilter: undefined});
                                    this.props.onFilter(this.getFilteredlist(this.state.textFilter, undefined, this.state.allUserRoisFilter));
                                    }}>All</Dropdown.Item>
                                <Dropdown.Item onSelect={() => {
                                    this.setState({approvalStatusFilter: RoiApprovalStatus.APPROVED});
                                    this.props.onFilter(this.getFilteredlist(this.state.textFilter, RoiApprovalStatus.APPROVED, this.state.allUserRoisFilter));
                            }}>{Util.getLabelForApprovalStatus(RoiApprovalStatus.APPROVED)}</Dropdown.Item>
                                <Dropdown.Item onSelect={() => {
                                    this.setState({approvalStatusFilter: RoiApprovalStatus.APPROVAL_PENDING});
                                    this.props.onFilter(this.getFilteredlist(this.state.textFilter, RoiApprovalStatus.APPROVAL_PENDING, this.state.allUserRoisFilter));
                                }}>{Util.getLabelForApprovalStatus(RoiApprovalStatus.APPROVAL_PENDING)}</Dropdown.Item>
                                <Dropdown.Item onSelect={() => {
                                    this.setState({approvalStatusFilter: RoiApprovalStatus.DECLINED});
                                    this.props.onFilter(this.getFilteredlist(this.state.textFilter, RoiApprovalStatus.DECLINED, this.state.allUserRoisFilter));
                                }}>{Util.getLabelForApprovalStatus(RoiApprovalStatus.DECLINED)}</Dropdown.Item>                                   
                            </Dropdown.Menu>
                    </Dropdown>
                    <table 
                        className="shell-input" 
                        style={{ border: '1px solid #a0a0a0', marginLeft: defaultMargin * 2 }}>
                        <tbody>
                            <tr>
                                <td>
                                    <input 
                                        id="allUsers"
                                        type='checkbox' 
                                        style={{ marginLeft: defaultMargin * 0.5 }} 
                                        onChange={() => {
                                            this.setState(prevState => {
                                                this.props.onFilter(this.getFilteredlist(this.state.textFilter, this.state.approvalStatusFilter, !prevState.allUserRoisFilter));
                                                return { allUserRoisFilter: !prevState.allUserRoisFilter }
                                            });                                        
                                        }}
                                        checked={this.state.allUserRoisFilter}
                                    />
                                </td>
                                <td>
                                    <label 
                                        style={{ 
                                            marginTop: '0px', marginBottom: '0px', 
                                            marginLeft: defaultMargin * 0.5, marginRight: defaultMargin * 0.5,
                                            position: "relative", 
                                            top: '0px', bottom: '0px', 
                                            paddingBottom: '2px' 
                                        }} 
                                        htmlFor="allUsers"
                                    >
                                        Show ROIs from other users
                                    </label>                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
        );
    }
}