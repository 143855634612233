import * as React from "react";
import {  defaultMargin, row, defaultPadding, mediumFontSize, box, lightRed, lightGreen, lightGray, gray, lightGray2, smallFontSize, shellDarkGray } from '../../styles/styles'
import { DataProvider, RequestStatus } from "../../data/DataProvider";
import { Roi, RoiApprovalStatus } from "../../../../lambda/interfaces/rois";
import { Modal, Spinner, Button } from "react-bootstrap";
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {CopyToClipboard} from 'react-copy-to-clipboard';

interface Props
{
    visible: boolean;
    onModalClose: () => void;
    wmsServerUrl: string;
}

interface State {
   copiedMessage: string
}

export class WMSSettingsModal extends React.Component<Props, State>
{
    constructor(props: Props){
        super(props);
        this.state = {
            copiedMessage: '',
        }
    }

    componentDidUpdate(){
        setTimeout(() => this.setState({copiedMessage:''}), 10000);
      }   

    public render(): JSX.Element
    {      
   
        return (
            <Modal show={this.props.visible}  backdrop="static">
                <Modal.Header >
                    <Modal.Title>WMS Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>WMS Server URL:</p>
                    <div style={{...row, alignItems: 'center'}}>
                        <textarea  className="form-control" disabled value={this.props.wmsServerUrl} rows={5}/>
                        <CopyToClipboard text={this.props.wmsServerUrl} onCopy={() => this.setState({copiedMessage: 'Copied!'})}>
                            <Button style={{marginLeft: 20, height: 40, backgroundColor: shellDarkGray}} >
                                <FontAwesomeIcon icon={faCopy} style={{fontSize: 20, color: 'white'}} />
                            </Button>
                        </CopyToClipboard>                      
                    </div>
                    <div style={{...row, width: '100%', justifyContent: 'flex-end', fontSize: smallFontSize, height: 15}}>{this.state.copiedMessage}</div>
                    
                </Modal.Body>
                   <Modal.Footer>
                            <Button className="shell-secondary-btn" onClick={() => {
                                this.props.onModalClose();
                            }}>Close</Button>

                    </Modal.Footer>                
            </Modal>     
        );
    }
}



