import * as React from "react";
import { Roi, RoiDataCollectionStatus, RoiUserAccessStatus, RoiAccessListStatus, UserInteractionType } from "../../../../lambda/interfaces/rois";
import { RoiOverview, StatusBox, BoxStatus } from "../../components";
import { row, defaultMargin, smallFontSize, mediumFontSize, defaultPadding, lightGray } from "../../styles/styles";
import { Util } from "../../util/Util";
import { DataProvider } from "../../data/DataProvider";
import { Button, OverlayTrigger, Tooltip, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faStop, faPlay } from "@fortawesome/free-solid-svg-icons";
import { StopDataCollectionModal } from "../../components/modals/StopDataCollectionModal";
import { PauseResumeModal } from "../../components/modals/PauseResumeModal";
import { RequestRoiAccessModal } from "../../components/modals/RequestRoiAccessModal";


export interface Props {
    roi: Roi;
    dataProvider: DataProvider;
}

export interface State {
   stopDataCollectionModalVisible: boolean;
   pauseResumeModalVisible: boolean;
   requestAccessModalVisible: boolean;
}

export default class Overview extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state = {
            stopDataCollectionModalVisible: false,
            pauseResumeModalVisible: false,
            requestAccessModalVisible: false
        }
    }

    canUpdate = Util.canUpdateAccess(this.props.roi.userAccess);

    public render(): JSX.Element
    {

        const notAllDataIsDownloaded = this.props.roi.dataCollectionStatus === RoiDataCollectionStatus.COMPLETE && this.props.roi.hasMissingDataWhenComplete === true;
        const roiPausableStoppable = this.canUpdate && this.props.roi.dataCollectionStatus !== RoiDataCollectionStatus.COMPLETE && !this.props.roi.isStopped;
      
        return (
            <div style={{...row, }}>   
                <div style={{flex: 2, maxWidth: 520}}>
                    <RoiOverview readonly={true} roiPreview={this.props.roi} dataProvider={this.props.dataProvider}/>
                </div>            
                
                <div style={{flex: 1, margin: defaultMargin, paddingLeft: defaultMargin * 2}}>       
                        <div >Access status:</div>                             
                        {
                            this.props.roi.userAccess === RoiUserAccessStatus.NONE ? (
                                <Button className="shell-gray-btn" style={{ ...row, width: 300, justifyContent: 'center', alignItems: 'center'}}
                                    onClick={() => this.setState({requestAccessModalVisible: true})}
                                >  
                                    <div style={{fontSize: smallFontSize, marginLeft: defaultMargin}}>Request Access</div>
                                </Button>  
                            ) : (
                                <StatusBox 
                                    status={Util.getColorForAccessStatus(this.props.roi.userAccess)} 
                                    label={Util.getLabelForAccessStatus(this.props.roi.userAccess)} 
                                /> 
                            )
                        }      

                        <div style={{  marginTop: defaultMargin}}>Data collection status:</div> 
                        <StatusBox 
                            status={this.props.roi.isStopped ? BoxStatus.ERROR : Util.getColorForCollectionStatus(this.props.roi.dataCollectionStatus)} 
                            label={this.props.roi.isStopped ? "Stopped" : notAllDataIsDownloaded ? "Complete*" : Util.getLabelForCollectionStatus(this.props.roi.dataCollectionStatus)} 
                        />
                        {
                            notAllDataIsDownloaded &&
                            <div style={{fontSize: smallFontSize}}>*(Not all data could be downloaded for the requested period)</div>
                        }
                        {
                            roiPausableStoppable &&
                            <div style={{...row, marginTop: defaultMargin}}>
                                <OverlayTrigger placement="top" overlay={(props) => 
                                    <Tooltip id="pause-tooltip" {...props}>
                                       {!this.props.roi.isSuspended ? "Suspend data collection for this ROI after the next run completes." : "Resume data collection for this ROI."}
                                    </Tooltip> }>
                                    <Button className="shell-gray-btn" style={{...row, width: 150, justifyContent: 'center', alignItems: 'center'}}
                                        onClick={() => this.setState({pauseResumeModalVisible: true})}
                                    >  
                                            <FontAwesomeIcon icon={this.props.roi.isSuspended ? faPlay : faPause} className="shell-gray-btn-icon" fontSize={smallFontSize}/>

                                        <div style={{fontSize: smallFontSize, marginLeft: defaultMargin}}>{this.props.roi.isSuspended ? 
                                        
                                        "Resume" : "Pause"}</div>
                                    </Button>                                   
                                </OverlayTrigger> 
                               
                                <OverlayTrigger placement="top" overlay={(props) => 
                                    <Tooltip id="pause-tooltip" {...props}>
                                       Reset the ROI end date to the current date, so data collection for this ROI will end permanently.
                                    </Tooltip> }>
                                    <Button className="shell-red-white-btn" style={{...row, width: 150, justifyContent: 'center', alignItems: 'center'}}
                                        onClick={() => this.setState({stopDataCollectionModalVisible: true})}
                                    >
                                        <FontAwesomeIcon icon={faStop} className="shell-gray-btn-icon" fontSize={smallFontSize}/>
                                        <div style={{fontSize: smallFontSize, marginLeft: defaultMargin}}>Stop</div>
                                    </Button>                                   
                                </OverlayTrigger>                                
                            </div>
                        }
                        {
                            this.props.roi.hasBeenUpdated &&
                            <div><b>Last modified:</b> {this.props.roi.hasBeenUpdated.user} {this.getPrintableUserInteractionType(this.props.roi.hasBeenUpdated.action)} on {Util.formatTimestamp(this.props.roi.hasBeenUpdated.timestamp)}</div>
                        }
                        <div style={{  marginTop: defaultMargin}}>Approval status:</div> 
                        <StatusBox 
                            status={Util.getColorForApprovalStatus(this.props.roi.approvalStatus)} 
                            label={Util.getLabelForApprovalStatus(this.props.roi.approvalStatus)} 
                            />
                        <div style={{ marginTop: defaultMargin}}>Storage:</div>
                        <div style={{fontWeight: 'bold'}}>{this.props.roi.dataStorage + " " + this.props.roi.dataStorageUnit}</div>
                </div>
                <StopDataCollectionModal 
                    visible={this.state.stopDataCollectionModalVisible} 
                    onModalClose={() => this.setState({stopDataCollectionModalVisible: false})} 
                    roiToStop={this.props.roi} 
                    dataProvider={this.props.dataProvider}
                />
                <PauseResumeModal 
                    visible={this.state.pauseResumeModalVisible} 
                    onModalClose={() => this.setState({pauseResumeModalVisible: false})} 
                    roi={this.props.roi} 
                    dataProvider={this.props.dataProvider}
                />
                <RequestRoiAccessModal 
                    visible={this.state.requestAccessModalVisible} 
                    onModalClose={() => this.setState({requestAccessModalVisible: false})} 
                    roi={this.props.roi} 
                    dataProvider={this.props.dataProvider}
                />
            </div>
        );
    }

    getPrintableUserInteractionType = (interaction: UserInteractionType): string =>{
        switch(interaction){
            case UserInteractionType.PAUSE:
                return "paused";
            case UserInteractionType.STOP:
                return "stopped";
            case UserInteractionType.RESUME:
                return "resumed";
            case UserInteractionType.TEMPORAL_CONSTRAINT_UPDATE:
                return "updated temporal constraints";
            default:
                return "updated this roi";
        }
    }
}