import * as React from "react";
import { Roi, DatasetParameter, OutputProjectionType, DataIssues, DataParams } from "../../../../lambda/interfaces/rois";
import { row, fullHeight, defaultMargin, defaultPadding, mainBlue, shellRed } from "../../styles/styles";
import { Card,Tooltip, OverlayTrigger } from "react-bootstrap";
import { Util } from "../../util/Util";
import { DataProvider, RequestStatus } from "../../data/DataProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { DataSelector } from "../../components/selectors/DataSelector";
import { DownloadGribModal } from "../../components/modals/DownloadGribModal";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { Redirect } from 'react-router-dom';

let baseUrl: string;
if (window.location.host.startsWith("localhost")) {
    baseUrl = "http://localhost:3000"
}
else if (window.location.host === "development.shellmetnetspatial.com") {
    baseUrl = 'https://api.development.shellmetnetspatial.com';
}
else {
    baseUrl = 'https://api.shellmetnetspatial.com';
}

export interface Props {
    roi: Roi;
    dataProvider: DataProvider;
    userCanRead: boolean;

    dataIssues: DataIssues;
    selectedIssueTimestamp: number;
    dataSetFetchStatus: RequestStatus;
    selectedYearUtc: number;
    selectedMonthUtc: number;
    dataParams: DataParams;
    selectedParam: string | null;
    datasetParameter: DatasetParameter;
    selectedRecordTimestamp: number;

    updateDataIssues: () => any;
    updateSelectedIssueTimestamp: (selectedTs: number) => any;
    onDatePickerNavigate: (month: number, year: number) => any;
    onDatePickerDateSelect: () => any;
    updateSelectedParam: (param: string) => any;
    onSelectRecord: (recordTs: number) => any;
}

export interface State {
   showDownloadGribModal: boolean;
}

export default class DataCollection extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state = {
            showDownloadGribModal: false,
        }
    }

    public async componentDidMount() {
        //default to currently selected month
        await this.props.updateDataIssues();
    }

    getMonthStartEndTs(year: number, month: number)
    {
        return {
            firstTs: new Date(year, month, 1).getTime(),
            lastTs:  new Date(year, month + 1, 0).getTime()
        }
    }

    public render(): JSX.Element
    {
        if(this.props.dataSetFetchStatus === RequestStatus.ERROR){
            <Redirect to="/"/>
        }

        const grayedOut = {color: 'lightgray'};
        const redStyle = {color: shellRed, fontWeight: 'bold'};

        let dataDownloadUrl = this.props.selectedRecordTimestamp ? `${baseUrl}/download/data/` + this.props.roi.id + "/" + this.props.selectedIssueTimestamp + "/" + this.props.selectedParam + "/" + this.props.selectedRecordTimestamp : null;

        let showLoader = this.props.dataSetFetchStatus === RequestStatus.NOT_STARTED || this.props.dataSetFetchStatus === RequestStatus.IN_PROGRESS;

        return (
            <div style={{ ...fullHeight}}>   
                {
                 showLoader &&
                    <LoadingIndicator message="Loading ROI data..."/>
                }
                <div style={{padding: defaultPadding, ...fullHeight}}>
                        <DataSelector 
                            selectedIssueTimestamp={this.props.selectedIssueTimestamp}
                            updateSelectedIssueTimestamp={this.props.updateSelectedIssueTimestamp.bind(this)}
                            dataIssues={this.props.dataIssues}
                            onDatePickerNavigate={this.props.onDatePickerNavigate.bind(this)}
                            onDatePickerDateSelect={this.props.onDatePickerDateSelect.bind(this)}
                            month={this.props.selectedMonthUtc}
                            year={this.props.selectedYearUtc}
                            updateSelectedParam={this.props.updateSelectedParam.bind(this)}
                            selectedParamId={this.props.selectedParam}
                            availableRecords={this.props.datasetParameter && this.props.datasetParameter.records || []}
                            availableParams={this.props.dataParams && this.props.dataParams.params.map(x => x.paramId) || []}
                            selectedRecordTimestamp={this.props.selectedRecordTimestamp}
                            onSelectRecord={this.props.onSelectRecord.bind(this)}
                        />
                            <Card className="shell-block" style={{padding: defaultPadding * 2, marginTop: 30}}>
                                <div style={{...row, justifyContent: 'space-between'}}>
                                    <div style={{fontWeight: 'bold', marginBottom: 30}}>NetCDF Download links</div>
                                    <OverlayTrigger placement="top" overlay={(props) =>
                                    <Tooltip id="historical-data-tooltip" {...props}>
                                        <span>NetCDF file download links use a standard structure. You can create and use these links directly without logging in to METNET Spatial first.</span> 
                                        <br/><br/>
                                        <span>For more information, hover your mouse over each of the variables highlighted red in the links below.</span> </Tooltip>}>
                                            <div style={{...row, alignItems: 'center'}}>
                                            <FontAwesomeIcon icon={faQuestionCircle} 
                                                style={{fontSize: 20, marginRight: 5 }} color={mainBlue} />
                                                <a href="javascript:void(0)">Structure of NetCDF download links</a>
                                            </div>                                           
                                    </OverlayTrigger>
                                </div>    

                                <div className={this.props.roi.availableForecasts ? "" : "disabled-link"}>Download all data for this ROI that was issued most recently:</div>
                                    <a className={this.props.roi.availableForecasts ? "" : "disabled-link"} href={this.props.roi.availableForecasts ? `${baseUrl}/download/merged/` + this.props.roi.id + "/latest" : null}>
                                        <span>{baseUrl}/download/merged/ 
                                            <OverlayTrigger placement="top" overlay={(props) =>
                                                    <Tooltip id="historical-data-tooltip" {...props}>
                                                        <span>ROI ID</span>
                                                        <br/>
                                                        <span>{this.props.roi.id}</span>
                                                    </Tooltip>}>
                                                    <span style={this.props.roi.availableForecasts ? redStyle : grayedOut}>{this.props.roi.id}</span>                                          
                                            </OverlayTrigger>          
                                            /latest
                                        </span>
                                    </a>

                                <div className={this.props.selectedIssueTimestamp && this.props.dataParams ? "" : "disabled-link"} style={{marginTop: defaultMargin * 2}}> Download all data for this ROI issued at {this.props.selectedIssueTimestamp && this.props.dataParams ? Util.formatTimestamp(this.props.selectedIssueTimestamp) : "ISSUE_TIMESTAMP"} :</div>
                                <a className={this.props.selectedIssueTimestamp && this.props.dataParams ? "" : "disabled-link"} href={this.props.selectedIssueTimestamp && this.props.dataParams ? `${baseUrl}/download/merged/` + this.props.roi.id + "/" + this.props.selectedIssueTimestamp : null}>
                                    <span>{baseUrl}/download/merged/
                                        <OverlayTrigger placement="top" overlay={(props) =>
                                                                <Tooltip id="historical-data-tooltip" {...props}>
                                                                    <span>ROI ID</span>
                                                                    <br/>
                                                                    <span>{this.props.roi.id}</span>
                                                                </Tooltip>}>
                                                                <span style={this.props.selectedIssueTimestamp && this.props.dataParams ? redStyle : grayedOut}>{this.props.roi.id}</span>                                          
                                        </OverlayTrigger>          
                                        /
                                        <OverlayTrigger placement="top" overlay={(props) => (this.props.selectedIssueTimestamp && this.props.dataParams !== null) ?
                                                <Tooltip id="historical-data-tooltip" {...props}>
                                                    <span>ISSUE TIMESTAMP (ms since 1970-01-01 00:00 UTC)</span>
                                                    <br/>
                                                    <span>{(this.props.selectedIssueTimestamp && this.props.dataParams !== null) ? this.props.selectedIssueTimestamp : "ISSUE_TIMESTAMP"}</span>
                                                </Tooltip> : <div/>}>
                                                <span style={this.props.selectedIssueTimestamp && this.props.dataParams ? redStyle : grayedOut}>{(this.props.selectedIssueTimestamp && this.props.dataParams !== null) ? this.props.selectedIssueTimestamp : "ISSUE_TIMESTAMP"}</span>
                                        </OverlayTrigger> 
                                    </span>
                                </a>
                                
                                <div className={this.props.selectedRecordTimestamp ? "" : "disabled-link"} style={{marginTop: defaultMargin * 2}}>
                                    <div>Download data for this ROI with timestamp {this.props.selectedRecordTimestamp ? <span>{Util.formatTimestamp(this.props.selectedRecordTimestamp)}</span> : <span style={{fontStyle: 'italic'}}>DATA_TIMESTAMP</span>}, parameter {this.props.selectedParam || "PARAMETER_ID"} issued at {this.props.selectedIssueTimestamp ? <span>{Util.formatTimestamp(this.props.selectedIssueTimestamp)}</span> : <span style={{fontStyle: 'italic'}}>ISSUE_TIMESTAMP</span>} :</div>
                                </div>
                                <a className={this.props.selectedRecordTimestamp ? "" : "disabled-link"}  key={"downloadLink"} href={dataDownloadUrl}><span>{baseUrl}/download/data/        
                                <OverlayTrigger placement="top" overlay={(props) => this.props.selectedRecordTimestamp ?
                                                        <Tooltip id="historical-data-tooltip" {...props}>
                                                            <span>ROI ID</span>
                                                            <br/>
                                                            <span>{this.props.roi.id}</span>
                                                        </Tooltip> : <div/>}>
                                                        <span style={this.props.selectedRecordTimestamp ? redStyle : grayedOut}>{this.props.roi.id}</span>                                           
                                </OverlayTrigger> 
                                /
                                <OverlayTrigger placement="top" overlay={(props) => this.props.selectedRecordTimestamp ?
                                                        <Tooltip id="historical-data-tooltip" {...props}>
                                                            <span>ISSUE TIMESTAMP (ms since 1970-01-01 00:00 UTC)</span>
                                                            <br/>
                                                            <span>{this.props.selectedIssueTimestamp || 'ISSUE_TIMESTAMP'}</span>
                                                        </Tooltip> : <div/>}>
                                                        <span style={this.props.selectedRecordTimestamp ? redStyle : grayedOut}>{this.props.selectedIssueTimestamp || 'ISSUE_TIMESTAMP'}</span>                                         
                                </OverlayTrigger> 
                                /
                                <span style={this.props.selectedRecordTimestamp ? redStyle : grayedOut }>{this.props.selectedParam || "PARAMETER_ID"}</span>/
                                <OverlayTrigger placement="top" overlay={(props) => this.props.selectedRecordTimestamp ?
                                                        <Tooltip id="historical-data-tooltip" {...props}>
                                                            <span>DATA TIMESTAMP (ms since 1970-01-01 00:00 UTC)</span>
                                                            <br/>
                                                            <span>{this.props.selectedRecordTimestamp || 'DATA_TIMESTAMP'}</span>
                                                        </Tooltip> : <div/>}>
                                                        <span style={this.props.selectedRecordTimestamp ? redStyle : grayedOut}>{this.props.selectedRecordTimestamp || 'DATA_TIMESTAMP'}</span>                                         
                                </OverlayTrigger> 
                                </span></a>  
                            </Card>  
                                                                                                                     
                            <Card className="shell-block" style={{padding: defaultPadding * 2, marginTop: 30}}>
                                <div style={{...row, justifyContent: 'space-between'}}>
                                    <div style={{fontWeight: 'bold', marginBottom: 30}}>GRIB JSON Download links</div>
                                    <OverlayTrigger placement="top" overlay={(props) =>
                                    <Tooltip id="historical-data-tooltip" {...props}>
                                        <span>GRIB JSON file download links use a standard structure. You can create and use these links directly without logging in to METNET Spatial first.</span> 
                                        <br/><br/>
                                        <span>For more information, hover your mouse over each of the variables highlighted red in the links below.</span> </Tooltip>}>
                                            <div style={{...row, alignItems: 'center'}}>
                                            <FontAwesomeIcon icon={faQuestionCircle} 
                                                style={{fontSize: 20, marginRight: 5 }} color={mainBlue} />
                                                <a href="javascript:void(0)">Structure of GRIB JSON download links</a>
                                            </div>                                           
                                    </OverlayTrigger>
                                </div>                           
                                {
                                    (this.props.roi.spatialConstraint.projectionType === OutputProjectionType.EQUIRECTANGULAR) 
                                        ?
                                            <>
                                                <div className={this.props.selectedRecordTimestamp ? "" : "disabled-link"} style={{marginTop: defaultMargin * 2}}>
                                                    <div>Download data for this ROI with timestamp {this.props.selectedRecordTimestamp ? <span>{Util.formatTimestamp(this.props.selectedRecordTimestamp)}</span> : <span style={{fontStyle: 'italic'}}>DATA_TIMESTAMP</span>}, parameter {this.props.selectedParam || "PARAMETER_ID"} issued at {this.props.selectedIssueTimestamp ? <span>{Util.formatTimestamp(this.props.selectedIssueTimestamp)}</span> : <span style={{fontStyle: 'italic'}}>ISSUE_TIMESTAMP</span>} :</div>
                                                </div>
                                            <a className={this.props.selectedRecordTimestamp ? "" : "disabled-link"} href={"javascript:void(0);"}  key={"downloadLink"} onClick={() => {
                                                this.setState({
                                                    showDownloadGribModal: true
                                                })
                                            }}><span>{baseUrl}/download/data/        
                                                <OverlayTrigger placement="top" overlay={(props) => this.props.selectedRecordTimestamp ?
                                                                        <Tooltip id="historical-data-tooltip" {...props}>
                                                                            <span>ROI ID</span>
                                                                            <br/>
                                                                            <span>{this.props.roi.id}</span>
                                                                        </Tooltip> : <div/>}>
                                                                        <span style={this.props.selectedRecordTimestamp ? redStyle : grayedOut}>{this.props.roi.id}</span>                                           
                                                </OverlayTrigger> 
                                                /
                                                <OverlayTrigger placement="top" overlay={(props) => this.props.selectedRecordTimestamp ?
                                                                        <Tooltip id="historical-data-tooltip" {...props}>
                                                                            <span>ISSUE TIMESTAMP (ms since 1970-01-01 00:00 UTC)</span>
                                                                            <br/>
                                                                            <span>{this.props.selectedIssueTimestamp || 'ISSUE_TIMESTAMP'}</span>
                                                                        </Tooltip> : <div/>}>
                                                                        <span style={this.props.selectedRecordTimestamp ? redStyle : grayedOut}>{this.props.selectedIssueTimestamp || 'ISSUE_TIMESTAMP'}</span>
                                                </OverlayTrigger> 
                                                /
                                                <span style={this.props.selectedRecordTimestamp ? redStyle : grayedOut }>{this.props.selectedParam || "PARAMETER_ID"}</span>/
                                                <OverlayTrigger placement="top" overlay={(props) => this.props.selectedRecordTimestamp ?
                                                    <Tooltip id="historical-data-tooltip" {...props}>
                                                        <span>DATA TIMESTAMP (ms since 1970-01-01 00:00 UTC)</span>
                                                        <br/>
                                                        <span>{this.props.selectedRecordTimestamp || 'DATA_TIMESTAMP'}</span>
                                                    </Tooltip> : <div/>}>
                                                    <span style={this.props.selectedRecordTimestamp ? redStyle : grayedOut}>{this.props.selectedRecordTimestamp || 'DATA_TIMESTAMP'}</span>
                                                </OverlayTrigger> 
                                            </span>?format=gribjson</a>  
                                        </> 
                                            :
                                        <div>
                                            GRIB JSON downloads are only supported for Equirectangular output projections
                                        </div>
                                }
                            </Card>  
                                                                                                                                                                 
                </div>        
                <DownloadGribModal
                    visible={this.state.showDownloadGribModal} 
                    onModalClose={() => this.setState({showDownloadGribModal: false})} 
                    dataProvider={this.props.dataProvider}
                    downloadLink={dataDownloadUrl}
                />
            </div>
        );
    }
}