import * as React from "react";
import {  defaultMargin, row, shellDarkGray } from '../../styles/styles'
import { DataProvider, RequestStatus } from "../../data/DataProvider";
import { Roi } from "../../../../lambda/interfaces/rois";
import { Modal, Spinner, Button } from "react-bootstrap";

interface Props
{
    visible: boolean;
    dataProvider: DataProvider;
    roi: Roi;
    onModalClose: () => void;
    user: string;
}

export class RevokAccessModal extends React.Component<Props, {}>
{
    constructor(props: Props)
    {
        super(props);
    }

    public render(): JSX.Element
    {
        if(this.props.dataProvider.getModifyRoiAccessStatus() == RequestStatus.SUCCESS){
            this.props.dataProvider.completeModifyRoiAccessAction();
            this.props.onModalClose();
        }

        const title = "Revoke access to ROI";
        const actionDescription = `This action will revoke access from user ${this.props.user} to ROI ${this.props.roi.id} (${this.props.roi.name}).`;
      

        return (
            <Modal show={this.props.visible}  backdrop="static">
                <Modal.Header >
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {
                            this.props.dataProvider.getModifyRoiAccessStatus() == RequestStatus.NOT_STARTED &&
                            <div>
                                <p>{actionDescription}</p>
                                <p>Are you sure you want to revoke access to this ROI?</p>
                            </div>                               
                        }   
                        {
                            this.props.dataProvider.getModifyRoiAccessStatus() == RequestStatus.ERROR &&
                                <p>There was an error requesting access.</p>
                        }                    
                        {
                            this.props.dataProvider.getModifyRoiAccessStatus() == RequestStatus.IN_PROGRESS &&
                            <div style={{...row, alignItems: 'center'}}>
                                 <Spinner animation="border" role="status" color={shellDarkGray}/>
                                <div style={{ marginLeft: defaultMargin}}>ROI revoke request is in progress ...</div>
                            </div>
                        }
                </Modal.Body>
                   <Modal.Footer>
                       {
                           (this.props.dataProvider.getModifyRoiAccessStatus() == RequestStatus.NOT_STARTED || 
                           this.props.dataProvider.getModifyRoiAccessStatus() == RequestStatus.ERROR) &&
                            <Button className="shell-secondary-btn" onClick={() => {
                                this.props.onModalClose();
                                this.props.dataProvider.completeModifyRoiAccessAction();
                            }}>Close</Button>
                       }
                        {
                            this.props.dataProvider.getModifyRoiAccessStatus() == RequestStatus.NOT_STARTED &&
                            <Button className="shell-gray-btn" onClick={() => {                                            
                                this.props.dataProvider.modifyRoiAccess(this.props.roi.id, this.props.user, false);                    
                             }}>Revoke</Button> 
                        }
                    </Modal.Footer>                
            </Modal>     
        );
    }
}


