import * as React from "react";
import { Button, Card } from 'react-bootstrap';
import { row, defaultPadding, lightGray, largeFontSize, gray } from "../../styles/styles";
import { DataProvider } from "../../data/DataProvider";
import { CreateMapFileModal } from "..";
import { DatasetRecord } from "../../../../lambda/interfaces/rois";

interface State{
    mapFileCreationModalVisible: boolean;
}

interface Props
{
    dsr: DatasetRecord;
    dataProvider: DataProvider;
    dataTimestamp: number;
    issueTimestamp: number;
    roiId: string;
    param: string;
}


export class MapFileConfigurator extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state = {
            mapFileCreationModalVisible: false,
        };
    }

    public render(): JSX.Element
    {
        return (
            <Card className="shell-block" style={{...row, padding: defaultPadding * 2,backgroundColor: lightGray, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <div>
                    <div style={{fontSize: largeFontSize, color: gray, fontWeight: 'bold'}}>Create a .map configuration file</div>
                    <div>The required configuration file for the visualization doesn't exist yet for this data set.</div>
                </div>     
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button className="shell-gray-btn" style={{width: 250}} onClick={() => {
                        this.props.dataProvider.createOrUpdateMapFile(this.props.dsr, this.props.roiId, this.props.issueTimestamp, this.props.param, this.props.dataTimestamp);
                        this.setState({mapFileCreationModalVisible: true});
                    }
                }>Create configuration file</Button>
                </div>
                <CreateMapFileModal 
                    dataProvider={this.props.dataProvider} 
                    visible={this.state.mapFileCreationModalVisible} 
                    onModalClose={() => this.setState({mapFileCreationModalVisible: false})} 
                />                    
            </Card>
        );
    }
}