import { PostRoi, TemporalInterpolationType } from "../../../lambda/interfaces/rois";
import { prettyPrintBytes } from "../../../lambda/interfaces/utils";

export const getDownloadEstimate = (roi: PostRoi): { size: number, unit: string } => {
    // number of points:
    const nXpoints = Math.ceil((roi.spatialConstraint.maxX - roi.spatialConstraint.minX) / roi.spatialConstraint.gridResolution);
    const nYpoints = Math.ceil((roi.spatialConstraint.maxY - roi.spatialConstraint.minY) / roi.spatialConstraint.gridResolution);
    const nPoints = nXpoints * nYpoints;

    // number of params:
    let nParams = 0;
    let dsId = "other";
    let dsSubsetId: string | undefined = "none";
    roi.dataSources.forEach(ds => {
        nParams += ds.parameterIds.length;
        dsId = ds.dataSourceId.toLowerCase();
        dsSubsetId = ds.subsetId;
    });

    // TODO refactor. Why does it depend on the data source selected? If it must then lets include enough information in the
    // DataSource JSON.
    let nFperDay = 0;
    let nHorizons = 1;
    switch (dsId) {
        case "gfs":
            nFperDay = 4;
            // number of horizons per forecast (16 days worth):
            // +1 for the first forecast at time 0
            nHorizons = 24 / roi.temporalConstraint.interval * 16 + 1;
            break;
        case "era5":
        case "himawari":
        case "acspo-sst-ahi":
        case "acspo-sst-ahi-l2":
            nFperDay = roi.temporalInterpolation === TemporalInterpolationType.NONE ? 24 : 24 / roi.temporalConstraint.interval;
            break;
        case "roms":
            nFperDay = roi.temporalInterpolation === TemporalInterpolationType.NONE ? 24 : 24 / roi.temporalConstraint.interval;
            break;
        case "modis":
            nFperDay = 1;
            nHorizons = roi.temporalInterpolation === TemporalInterpolationType.NONE ? 1 : 24 / roi.temporalConstraint.interval;
            break;
        case "global-ocean":
            if (roi.temporalInterpolation === TemporalInterpolationType.NONE) {
                if (dsSubsetId && dsSubsetId === 'daily') {
                    nFperDay = 1;
                }
                else {
                    nFperDay = 24;
                }
            }
            else {
                nFperDay = 24 / roi.temporalConstraint.interval;
            }
            if (dsSubsetId && dsSubsetId === 'smoc') {
                nFperDay = roi.temporalInterpolation === TemporalInterpolationType.NONE ? 1 : 24 / roi.temporalConstraint.interval;
            }
            else {
                nFperDay = roi.temporalInterpolation === TemporalInterpolationType.NONE ? 8 : 24 / roi.temporalConstraint.interval * 8;
            }
            break;
        case "sentinel":
        case "sentinel-ocn":
        case "sentinel-s3":
        case "sentinel-s2":
            nFperDay = 0.3; // once every three days
            break;
    }


    const nBytesPerPoint = 4;
    const fileHeaderSizeEstimate = 4400;
    const estimateData = (nPoints * nBytesPerPoint + fileHeaderSizeEstimate) * nHorizons * nFperDay * nParams;
    const estimateMerged = (nPoints * nHorizons * nParams * nBytesPerPoint + fileHeaderSizeEstimate) * nFperDay;

    const prettySize = prettyPrintBytes(estimateData + estimateMerged); 

    let divisor: number;
    if (prettySize.size < 10) {
        divisor = 5;
    }
    else if (prettySize.size < 100) {
        divisor = 50;
    }
    else if (prettySize.size < 1000) {
        divisor = 500;
    }
    else {
        divisor = 5000;
    }
    prettySize.size = Math.ceil(prettySize.size / divisor) * divisor;
    return prettySize;
}
