import * as React from "react";
import {  defaultMargin, row } from '../../styles/styles'
import { DataProvider, RequestStatus } from "../../data/DataProvider";
import { Util } from "../../util/Util";
import { RoiPreview } from "../../screens/NewRoi";
import { Roi, SpatialConstraint } from "../../../../lambda/interfaces/rois";
import { Modal, Spinner, Button } from "react-bootstrap";
import { Redirect} from 'react-router-dom'
import { IntersectionMap } from "../maps/IntersectionMap";
import { DataSource, IntersectionType } from "../../../../lambda/interfaces/sources";

import {mainBlue, shellRed} from "../../styles/styles";

interface Props
{
    visible: boolean;
    onModalClose: () => void;
    roiSpatialConst: SpatialConstraint;
    dataSource: DataSource;
    intersectionName: string;
}

export class MapIntersectionModal extends React.Component<Props, {}>
{
    constructor(props: Props)
    {
        super(props);
    }

    public render(): JSX.Element
    {

      
        return (
            <Modal show={this.props.visible}  backdrop="static" dialogClassName="map-modal-dialog">
                <Modal.Header >
                <Modal.Title>Intersection: {this.props.intersectionName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{color: mainBlue}}><span style={{fontWeight: 'bold'}}>BLUE </span> - data source coverage</div>
                    <div style={{color: shellRed}}><span style={{fontWeight: 'bold'}}>RED </span> - selected ROI</div>
                    <div style={{width: '100%', height: 400, marginTop: defaultMargin * 2 }}>
                        <IntersectionMap roiSpatialConst={this.props.roiSpatialConst} dsSpatialConst={this.props.dataSource.spatialConstraint} modalVisible={this.props.visible}/>
                    </div> 
                        
                </Modal.Body>
                   <Modal.Footer>
                    <Button className="shell-secondary-btn" onClick={() => {
                        this.props.onModalClose(); 
                    }}>Close</Button>                       
                    </Modal.Footer>                
            </Modal>     
        );
    }
}


