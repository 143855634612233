import * as React from "react";
import * as ReactDOM from "react-dom";
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import Feature from 'ol/Feature';
import {applyTransform} from 'ol/extent';
import TileLayer from 'ol/layer/Tile';
import Draw, {createRegularPolygon, createBox, DrawEvent} from 'ol/interaction/Draw';
import {get as getProjection, getTransform} from 'ol/proj';
import {register} from 'ol/proj/proj4';
import OSM from 'ol/source/OSM';
import proj4 from 'proj4';
import { Vector as VectorSource, GeoJSON} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer';
import { OutputProjectionType } from "../../../../lambda/interfaces/rois";
import {defaults as defaultControls, Control} from 'ol/control';
import { Point, LinearRing, Polygon} from 'ol/geom';
import { Util } from "../../util/Util";

var source = new VectorSource({wrapX: false});

var raster = new TileLayer({
  source: new OSM()
});

var vector = new VectorLayer({
  source: source
});

function formatNumber(num: number): number{
  return parseFloat(Util.formatNumber(num));
}

  interface Props {
    projectionType: OutputProjectionType | undefined;
    utmProjectionStringIdentifier: string;
    //to update the input values when rect is drawn
    onRectDrawn: (minX: number, minY: number, maxX: number, maxY: number) => void;
    minX: number | undefined;
    minY: number | undefined;
    maxX: number | undefined;
    maxY: number | undefined;
  }

  interface State {
      map: Map ;
      mapDiv: any;
      utmProjectionStringIdentifier: string;
      projectionString: string;
      projectionType: OutputProjectionType | undefined;
      drawObj: Draw;
      minX: number | undefined;
      minY: number | undefined;
      maxX: number | undefined;
      maxY: number | undefined;
  }

const defaultProjection = 'EPSG:3857';
const equirectangularProj = 'EPSG:4326';

export class ProjectionMap extends React.Component<Props,State> {
  constructor(props) {
    super(props);

    let projectionMapComponent = this;

    //create the Clear map  button
    var ClearMapButton = /*@__PURE__*/(function (Control) {
      function ClearMapButton(opt_options?) {
          var options = opt_options || {};
    
          var button = document.createElement('button');
          button.innerHTML = 'Clear map';
          button.style.width = '200px';
          button.style.height = '30px';
          button.style.margin = '10px';
    
          var element = document.createElement('div');
          element.className = 'ol-control ol-unselectable';
          element.appendChild(button);
    
          Control.call(this, {
            element: element,
            target: options.target
          });
    
          button.addEventListener('click', () => {
            source.clear();         
            map.addInteraction(draw);
            projectionMapComponent.setState({minX: undefined, maxX: undefined, minY: undefined, maxY: undefined});
            props.onRectDrawn(undefined, undefined, undefined, undefined);
            
          }, false);
        }
    
        if ( Control ) ClearMapButton.__proto__ = Control;
        ClearMapButton.prototype = Object.create( Control && Control.prototype );
        ClearMapButton.prototype.constructor = ClearMapButton;
    
        return ClearMapButton;
      }(Control));
    
    //create the map
    var map: Map = new Map({
        layers: [raster, vector],
        target: 'map',
        controls: defaultControls().extend([
          new ClearMapButton()
        ]),
        view: new View({
          projection: defaultProjection,
          center: [0, 0],
          zoom: 1
        })
      }); 

      //react drawing feature
      let draw = new Draw({
        source: source,
        type: 'Circle',
        geometryFunction: createBox(),
      });

      let onRectDrawn = this.props.onRectDrawn;
      
      draw.on('drawend', function (e) {
        //the user can only draw a single rect, so drawing interaction needs to be removed after
        map.removeInteraction(draw);          
        if( e && e.feature && e.feature.values_ && e.feature.values_.geometry && e.feature.values_.geometry.extent_ && e.feature.values_.geometry.extent_.length == 4){
          let values = e.feature.values_.geometry.extent_;
          onRectDrawn(formatNumber(values[0]), formatNumber(values[1]), formatNumber(values[2]), formatNumber(values[3]));
        }
        });
    
    this.state={
        map: map,
        mapDiv: undefined,
        utmProjectionStringIdentifier: this.props.utmProjectionStringIdentifier,
        projectionString: defaultProjection,
        projectionType: undefined,
        drawObj: draw,
        minX: this.props.minX,
        maxX: this.props.maxX,
        minY: this.props.minY,
        maxY: this.props.maxY,
    }
  }

  static setEquiRectProjection(map: Map, drawObj: Draw){
        var newView = new View({
          projection: equirectangularProj,
          center: [0, 0],
          zoom: 2
          });      
        let modifiedMap = map;
        modifiedMap.setView(newView);  
        //enable rect drawing
        modifiedMap.addInteraction(drawObj);    

        return{
            map: modifiedMap,
            projectionString: equirectangularProj,
            projectionType: OutputProjectionType.EQUIRECTANGULAR,
            utmProjectionStringIdentifier: '',
        }
  }

  static setUTMProjection(identifier: string, map: Map, drawObj: Draw, prevProjString: string){
    fetch('https://epsg.io/?format=json&q=' + identifier).then(function(response) {
      return response.json();
      }).then(function(json) {
      var results = json['results'];
      if (results && results.length > 0) {
          var result = results[results.length - 1];
          if (result) {

              var code = result['code'];
              var proj4def = result['proj4'];
              var bbox = result['bbox'];
              
              if (code && code.length > 0 && proj4def && proj4def.length > 0 &&
                  bbox && bbox.length == 4) {                   

                  var newProjCode = 'EPSG:' + code;
                  proj4.defs(newProjCode, proj4def);
                  register(proj4);
                  var newProj = getProjection(newProjCode);
                  var fromLonLat = getTransform('EPSG:4326', newProj);
              
                  // very approximate calculation of projection extent
                  var extent = applyTransform(
                  [bbox[1], bbox[2], bbox[3], bbox[0]], fromLonLat);
                  newProj.setExtent(extent);
                  var newView = new View({
                    projection: newProj
                  });
                  
                  let modifiedMap = map;
                  modifiedMap.setView(newView);                 
                  newView.fit(extent);       
                  //enable rect drawing
                  modifiedMap.addInteraction(drawObj);         

                  return{
                      map: modifiedMap,
                      projectionString: newProjCode,
                      projectionType: OutputProjectionType.UTM,
                      utmProjectionStringIdentifier: identifier,
                  }
              }
          }        
      }
      });
      return{
        map: map,
        projectionString: prevProjString,
        projectionType: OutputProjectionType.UTM,
        utmProjectionStringIdentifier: identifier,
    }
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if(nextProps.projectionType !== prevState.projectionType){
        //clear the previous rect when projection type changes
        source.clear();
        if(nextProps.projectionType == OutputProjectionType.EQUIRECTANGULAR){
            return ProjectionMap.setEquiRectProjection(prevState.map, prevState.drawObj);
        }else{
          //case of UTM is selected
          //disbale rect drawing
          let modifiedMap = prevState.map;
          modifiedMap.removeInteraction(prevState.drawObj);   
          return {
            projectionType: OutputProjectionType.UTM,
            map: modifiedMap,
          }
        }           
    }

    if(nextProps.utmProjectionStringIdentifier !== prevState.utmProjectionStringIdentifier){
      //clear the previous rect when utm projection changes(zone/facing)
      source.clear();
      return ProjectionMap.setUTMProjection(nextProps.utmProjectionStringIdentifier, prevState.map, prevState.drawObj, prevState.projectionString);
    }


    //when user changed the input values, the drawn rect must be redrawn
    if((nextProps.minX !== prevState.minX || nextProps.maxX !== prevState.maxX || nextProps.minY !== prevState.minY || nextProps.maxY !== prevState.maxY ) &&
        (nextProps.minX && nextProps.maxX && nextProps.minY && nextProps.maxY)
      ){        
      var features = vector.getSource().getFeatures();
      if(features.length > 0){
        ProjectionMap.updateCoordinates(features[0], nextProps.minX, nextProps.maxX, nextProps.minY, nextProps.maxY);  
        return{
          minX: nextProps.minX,
          maxX: nextProps.maxX,
          minY: nextProps.minY,
          maxY: nextProps.maxY,
        }
      }       
    }

    //the below sections checks if the inputs were manually changed and a new rect needs to be drawn
    if(nextProps.minX !== prevState.minX){
      if(prevState.minX === undefined && prevState.maxX && prevState.minY && prevState.maxY){
        ProjectionMap.createRectPolygon( nextProps.minX, nextProps.maxX, nextProps.minY, nextProps.maxY);
        let newMap: Map = prevState.map;
        newMap.removeInteraction(prevState.drawObj); 
      }
      return{
        minX: nextProps.minX
      }
    }
    if(nextProps.maxX !== prevState.maxX){
      if(prevState.maxX === undefined && prevState.minX && prevState.minY && prevState.maxY){
        ProjectionMap.createRectPolygon( nextProps.minX, nextProps.maxX, nextProps.minY, nextProps.maxY);
        let newMap: Map = prevState.map;
        newMap.removeInteraction(prevState.drawObj); 
      }
      return{
        maxX: nextProps.maxX
      }
    }
    if(nextProps.minY !== prevState.minY){
      if(prevState.minY === undefined && prevState.minX && prevState.maxX && prevState.maxY){
        ProjectionMap.createRectPolygon( nextProps.minX, nextProps.maxX, nextProps.minY, nextProps.maxY);
        let newMap: Map = prevState.map;
        newMap.removeInteraction(prevState.drawObj); 
      }
      return{
        minY: nextProps.minY
      }
    }
    if(nextProps.maxY !== prevState.maxY){
      if(prevState.maxY === undefined && prevState.minX && prevState.maxX && prevState.minY){
        ProjectionMap.createRectPolygon( nextProps.minX, nextProps.maxX, nextProps.minY, nextProps.maxY);
        let newMap: Map = prevState.map;
        newMap.removeInteraction(prevState.drawObj); 
      }
      return{
        maxY: nextProps.maxY
      }
    }

    if((nextProps.minX !== prevState.minX && nextProps.maxX !== prevState.maxX && nextProps.minY !== prevState.minY && nextProps.maxY !== prevState.maxY ) &&
    (prevState.minX === undefined && prevState.maxX === undefined && prevState.minY === undefined && prevState.maxY === undefined)){
        return{
          minX: nextProps.minX,
          maxX: nextProps.maxX,
          minY: nextProps.minY,
          maxY: nextProps.maxY,
        }
    }

    return null;
  }

  //updates the coordinates of the drawn rect(when user edits the input values)
  static updateCoordinates(featureToUpdate, minX: number, maxX: number, minY: number, maxY: number) { 
      var allCoords = featureToUpdate.getGeometry().getCoordinates();
      var coord = allCoords[0];
      coord[0][0] = parseFloat(minX.toString());
      coord[0][1] = parseFloat(minY.toString());

      coord[1][0] = parseFloat(maxX.toString());
      coord[1][1] = parseFloat(minY.toString());

      coord[2][0] = parseFloat(maxX.toString());
      coord[2][1] = parseFloat(maxY.toString());

      coord[3][0] = parseFloat(minX.toString());
      coord[3][1] = parseFloat(maxY.toString());

      coord[4][0] = parseFloat(minX.toString());
      coord[4][1] = parseFloat(minY.toString());

      featureToUpdate.getGeometry().setCoordinates([coord]);
  }

  static createRectPolygon(minX: number, maxX: number, minY: number, maxY: number,){
    var ring = [[minX,minY],[maxX,minY],[maxX,maxY],[minX, maxY],[minX,minY]];
    var polygon = new Polygon([ring]);  
    var feature = new Feature(polygon);
    source.addFeature(feature);
    }

  render(){

    return (
        <div id="map" style={{width: '100%', height: '100%'}} className="map" ref={(el)=> this.state.map.setTarget(el)}></div>
    );
  }
}