import { SpatialConstraint, Facing, OutputProjectionType } from "../../../lambda/interfaces/rois";
import { IntersectionType } from "../../../lambda/interfaces/sources";

interface LonLat {
    longitude: number,
    latitude: number,
}

export class GeoUtil{

    public static convertXCoordinateToBeInRange(x: number): number{
        while(x > 180 || x < -180){
            if(x > 180){
                x = x - 360;
            }
            if(x < -180){
                x = x + 360;
            }
        }
        return x;
    }

    public static getIntersectionForROIAndDs(roiSpatial: SpatialConstraint, dsSpacial: SpatialConstraint): IntersectionType{

        let roiSpatialConverted: SpatialConstraint;
        if(roiSpatial.projectionType === OutputProjectionType.UTM){
            const minlatLong = GeoUtil.utmToLatLon(roiSpatial.minX, roiSpatial.minY, roiSpatial.zone, roiSpatial.facing);
            const maxlatLong = GeoUtil.utmToLatLon(roiSpatial.maxX, roiSpatial.maxY, roiSpatial.zone, roiSpatial.facing);
            roiSpatialConverted = {
                minX: minlatLong.longitude,
                maxX: maxlatLong.longitude,
                minY: minlatLong.latitude,
                maxY: maxlatLong.latitude,
                gridResolution: roiSpatial.gridResolution,
                projectionType: roiSpatial.projectionType,
                facing: roiSpatial.facing,
                zone: roiSpatial.zone,
            }

        }else{
            roiSpatialConverted = roiSpatial;
        }

        if(dsSpacial.maxX - dsSpacial.minX === 360){
            dsSpacial.minX = dsSpacial.minX - 360 * 10; // add 10 "pages" to the left and right, so rects drawn on the date line should be fine
            dsSpacial.maxX = dsSpacial.maxX + 360 * 10;
          }
        //roiSpatial = this.convertXCoordinatesToBeInRange(roiSpatial);
        if(roiSpatialConverted.minX >= dsSpacial.minX && roiSpatialConverted.minX <= dsSpacial.maxX &&
            roiSpatialConverted.maxX >= dsSpacial.minX && roiSpatialConverted.maxX <= dsSpacial.maxX &&
            roiSpatialConverted.minY >= dsSpacial.minY && roiSpatialConverted.minY <= dsSpacial.maxY &&
            roiSpatialConverted.maxY >= dsSpacial.minY && roiSpatialConverted.maxY <= dsSpacial.maxY){
                //roi's rect is inside ds's rect
                return IntersectionType.FULL;
        }else{

            //top left corner is inside
            if ((roiSpatialConverted.minX >= dsSpacial.minX && roiSpatialConverted.minX <= dsSpacial.maxX && roiSpatialConverted.maxY >= dsSpacial.minY && roiSpatialConverted.maxY <= dsSpacial.maxY) || 
            //bottom right corner is inside
            ( roiSpatialConverted.maxX >= dsSpacial.minX && roiSpatialConverted.maxX <= dsSpacial.maxX && roiSpatialConverted.minY >= dsSpacial.minY && roiSpatialConverted.minY <= dsSpacial.maxY) || 
            //top right corner is inside
            (roiSpatialConverted.maxX >= dsSpacial.minX && roiSpatialConverted.maxX <= dsSpacial.maxX && roiSpatialConverted.maxY >= dsSpacial.minY && roiSpatialConverted.maxY <= dsSpacial.maxY) ||
            //bottom left corner is inside
            (roiSpatialConverted.minX >=dsSpacial.minX && roiSpatialConverted.minX <= dsSpacial.maxX && roiSpatialConverted.minY >= dsSpacial.minY && roiSpatialConverted.minY <= dsSpacial.maxY) ||

            //all of these inverted
            (dsSpacial.minX >= roiSpatialConverted.minX && dsSpacial.minX <= roiSpatialConverted.maxX && dsSpacial.maxY >= roiSpatialConverted.minY && dsSpacial.maxY <= roiSpatialConverted.maxY) || 
            ( dsSpacial.maxX >= roiSpatialConverted.minX && dsSpacial.maxX <= roiSpatialConverted.maxX && dsSpacial.minY >= roiSpatialConverted.minY && dsSpacial.minY <= roiSpatialConverted.maxY) || 
            (dsSpacial.maxX >= roiSpatialConverted.minX && dsSpacial.maxX <= roiSpatialConverted.maxX && dsSpacial.maxY >= roiSpatialConverted.minY && dsSpacial.maxY <= roiSpatialConverted.maxY) ||
            (dsSpacial.minX >=roiSpatialConverted.minX && dsSpacial.minX <= roiSpatialConverted.maxX && dsSpacial.minY >= roiSpatialConverted.minY && dsSpacial.minY <= roiSpatialConverted.maxY) ||

            //they have common area in the middle
            (roiSpatialConverted.minX >= dsSpacial.minX && roiSpatialConverted.minX <= dsSpacial.maxX && roiSpatialConverted.maxX >= dsSpacial.minX && roiSpatialConverted.maxX <= dsSpacial.maxX &&
                dsSpacial.minY >= roiSpatialConverted.minY && dsSpacial.minY <= roiSpatialConverted.maxY && dsSpacial.maxY >= roiSpatialConverted.minY && dsSpacial.maxY <= roiSpatialConverted.maxY) ||

            (dsSpacial.minX >= roiSpatialConverted.minX && dsSpacial.minX <= roiSpatialConverted.maxX && dsSpacial.maxX >= roiSpatialConverted.minX && dsSpacial.maxX <= roiSpatialConverted.maxX &&
                roiSpatialConverted.minY >= dsSpacial.minY && roiSpatialConverted.minY <= dsSpacial.maxY && roiSpatialConverted.maxY >= dsSpacial.minY && roiSpatialConverted.maxY <= dsSpacial.maxY)
            ){

                //roi is out of the ds's rect
                return IntersectionType.PARTIAL;
            }else{
                return IntersectionType.NONE;
            }
        }
    }
    
    // E and N will be sent in m, convert to km
    // Method adapted from https://en.wikipedia.org/wiki/Universal_Transverse_Mercator_coordinate_system
    public static utmToLatLon = (Em: number, Nm: number, Zone: number, facing: Facing): LonLat => {
        const E = Em / 1000;
        const N = Nm / 1000;
        const N0 = facing === Facing.N ? 0 : 10000; // km
    
        const k0 = 0.9996;
        const E0 = 500; //km
    
        const a = 6378.137; //km
        const f = 1 / 298.257223563;
    
        const n = f / (2 - f);
        const nn = n * n;
        const nnn = nn * n;
        const nnnn = nnn * n;
        const A = (a / (1 + n)) * (1 + nn / 4 + nnnn / 64);
    
        const beta = [
            0.5 * n - 2/3 * nn + 37/96 * nnn,
            1/48 * nn + 1/15 * nnn,
            17/480 * nnn
        ];
    
        const delta = [
            2 * n - 2/3 * nn - 2 * nnn,
            7/3 * nn - 8/5 * nnn,
            56/15 * nnn
        ]
    
        const xi = (N - N0) / (k0 * A);
        const eta = (E - E0) / (k0 * A);
    
        let xi_p = xi;
        let eta_p = eta;
        let sigma_p = 1;
        let tau_p = 0;
    
        for (let j = 1; j <= 3; j++) {
            xi_p -= beta[j-1] * Math.sin(2 * j * xi) * Math.cosh(2 * j * eta);
            eta_p -= beta[j-1] * Math.cos(2 * j * xi) * Math.sinh(2 * j * eta);
            sigma_p -= 2 * j * beta[j-1] * Math.cos(2 * j * xi) * Math.cosh(2 * j * eta);
            tau_p += 2 * j * beta[j-1] * Math.sin(2 * j * xi) * Math.sinh(2 * j * eta);
        }
    
        const chi = Math.asin(Math.sin(xi_p) / Math.cosh(eta_p));
    
        let phi = chi;
        for (let j = 1; j <= 3; j++) {
            phi += delta[j-1] * Math.sin(2 * j * chi);
        }
    
        const lambda0 = GeoUtil.degToRad(Zone * 6 - 183);
        const lambda = lambda0 + Math.atan(Math.sinh(eta_p) / Math.cos(xi_p));

        const latitude = GeoUtil.degToPlusMinus180(GeoUtil.radToDeg(phi));
        const longitude = GeoUtil.degToPlusMinus180(GeoUtil.radToDeg(lambda));
        return {
            latitude,
            longitude
        }
    }
    
    static radToDeg = (rad: number): number => rad * 180 / Math.PI;
    static degToRad = (deg: number): number => deg * Math.PI / 180;
    static degToPlusMinus180 = (deg: number): number => {
        if (deg <= -180) return deg + 360;
        if (deg > 180) return deg - 360;
        return deg;
    }


    /*public static convertXCoordinatesToBeInRange(spatialC: SpatialConstraint): SpatialConstraint{
        let minX = spatialC.minX;
        let maxX = spatialC.maxX;
        if(minX < -180){
            while(minX < -180){
                minX += 360;
            }
        }
        if(minX > 180){
            while(minX > 180){
                minX -= 360;
            }
        }
        if(maxX < -180){
            while(maxX < -180){
                maxX += 360;
            }
        }
        if(maxX > 180){
            while(maxX > 180){
                maxX -= 360;
            }
        }
        return {...spatialC, minX: minX, maxX: maxX}
    }*/
}