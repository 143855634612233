import * as React from "react";
import { defaultMargin, transparentDark, fullHeight } from "../styles/styles";
import { Spinner } from "react-bootstrap";

interface Props{
    message: string;
}

export class LoadingIndicator extends React.Component<Props, {}>
{

    public render(): JSX.Element
    {
        return (
            <div style={overlayStyle} id="overlay">              
                    <Spinner animation="border" role="status" variant="light"/>  
        <div style={{color: 'white', marginLeft: defaultMargin}}>{this.props.message}</div>         
            </div>
        );
    }
}

const overlayStyle: React.CSSProperties = {
    zIndex: 5, 
    position: 'absolute', 
    backgroundColor: 'rgba(0, 0, 0, 0.7)', 
    left:0, 
    top: 0, 
    display: 'flex', 
    flexDirection: 'row', 
    width: '100%', 
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
}