import * as React from "react";
import * as ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "core-js/stable";

import { Main } from "./src/screens/Main";
import { DataProvider } from "./src/data/DataProvider";

export class App
{
    private _appName: string = "Shell METNET Spatial";

    constructor()
    {      
        this.render();
    }

    private render(): void
    {      
        ReactDOM.render(React.createElement(Main, /*{ app: this }*/), document.getElementById("shell_metnet_spatial_app"));
    }

    public get appName(): string { return this._appName; }
}

new App();