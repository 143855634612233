import * as React from "react";
import { Redirect } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Form, Button, Modal, Spinner } from 'react-bootstrap';
import {  defaultMargin, row } from '../../styles/styles'
import { DataProvider, RequestStatus } from "../../data/DataProvider";
import { Util } from "../../util/Util";
import { RoiPreview } from "../../screens/NewRoi";
import { Roi } from "../../../../lambda/interfaces/rois";

interface Props
{
    visible: boolean;
    dataValid: boolean;
    dataProvider: DataProvider;
    roiToUpload: RoiPreview | Roi;
    onModalClose: () => void;
}

interface State {
    roiName: string;
}

export class SubmitRoiModal extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state={
            roiName: this.props.roiToUpload.name,
        }
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        if(nextProps.roiToUpload.name !== prevState.roiName && nextProps.roiToUpload.name !== undefined && nextProps.roiToUpload.name !== ''){
            return {
                roiName: nextProps.roiToUpload.name,
              };
        }
        return null;
      }


    public render(): JSX.Element
    {

        if(this.props.dataProvider.isRoiUploaded() == RequestStatus.SUCCESS){
            this.props.dataProvider.completeRoiUpload();
            return <Redirect to='/' />;
        }     
      
        return (
            <Modal show={this.props.visible} backdrop="static">
                <Modal.Header >
                    <Modal.Title>Submit for approval</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {
                            this.props.dataProvider.isRoiUploaded() == RequestStatus.NOT_STARTED && this.props.dataValid && (this.props.roiToUpload.name === '' || this.props.roiToUpload.name === undefined) &&
                            <div>
                                <div>Please enter a name for the ROI</div>
                                <input type="text" className="form-control" onChange={(evt) => this.setState({roiName: evt.target.value})}/>
                                <div>Then click Submit to complete the upload process.</div>
                            </div>                                                              
                        }  
                        {
                            this.props.dataProvider.isRoiUploaded() == RequestStatus.NOT_STARTED && this.props.roiToUpload.name !== '' && this.props.dataValid &&
                                <p>{ 'Your ROI: ' + this.props.roiToUpload.name + ' is ready to be uploaded for approval. Click Submit to complete the upload process.' }</p>
                        }    
                        {
                            this.props.dataProvider.isRoiUploaded() == RequestStatus.NOT_STARTED && !this.props.dataValid &&
                            <p>{ 'Some of the necessary information is missing for the new ROI. Please check the preview for the missing information and complete it.' }</p>
                            
                        }                
                        {
                            this.props.dataProvider.isRoiUploaded() == RequestStatus.IN_PROGRESS &&
                            <div style={{...row, alignItems: 'center'}}>
                                 <Spinner animation="border" role="status" variant="primary"/>
                                <div style={{ marginLeft: defaultMargin}}>ROI data uploading ...</div>
                            </div>
                        }
                        {
                            this.props.dataProvider.isRoiUploaded() == RequestStatus.ERROR &&
                            <div style={{...row, alignItems: 'center'}}>                               
                                <div style={{ marginLeft: defaultMargin}}>There was an error while submitting the data.</div>
                            </div>
                        }
                </Modal.Body>
                {
                   this.props.dataProvider.isRoiUploaded() !== RequestStatus.IN_PROGRESS &&
                   <Modal.Footer>
                        <Button className="shell-secondary-btn" onClick={() => {
                            this.props.onModalClose();
                            this.props.dataProvider.completeRoiUpload();
                            this.setState({roiName: ''});
                            }}>Close</Button>
                        {
                        this.props.dataValid && this.props.dataProvider.isRoiUploaded() == RequestStatus.NOT_STARTED && this.state.roiName !== '' && this.state.roiName !== undefined &&
                        <Button className="shell-gray-btn" onClick={() => {   
                                let finalRoi = this.props.roiToUpload;
                                finalRoi.name = this.state.roiName;                                         
                                this.props.dataProvider.submitRoi(Util.prepareRoiForUpload(finalRoi));
                                                
                        }}>Submit</Button>
                        }
                    </Modal.Footer>
                }
            </Modal>     
        );
    }
}


