import * as React from "react";
import { Card, Button, Modal, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Parameter, DataCollectionDateType } from "../../../../lambda/interfaces/sources";;
import { defaultMargin, defaultPadding, tabPage, fullHeight, darkBlueColor, smallFontSize, row, smallBold, smallFont, lightRed, lightGray, gray, errorColor, blackColor, largeFontSize, lightBlue, mediumFontSize, mainBlue, shellDarkGray, shellYellow } from "../../styles/styles";
import { OutputProjectionType, Roi } from "../../../../lambda/interfaces/rois";;
import { SelectedDataSource } from "../data-source/SelectedDataSource";
import { RoiPreview, TabKeys } from "../../screens/NewRoi";
import { Util } from "../../util/Util";
import { Link } from 'react-router-dom';
import { DataProvider } from "../../data/DataProvider";
import { RegionBox } from "../RegionBox";
import { SubmitRoiModal } from "../modals/SubmitRoiModal";
import { getDownloadEstimate } from "../../data/estimate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faQuestionCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { GeoUtil } from "../../data/GeoUtil";
import { RoiMapModal } from "../modals/RoiMapModal";
import { EditROITemporalConstraintsModal } from "../modals/EditROITemporalConstraintsModal";

export interface Props {
    onParameterRemove?: (paramId: string, dataSourceId: string) => void;
    roiPreview: RoiPreview | Roi;
    showValidation?: boolean;
    updateValidationVisibility?: (v: boolean) => void;
    readonly: boolean;
    dataProvider: DataProvider;
    activeTabKey?: string;
    onNextButtonPressed?: () => void;
    onPreviousButtonPressed?: () => void;
}

export interface State {
    submitModalVisible: boolean;
    roiMapModalVisible: boolean;
    editTemporalConstraintsModalVisible: boolean;
}

export class RoiOverview extends React.Component<Props, State>
{
    constructor(props: Props){
        super(props);
        this.state = {
            submitModalVisible: false,
            roiMapModalVisible: false,
            editTemporalConstraintsModalVisible: false
        }
    }

    public render(): JSX.Element
    {

        const notDefinedString = 'not defined yet';
        const noROINameString  = 'Name of the new ROI';

        const buttonsLayout = this.props.activeTabKey == TabKeys.SPATIAL ? 'flex-end' : 'space-between';

        const spatialConst = this.props.roiPreview.spatialConstraint;
        const temporalConst = {...this.props.roiPreview.temporalConstraint};
        
        const zoneString = spatialConst.zone !== undefined ? ' (' + spatialConst.zone + ')' : '';
        const facingString = spatialConst.facing !== undefined ? ', ' + Util.getFacingString(spatialConst.facing) : ''; 
        const additionalProjectionString = spatialConst.projectionType == OutputProjectionType.EQUIRECTANGULAR ? '' : zoneString + facingString;
        const projection = spatialConst.projectionType === undefined ? notDefinedString : Util.getProjectionName(spatialConst.projectionType) + additionalProjectionString;               
        let regionString = spatialConst.minX !== undefined && spatialConst.maxX !== undefined && spatialConst.minY !== undefined && spatialConst.maxY !== undefined ?
         '(' +spatialConst.minX + ", " + spatialConst.minX + ')  ;  (' + spatialConst.minY + ', ' + spatialConst.maxY + ')' : notDefinedString;

         if(regionString !== notDefinedString && (spatialConst.minX <= -180 || spatialConst.maxX >= 180)){
            regionString = "not valid";
         }
        

        let dateRange = '';
        if(temporalConst.dataCollectionDateType == DataCollectionDateType.FIXED){
           dateRange = temporalConst.firstDate !== undefined && temporalConst.lastDate !== undefined ? Util.formatDate(temporalConst.firstDate) + ' to ' + Util.formatDate(temporalConst.lastDate) : notDefinedString;
        }else{
            dateRange = temporalConst.firstDate !== undefined ? Util.formatDate(temporalConst.firstDate) + ' (ongoing)' : notDefinedString;
        }

        let gr = spatialConst.gridResolution;
        if(spatialConst.projectionType === OutputProjectionType.UTM){
            //convert m to km just for the UI
            gr = Math.round(gr / 100)/10;
        }
        const gridResolution = gr === undefined || gr.toString().length == 0 ? notDefinedString : (gr + " " + Util.getProjectionUnits(spatialConst.projectionType));

        const previewName = this.props.roiPreview.name !== undefined && this.props.roiPreview.name.length > 0 ? this.props.roiPreview.name : noROINameString;
        const nameValid = this.props.roiPreview.name !== undefined && this.props.roiPreview.name.length > 0;

        //validation
        const nameStyle: React.CSSProperties = this.props.showValidation && !nameValid ? {color: lightRed} : previewName !== noROINameString ? {color: shellDarkGray, fontWeight: 'bold'} : {color: shellDarkGray};
        const projectionValid = !(spatialConst.projectionType === undefined || (spatialConst.projectionType == OutputProjectionType.UTM && (spatialConst.zone === undefined || spatialConst.facing === undefined || spatialConst.zone.toString().length == 0))); 
        const regionValid = !((spatialConst.maxX === undefined || spatialConst.maxX.toString().length == 0)|| 
                            (spatialConst.maxY === undefined || spatialConst.maxY.toString().length == 0) ||
                            (spatialConst.minX === undefined || spatialConst.minX.toString().length == 0) ||
                            (spatialConst.minY === undefined || spatialConst.minY.toString().length == 0) || 
                            (spatialConst.maxX !== undefined && spatialConst.maxX > 180 && spatialConst.projectionType === OutputProjectionType.EQUIRECTANGULAR) || 
                            (spatialConst.minX !== undefined && spatialConst.minX < -180 && spatialConst.projectionType === OutputProjectionType.EQUIRECTANGULAR)); 
        const dateRangeValid = dateRange !== notDefinedString;
        const intervalValid = !(temporalConst.interval === undefined || temporalConst.interval.toString().length == 0) || temporalConst.disabled;
        const interpolationValid = !(this.props.roiPreview.temporalInterpolation === undefined || this.props.roiPreview.spatialInterpolation === undefined);
        const parametersValid = this.props.roiPreview.dataSources.length !== 0 ;
        const gridResolutionValid = spatialConst.gridResolution !== undefined && spatialConst.gridResolution.toString().length > 0;

        const allValid = projectionValid && regionValid && dateRangeValid && interpolationValid && intervalValid && parametersValid && gridResolutionValid;

        let spatialInterpolationText = this.props.roiPreview.spatialInterpolation === undefined ? 'None' : Util.getInterpolationName(this.props.roiPreview.spatialInterpolation);
        let temporalInterpolationText = this.props.roiPreview.temporalConstraint.disabled ? "-" : this.props.roiPreview.temporalInterpolation === undefined ? 'None' : Util.getTemporalInterpolationName(this.props.roiPreview.temporalInterpolation);


        const estimate =  getDownloadEstimate(Util.prepareRoiForUpload(this.props.roiPreview));

        return (    
            <div style={{ ...fullHeight}}>
                <Card style={{ minHeight: '100%',display: 'flex',flexDirection: 'column', ...container, color: shellDarkGray}} >
                        <div>
                            <div style={{...row, alignItems: 'center', marginBottom: defaultMargin, justifyContent: 'space-between'}}>
                                {
                                    this.props.readonly ==true &&
                                    <div style={{marginRight: defaultMargin}}>Name:</div>
                                }
                                <div style={{...nameStyle,  fontSize: smallFontSize}}>{previewName}</div>  
                            </div>        
                            <div style={{ alignItems: 'center', marginBottom: defaultMargin}}>                   
                                <div style={{...row, justifyContent: this.props.readonly ? 'space-between' : 'flex-start', marginBottom: defaultMargin, alignItems: 'center'}}>
                                    <div style={this.props.showValidation && !regionValid ? errorColor : blackColor}>Region:</div> 
                                    {
                                       !this.props.readonly &&
                                       <OverlayTrigger placement="bottom" overlay={(props) =>
                                            <Tooltip id={"region-tooltip"} {...props}>The X coordinates must be in the range of [-180, 180].</Tooltip>}>
                                            <FontAwesomeIcon icon={(this.props.showValidation && !regionValid) ?  faExclamationCircle : faQuestionCircle} 
                                                style={{fontSize: 15, marginLeft: 5 }} color={(this.props.showValidation && !regionValid) ? lightRed : shellDarkGray} />
                                        </OverlayTrigger>
                                    }                                 
                                    {
                                        this.props.readonly &&
                                        <a className="simple-link" onClick={() => this.setState({roiMapModalVisible: true})}>Show map</a>
                                    }
                                </div>                                              
                                {
                                    !regionValid &&
                                    <div style={{...smallFont, width: '100%', textAlign: 'right', paddingRight: defaultPadding}}>{regionString}</div>
                                }
                                {
                                    regionValid &&
                                    <RegionBox minX={spatialConst.minX} maxX={spatialConst.maxX} minY={spatialConst.minY} maxY={spatialConst.maxY} />
                                }
                                
                            </div>
                            <div style={{...row, alignItems: 'center', marginBottom: defaultMargin, justifyContent: 'space-between'}}>
                                <div style={this.props.showValidation && !gridResolutionValid ? errorColor : blackColor}>Grid resolution:</div>
                                <div style={gridResolution !== notDefinedString ? smallBold: smallFont}>{gridResolution}</div>
                            </div>
                            <div style={{...row, alignItems: 'center', marginBottom: defaultMargin, justifyContent: 'space-between'}}>
                                <div style={this.props.showValidation && !projectionValid ? errorColor : blackColor}>Projection:</div>
                                <div style={spatialConst.projectionType !== undefined ? smallBold: smallFont}>{projection}</div>
                            </div>
                            <div style={{...row, alignItems: 'center', marginBottom: defaultMargin, justifyContent: 'space-between'}}>
                                <div style={this.props.showValidation && !dateRangeValid ? errorColor : blackColor}>Date range:</div>
                                <div style={dateRange !== notDefinedString ? smallBold: smallFont}>{dateRange}{" "}
                                {
                                    ((this.props.roiPreview as Roi).isStopped !== undefined && !(this.props.roiPreview as Roi).isStopped) && <a className="simple-link" style={{fontWeight: "normal"}} onClick={() => {
                                        this.setState({
                                            editTemporalConstraintsModalVisible: true
                                        });
                                    }}>Edit</a>
                                }</div>
                            </div>
                            <div style={{...row, alignItems: 'center', marginBottom: defaultMargin, justifyContent: 'space-between'}}>
                                <div style={this.props.showValidation && !intervalValid ? errorColor : blackColor}>Interval:</div>
                                <div style={temporalConst.interval !== undefined ? smallBold: smallFont}>{temporalConst.disabled ? "-" :  temporalConst.interval !== undefined ? temporalConst.interval + ' h' : notDefinedString }</div>
                            </div>
                            <div style={{...row, alignItems: 'center', marginBottom: defaultMargin, justifyContent: 'space-between'}}>
                                <div style={this.props.showValidation && !intervalValid ? errorColor : blackColor}>Estimated file size(per day):</div>
                                <div style={smallBold}>{(estimate.size !== undefined && estimate.size.toString() !== 'NaN' ? estimate.size : '-') + ' ' + estimate.unit}</div>
                            </div>
                            <div style={{ marginBottom: defaultMargin}}>
                                <div style={this.props.showValidation && !interpolationValid ? errorColor : blackColor}>Interpolation:</div>
                                {
                                    this.props.roiPreview.temporalInterpolation === undefined && this.props.roiPreview.spatialInterpolation === undefined &&
                                    <div style={{ ...smallFont, width: '100%', textAlign: 'right',  paddingRight: defaultPadding}}>{notDefinedString}</div>
                                }
                                {
                                this.props.roiPreview.spatialInterpolation !== undefined &&
                                <div style={{...row, marginLeft: defaultMargin, alignItems:'center', justifyContent: 'space-between'}}>
                                <div style={{fontSize: smallFontSize}}>Spatial interpolation:</div>
                                <div style={{marginLeft: defaultMargin, ...smallBold}}>{spatialInterpolationText}</div>
                            </div>
                                }
                                {
                                this.props.roiPreview.temporalInterpolation !== undefined &&
                                    <div style={{...row, marginLeft: defaultMargin,  alignItems:'center', justifyContent: 'space-between'}}>
                                        <div style={{fontSize: smallFontSize}}>Temporal interpolation:</div>
                                        <div style={{marginLeft: defaultMargin, ...smallBold}}>{temporalInterpolationText}</div>
                                    </div>
                                    }                    
                            </div>
                            <div style={this.props.showValidation && !parametersValid ? errorColor : blackColor}>Selected parameters:</div> 
                            <div style={{marginBottom: defaultMargin}}/>
                            {
                                this.props.roiPreview.dataSources.length == 0 &&
                                <div style={{ fontSize: smallFontSize, marginLeft: defaultMargin, width: '100%', textAlign: 'right',  paddingRight: defaultPadding}}>no selected parameter yet</div>
                            }
                            {
                            this.props.roiPreview.dataSources.map((ds, i) =>
                                    <SelectedDataSource 
                                        dataProvider={this.props.dataProvider}
                                        dataSourceReference={ds} 
                                        key={ds.dataSourceId}  
                                        onParameterRemove={(p)=> this.props.onParameterRemove(p,ds.dataSourceId)} 
                                        readonly={this.props.readonly}
                                        />
                                    )
                            }      
                        </div>
                        {
                            this.props.readonly == false &&
                            <div style={{width: '100%', ...row, justifyContent: buttonsLayout}}>
                                {
                                    this.props.activeTabKey !== undefined && this.props.activeTabKey !== TabKeys.SPATIAL &&                                  
                                        <Button style={{width: 100}} className="shell-secondary-btn" onClick={() => this.props.onPreviousButtonPressed()}>Previous</Button>
                                }
                                {
                                    this.props.activeTabKey !== undefined && this.props.activeTabKey !== TabKeys.TEMPORAL &&
                                        <Button style={{width: 100}} className="shell-gray-btn" onClick={() => this.props.onNextButtonPressed()}>Next</Button>
                                }
                                {
                                    this.props.activeTabKey !== undefined && this.props.activeTabKey === TabKeys.TEMPORAL &&
                                    <Button 
                                        className="shell-gray-btn"                             
                                        onClick={() => {
                                            this.setState({submitModalVisible: true});
                                            this.props.updateValidationVisibility(true);
                                        }}
                                    >
                                        Submit for approval
                                    </Button>
                                }
                            </div>            
                        }                                                  
                    </Card>
                    <SubmitRoiModal 
                        visible={this.state.submitModalVisible} 
                        roiToUpload={this.props.roiPreview} 
                        dataProvider={this.props.dataProvider} 
                        dataValid={allValid}
                        onModalClose={() => this.setState({submitModalVisible: false})}
                    />
                    <RoiMapModal 
                        onModalClose={() => this.setState({roiMapModalVisible: false})} 
                        visible={this.state.roiMapModalVisible} 
                        roiSpatialConst={this.props.roiPreview.spatialConstraint} 
                    />
                    <EditROITemporalConstraintsModal 
                        onModalClose={() => this.setState({editTemporalConstraintsModalVisible: false})} 
                        visible={this.state.editTemporalConstraintsModalVisible}
                        roi={this.props.roiPreview as Roi}//will definitely be Roi and not RoiPreview here
                        dataProvider={this.props.dataProvider}
                    />
            </div>              
        );
    }
}

const container: React.CSSProperties = {
    marginLeft: defaultMargin,
    marginBottom: defaultMargin,
    padding: defaultPadding,
    justifyContent: 'space-between', 
    backgroundColor: lightGray, 
    borderColor: 'transparent',
    overflowY: 'auto',
    borderBottomWidth: 5,
    borderBottomColor: shellYellow,
}