import * as React from "react";
import {  defaultMargin, row } from '../../styles/styles'
import { DataProvider, RequestStatus } from "../../data/DataProvider";
import { Roi } from "../../../../lambda/interfaces/rois";
import { Modal, Spinner, Button } from "react-bootstrap";

interface Props
{
    visible: boolean;
    dataProvider: DataProvider;
    roiToStop: Roi;
    onModalClose: () => void;
}

interface State 
{
    status: RequestStatus;
}

export class StopDataCollectionModal extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state = {
            status: RequestStatus.NOT_STARTED
        }
    }

    stopDataCollection = async () => {
        this.setState({
            status: RequestStatus.IN_PROGRESS
        });

        await this.props.dataProvider.stopRoiDataCollection(this.props.roiToStop.id);

        this.setState({
            status: RequestStatus.NOT_STARTED
        });

        this.props.onModalClose();
    }

    public render(): JSX.Element
    {
        if(this.state.status == RequestStatus.SUCCESS){
            this.setState({
                status: RequestStatus.NOT_STARTED
            });
            this.props.onModalClose();
        }
      
        return (
            <Modal show={this.props.visible}  backdrop="static">
                <Modal.Header >
                    <Modal.Title>Stop data collection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {
                            this.state.status == RequestStatus.NOT_STARTED &&
                            <div>
                                <p>Your ROI will not stop immediately. In some cases it may take up to a day.</p>
                                <p>Are you sure you want to stop the data collection ?</p>
                            </div>
                                
                        }   
                        {
                            this.state.status == RequestStatus.ERROR &&
                                <p>There was an error while stopping the data collection.</p>
                        }                    
                        {
                            this.state.status == RequestStatus.IN_PROGRESS &&
                            <div style={{...row, alignItems: 'center'}}>
                                 <Spinner animation="border" role="status" variant="primary"/>
                                <div style={{ marginLeft: defaultMargin}}>Data collection stop is in progress ...</div>
                            </div>
                        }
                </Modal.Body>
                   <Modal.Footer>
                       {
                           (this.state.status == RequestStatus.NOT_STARTED || 
                           this.state.status == RequestStatus.ERROR) &&
                            <Button className="shell-secondary-btn" onClick={() => {
                                this.setState({
                                    status: RequestStatus.NOT_STARTED
                                });
                                this.props.onModalClose();
                            }}>Close</Button>
                       }
                        {
                            this.state.status == RequestStatus.NOT_STARTED &&
                            <Button className="shell-gray-btn" onClick={this.stopDataCollection.bind(this)}>Stop</Button> 
                        }
                    </Modal.Footer>                
            </Modal>     
        );
    }
}