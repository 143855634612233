import * as React from "react";
import {  defaultMargin, row, shellDarkGray } from '../../styles/styles'
import { DataProvider, RequestStatus } from "../../data/DataProvider";
import { Util } from "../../util/Util";
import { RoiPreview } from "../../screens/NewRoi";
import { Roi } from "../../../../lambda/interfaces/rois";
import { Modal, Spinner, Button } from "react-bootstrap";
import { Redirect} from 'react-router-dom'

interface Props
{
    visible: boolean;
    dataProvider: DataProvider;
    roi: Roi;
    onModalClose: () => void;
}

interface State 
{
    status: RequestStatus;
}

export class PauseResumeModal extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state = {
            status: RequestStatus.NOT_STARTED
        }
    }

    pauseResumeDataCollection = async () => {
        this.setState({
            status: RequestStatus.IN_PROGRESS
        });

        await this.props.dataProvider.pauseResumeDataCollection(this.props.roi.id, !this.props.roi.isSuspended);

        this.setState({
            status: RequestStatus.NOT_STARTED
        });

        this.props.onModalClose();
    }

    public render(): JSX.Element
    {
        if(this.state.status == RequestStatus.SUCCESS){
            this.setState({
                status: RequestStatus.NOT_STARTED
            });
            this.props.onModalClose();
        }

        const title = this.props.roi.isSuspended ? "Resume data collection" : "Pause data collection";
        const actionDescription = !this.props.roi.isSuspended? "This action will suspend data collection for this ROI after the next run completes." : "This action will resume data collection for this ROI.";
      

        return (
            <Modal show={this.props.visible}  backdrop="static">
                <Modal.Header >
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {
                            this.state.status == RequestStatus.NOT_STARTED &&
                            <div>
                                <p>{actionDescription}</p>
                                <p>Are you sure you want to <b>{this.props.roi.isSuspended ? "resume" : "pause"}</b> the data collection ?</p>
                            </div>                               
                        }   
                        {
                            this.state.status == RequestStatus.ERROR &&
                                <p>There was an error while {this.props.roi.isSuspended ? "resuming" : "pausing"} the data collection.</p>
                        }                    
                        {
                            this.state.status == RequestStatus.IN_PROGRESS &&
                            <div style={{...row, alignItems: 'center'}}>
                                 <Spinner animation="border" role="status" color={shellDarkGray}/>
                                <div style={{ marginLeft: defaultMargin}}>Data collection {this.props.roi.isSuspended ? "resume" : "pause"} is in progress ...</div>
                            </div>
                        }
                </Modal.Body>
                   <Modal.Footer>
                       {
                           (this.state.status == RequestStatus.NOT_STARTED || 
                            this.state.status == RequestStatus.ERROR) &&
                            <Button className="shell-secondary-btn" onClick={() => {
                                this.props.onModalClose();
                            }}>Close</Button>
                       }
                        {
                            this.state.status == RequestStatus.NOT_STARTED &&
                            <Button className="shell-gray-btn" onClick={this.pauseResumeDataCollection.bind(this)}>{this.props.roi.isSuspended ? "Resume" : "Pause"}</Button> 
                        }
                    </Modal.Footer>                
            </Modal>     
        );
    }
}